import _ from "lodash"
import * as React from "react"

import useCategoryHooks from "components/InventoryList/useCategoryHooks"
import useItems from "components/InventoryList/useItems"
import useOptionListHooks from "components/InventoryList/useOptionListHooks"
import Layout from "components/Layout"
import { useInventoryContext } from "contexts/InventoryContext"
import { Catalog } from "models/hubrise/Catalog"
import { CatalogInventory } from "models/hubrise/CatalogInventory"
import { CatalogSummary } from "models/hubrise/CatalogSummary"
import { LocalStorage } from "utils/localStorage"

import ListView from "./ListView"
import DesktopNavigation from "./Navigation/DesktopNavigation"
import Toolbar from "./Toolbar"
import MobileAdjustInventoryButton from "./Toolbar/AdjustInventoryButton/MobileAdjustInventoryButton"
import UpdateModal from "./UpdateModal"
import {
  isOptionEditable,
  isOptionUnavailable,
  isProductEditable,
  isProductUnavailable,
  optionInventoryEntry,
  skuInventoryEntry,
} from "./shared"
import { ItemWithType, Section } from "./types"

interface InventoryListProps {
  catalogSummaries: Array<CatalogSummary>
  catalog: Catalog
  catalogInventory: CatalogInventory
  selectCatalog: (catalogId: string) => void
  section: Section
}

const InventoryList = ({
  catalogSummaries,
  catalog,
  catalogInventory,
  selectCatalog,
  section,
}: InventoryListProps): JSX.Element => {
  const { selection } = useInventoryContext()

  const [editedItems, setEditedItems] = React.useState<Array<ItemWithType>>([])

  React.useEffect(() => {
    LocalStorage.lastCatalogId = catalog.id
  }, [catalog.id])

  const items = useItems(catalog, catalogInventory, section)

  const { categoryDescendants, isCategorySelected, isLastOfCategory } = useCategoryHooks(
    items,
    selection.section === "products" ? selection.ids : [],
  )

  const { optionListDescendants, isOptionListSelected, isLastOfOptionList } = useOptionListHooks(
    items,
    selection.section === "options" ? selection.ids : [],
  )

  const flattenOptions = React.useMemo(() => {
    return catalog.data.option_lists.flatMap((optionList) => optionList.options)
  }, [catalog.data.option_lists])

  const unavailableItemsCount = React.useMemo(() => {
    switch (section) {
      case "products":
        return catalog.data.products.filter((product) => isProductUnavailable(product, catalogInventory)).length
      case "options":
        return _.uniqBy(
          flattenOptions.filter((option) => isOptionUnavailable(option, catalogInventory)),
          "ref",
        ).length
    }
  }, [section, catalog.data.products, flattenOptions, catalogInventory])

  const closeModal = () => setEditedItems([])

  const openModal = () =>
    setEditedItems(
      selection.section === "products"
        ? catalog.data.products
            .filter((product) => selection.ids.includes(product.id))
            .map((product) => ({ type: "product", data: product }))
        : flattenOptions
            .filter((option) => selection.ids.includes(option.id))
            .map((option) => ({ type: "option", data: option })),
    )

  return (
    <Layout
      layout={{
        type: "toolbar",
        toolbar: (
          <Toolbar
            catalogSummaries={catalogSummaries}
            selectedCatalogId={catalog.id}
            selectCatalog={selectCatalog}
            items={items}
            section={section}
            unavailableItemsCount={unavailableItemsCount}
            openModal={openModal}
          />
        ),
        desktopNavigation: <DesktopNavigation catalogId={catalog.id} section={section} />,
      }}
    >
      {section === "products" ? (
        <ListView
          section={section}
          items={items}
          catalog={catalog}
          setEditedItems={setEditedItems}
          getDescendants={categoryDescendants}
          getInventoryEntry={(ref) => (ref !== null ? skuInventoryEntry(ref, catalogInventory) : undefined)}
          isGroupSelected={isCategorySelected}
          isItemEditable={isProductEditable}
          isLastGroupItem={isLastOfCategory}
        />
      ) : (
        <ListView
          section={section}
          items={items}
          catalog={catalog}
          setEditedItems={setEditedItems}
          getDescendants={optionListDescendants}
          getInventoryEntry={(ref) => (ref !== null ? optionInventoryEntry(ref, catalogInventory) : undefined)}
          isGroupSelected={isOptionListSelected}
          isItemEditable={isOptionEditable}
          isLastGroupItem={isLastOfOptionList}
        />
      )}

      {editedItems.length > 0 && (
        <UpdateModal catalogId={catalog.id} section={section} editedItems={editedItems} close={closeModal} />
      )}

      {selection.section === section && selection.ids.length > 0 && editedItems.length === 0 && (
        <MobileAdjustInventoryButton openModal={openModal} section={section} count={selection.ids.length} />
      )}
    </Layout>
  )
}

export default InventoryList
