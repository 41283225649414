import * as React from "react"

import ConnectionLost from "components/ConnectionLost"
import { withLayout } from "components/Layout"
import Loading from "components/Loading"
import NoOrders from "components/NoOrders"
import OrderList from "components/OrderList"
import { useConfig } from "contexts/ConfigContext"
import { useOrderContext } from "contexts/OrderContext"
import useTitle from "hooks/useTitle"

const Today = withLayout((): JSX.Element => {
  const { settings } = useConfig()
  const { todayAndFutureOrders, todayOrders, nbNewTodayOrders, isConnectionLost } = useOrderContext()

  useTitle(nbNewTodayOrders)

  if (isConnectionLost) {
    return <ConnectionLost />
  } else if (todayAndFutureOrders.isLoading) {
    return <Loading />
  } else if (todayOrders.length === 0) {
    return <NoOrders />
  }

  const orders = todayOrders.filter((order) => !settings.status_hidden.includes(order.status))
  const nbHiddenOrders = todayOrders.length - orders.length

  return <OrderList orders={orders} nbHiddenOrders={nbHiddenOrders} />
}, "Today")

export default Today
