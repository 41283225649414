import * as React from "react"
import { useTranslation } from "react-i18next"

import StatusSelect from "components/StatusSelect"
import { useConfig } from "contexts/ConfigContext"
import { useSettingsMutation } from "hooks/mutations"
import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Statuses } from "models/hubrise/types"

import { Row, Subtitle, Title } from "../Styles"

import { Column, Columns } from "./Styles"

const OrderStatus = () => {
  const { t } = useTranslation()
  const { colon } = useTranslationFormatters()
  const { settings } = useConfig()
  const { status_transitions, status_with_editable_time, status_with_editable_comment, status_hidden } = settings
  const { mutate } = useSettingsMutation()

  const statusGroups = (() => {
    const middleIndex = Math.ceil(Statuses.length / 2)
    return [[...Statuses].splice(0, middleIndex), [...Statuses].splice(-middleIndex + 1)]
  })()

  return (
    <React.Fragment>
      <Row>
        <Title>
          {t(`popup.settings.tabs.order_status.title`)}
          <Subtitle>{t(`popup.settings.tabs.order_status.subtitle`)}</Subtitle>
        </Title>

        <Columns>
          {statusGroups.map((group, index) => (
            <Column key={index} nb={index}>
              {group.map((status, index) => (
                <StatusSelect
                  key={index}
                  label={t(`order.status.${status}`)}
                  popupTitle={t("popup.statuses_list.next_statuses_for")}
                  popupSubtitle={t(`order.status.${status}`)}
                  statuses={status_transitions[status] || []}
                  onChange={(statuses) => mutate({ status_transitions: { ...status_transitions, [status]: statuses } })}
                />
              ))}
            </Column>
          ))}
        </Columns>
      </Row>

      <Row>
        <Title>{t("popup.settings.tabs.order_status.additional_prompt")}</Title>

        <StatusSelect
          label={t("popup.settings.tabs.order_status.prompt_delivery_time") + colon}
          popupTitle={t("popup.settings.tabs.order_status.popup_title.delivery_time") + colon}
          statuses={status_with_editable_time}
          onChange={(statuses) => mutate({ status_with_editable_time: statuses })}
        />

        <StatusSelect
          label={t("popup.settings.tabs.order_status.prompt_comment") + colon}
          popupTitle={t("popup.settings.tabs.order_status.popup_title.comment") + colon}
          statuses={status_with_editable_comment}
          onChange={(statuses) => mutate({ status_with_editable_comment: statuses })}
        />
      </Row>

      <Row>
        <Title>{t("popup.settings.tabs.order_status.hide_orders")}</Title>

        <StatusSelect
          label={t("popup.settings.tabs.order_status.hide_orders_details") + colon}
          popupTitle={t("popup.settings.tabs.order_status.popup_title.hide_orders") + colon}
          statuses={status_hidden}
          onChange={(statuses) => mutate({ status_hidden: statuses })}
        />
      </Row>
    </React.Fragment>
  )
}

export default OrderStatus
