import * as React from "react"

import { Container, Image, Text } from "./Styles"

type TextWithImageProps =
  | {
      type: "children"
      text: string
      children: React.ReactNode
    }
  | {
      type: "image"
      text: string
      $url: string
      $retinaUrl: string
    }

const TextWithImage = ({ text, ...attrs }: TextWithImageProps): JSX.Element => {
  return (
    <Container>
      {attrs.type === "children" ? attrs.children : <Image $url={attrs.$url} $retinaUrl={attrs.$retinaUrl} />}
      <Text>{text}</Text>
    </Container>
  )
}

export default TextWithImage
