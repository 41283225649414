import * as React from "react"

import { useConfig } from "contexts/ConfigContext"

import { TitleSection } from "./Styles"

const Title = (): JSX.Element => {
  const { location } = useConfig()

  return (
    <TitleSection>
      {location.account.name} {location.name}
    </TitleSection>
  )
}

export default Title
