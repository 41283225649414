import { Icon, colors, mixin } from "@hubrise/react-components"
import styled from "styled-components"

import { blockPadding } from "components/OrderModalContent/consts"
import { dotBefore, labelValueGap } from "styles/common"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.75rem;
  padding: ${blockPadding};
  border-bottom: 1px solid ${colors.borderLight};
`

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  gap: ${labelValueGap};
  color: ${colors.primary};
  ${mixin.clickable};
`

export const StyledIcon = styled(Icon)`
  color: ${colors.textLight};
`

export const Phone = styled.div`
  display: flex;
  align-items: center;
  gap: ${labelValueGap};
`

export const Code = styled.div`
  ${dotBefore};
`

export const CodeLabel = styled.label`
  margin-right: ${labelValueGap};
  color: ${colors.textMedium};
`

export const CodeValue = styled.span`
  font-weight: bold;
  color: ${colors.textDark};
`
