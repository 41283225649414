import { Icon, iconSizes } from "@hubrise/react-components"
import type { IconCode } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { useOrderContext } from "contexts/OrderContext"
import useOrderIconUrl from "hooks/useOrderIconUrl"
import { Day } from "models/Day"
import { Order } from "models/hubrise/Order"
import { ColorScheme } from "styles/common"

import {
  Info,
  ServiceType,
  Title,
  Price,
  OrderStatus,
  Header,
  Notification,
  Card,
  Body,
  InfoLine,
  ServiceTypeIcon,
  ServiceTypeInfo,
  IconHolder,
  CollectionCode,
  ConnectionName,
} from "./Styles"

interface OrderContentProps {
  order: Order
  colorScheme: ColorScheme
}

const OrderContent = ({ order, colorScheme }: OrderContentProps): JSX.Element => {
  const { t } = useTranslation()
  const { cutoff, timezone } = useConfig()
  const today = Day.today(cutoff, timezone)

  const { orderNotificationStore } = useOrderContext()

  const iconUrl = useOrderIconUrl(order)

  const isNotificationVisible = orderNotificationStore.hasOrder(order.id)
  const { customer, connectionName, collectionCode, serviceType, status } = order

  const serviceTypeIconCode: IconCode = {
    delivery: "directions_bike",
    collection: "shopping_bag",
    eat_in: "restaurant",
  }[serviceType ?? "delivery"] as IconCode

  const timeStatus = order.timeStatus(cutoff, today)
  const time = timeStatus.status === "imminent" ? `${timeStatus.left} min` : timeStatus.time

  return (
    <Card>
      <Header $status={status} $colorScheme={colorScheme}>
        <Title>
          <OrderStatus>{t(`order.status.${status}`)}</OrderStatus>
          <Price dangerouslySetInnerHTML={{ __html: order.total.toHumanString() }} />
        </Title>

        <Notification $isVisible={isNotificationVisible}>
          <Icon code="notifications" size={iconSizes._25} />
        </Notification>
      </Header>

      <Body>
        {iconUrl !== null && (
          <IconHolder $colorScheme={colorScheme}>
            <img src={iconUrl} alt={order.createdBy} />
          </IconHolder>
        )}

        <Info>
          {customer.fullName !== "" && <InfoLine $bold>{customer.fullName}</InfoLine>}
          {(collectionCode !== null || connectionName !== null) && (
            <InfoLine>
              {collectionCode !== null && <CollectionCode>{collectionCode}</CollectionCode>}
              {connectionName !== null && (
                <ConnectionName $separator={collectionCode !== null}>{connectionName}</ConnectionName>
              )}
            </InfoLine>
          )}
        </Info>

        <ServiceType $isLate={timeStatus.status === "past" && colorScheme === "normal"}>
          <ServiceTypeIcon code={serviceTypeIconCode} />
          {serviceType === "eat_in"
            ? order.tableName !== null && <ServiceTypeInfo>{order.tableName}</ServiceTypeInfo>
            : time !== null && <ServiceTypeInfo>{time}</ServiceTypeInfo>}
        </ServiceType>
      </Body>
    </Card>
  )
}

export default OrderContent
