import * as React from "react"

import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Item } from "models/hubrise/order/Item"

import { TdName, TdPrice, TdQuantity } from "../Styles"

const Top = ({ item }: { item: Item }): JSX.Element => {
  const { formatItemPrice } = useTranslationFormatters()

  return (
    <React.Fragment>
      <TdQuantity>{item.quantity}</TdQuantity>
      <TdName>{item.productFullName}</TdName>
      <TdPrice dangerouslySetInnerHTML={{ __html: formatItemPrice(item) }} />
    </React.Fragment>
  )
}

export default Top
