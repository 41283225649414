import { Icon, boxShadows, breakpoints, colors, iconSizes, mixin, Input } from "@hubrise/react-components"
import type { IconCode } from "@hubrise/react-components"
import styled from "styled-components"

import DatePicker from "components/DatePicker"

export const Container = styled.div`
  margin: 0 auto 1.25rem auto;
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr;
  grid-template-areas: "filter";

  @media (min-width: ${breakpoints.mobile}) {
    max-width: 35rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "filter filter";
  }

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 65rem;
    gap: 1.25rem;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "filter";
  }
`

const Field = styled.div`
  display: flex;
  align-items: baseline;
`

export const FilterField = styled(Field)`
  grid-area: filter;
`

export const DateField = styled(Field)``

export const Label = styled.div`
  min-width: 3rem;
  margin-right: 0.75rem;
  white-space: nowrap;

  @media (min-width: ${breakpoints.tablet}) {
    min-width: auto;
  }
`

export const Entry = styled.div`
  flex-grow: 1;
  padding: 0 0.25rem;
  display: flex;
  align-items: center;
  background-color: ${colors.backgroundWhite};
  ${mixin.fieldBorder("transparent")};

  box-shadow: ${boxShadows.small};
  :focus-within {
    ${mixin.fieldBorder(colors.borderInputFocus)};
  }
`

export const StyledInput = styled(Input).attrs({ mouseOverBackground: "initial" })`
  flex-grow: 1;
`

export const StyledDatePicker = styled(DatePicker)`
  flex-grow: 1;
`

const RightAlignedIcon = (code: IconCode) => styled(Icon).attrs({ code, size: iconSizes._20 })`
  padding: 0.2rem 0.5rem;
  border-left: 1px solid ${colors.borderLight};
  color: ${colors.textDark};
  ${mixin.clickable};
`

export const ClearIcon = RightAlignedIcon("close")

export const DateIcon = RightAlignedIcon("expand_more")
