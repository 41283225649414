import { colors } from "@hubrise/react-components"
import styled, { css } from "styled-components"

import { dotBefore } from "styles/common"

import { blockPadding } from "../consts"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  color: ${colors.textMedium};
  border-bottom: 1px solid ${colors.borderLight};
  padding: ${blockPadding};
  text-transform: uppercase;
`

export const ItemsSection = styled.div`
  display: grid;
  grid-template-columns: [name] 1fr [quantity] 2.25rem [price] 3.25rem [end];
  align-items: baseline;
  padding: ${blockPadding};
`

export const Spacer = styled.div`
  grid-column: name / end;
  height: 0.75rem;
`

const dealIndicator = (isDeal = false) =>
  isDeal &&
  css`
    height: 100%;
    border-left: 2px solid ${colors.primary};
    padding-left: 0.75rem;
  `

export const DealName = styled.div`
  grid-column: name / end;
  padding: 0.3rem 0;
  color: ${colors.primary};
  font-weight: 500;
`

const Deletable = styled.div<{ $isDeleted: boolean }>`
  ${({ $isDeleted }) => $isDeleted && `text-decoration: line-through; color: ${colors.textMedium};`}
`

export const Quantity = styled(Deletable)`
  grid-column: quantity;
  font-weight: 500;
`

export const ItemName = styled(Deletable)<{ $isDeal?: boolean }>`
  grid-column: name;
  padding: 0.3rem 0;
  font-weight: 500;
  ${({ $isDeal }) => dealIndicator($isDeal)}
  ${({ $isDeleted }) => $isDeleted && `text-decoration: line-through; color: ${colors.textMedium};`}
`

export const Price = styled(Deletable)`
  grid-column: price;
  font-weight: 500;
  text-align: right;
`

export const TotalLabel = styled.div`
  grid-column: name;
  padding-top: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
`

export const TotalPrice = styled(Price).attrs({ $isDeleted: false })`
  font-weight: 800;
`

export const TdOptionLists = styled.div<{ $isDeal: boolean }>`
  grid-column: name / end;
  padding: 0 0 0.2rem 0.5rem;
  ${({ $isDeal }) => dealIndicator($isDeal)}
  color: ${colors.textMedium};
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const TdCustomerNotes = styled.div<{ $isDeal: boolean }>`
  grid-column: name / end;
  ${({ $isDeal }) => dealIndicator($isDeal)}
  color: ${colors.textMedium};
`

export const OptionList = styled.div`
  ${dotBefore};
`
