import { colors, fontSizes, mixin, sizes } from "@hubrise/react-components"
import styled from "styled-components"

import FancyCheckbox from "components/FancyCheckbox"

import { checkboxPositionCss } from "../Styles"

export const StyledRow = styled.div<{ $height: number }>`
  height: ${({ $height }) => $height - 2}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${colors.backgroundLightest};
  border-radius: ${sizes.borderRadius};
`

export const SelectCheckbox = styled(FancyCheckbox)`
  height: 100%;
  width: 3.5rem;
  ${checkboxPositionCss};
`

export const Name = styled.div`
  color: ${colors.textDarkest};
  font-size: ${fontSizes._18};
  ${mixin.noWrapEllipsis}
`
