import { boxShadows, breakpoints, colors, mixin, sizes } from "@hubrise/react-components"
import styled, { css } from "styled-components"

export const Container = styled.div`
  margin-bottom: 2.25rem;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 0 0.65rem;
  color: ${colors.textMedium};
  font-weight: 500;
`

export const Date = styled.div``

export const Summary = styled.div``

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.1rem;
`

export const OrderCard = styled.div`
  border-radius: ${sizes.borderRadius};
  box-shadow: ${boxShadows.small};
  background-color: ${colors.white};
  ${mixin.clickable};
`

export const headerHeight = css`
  height: 2.25rem;

  @media (min-width: ${breakpoints.mobile}) {
    height: 2.75rem;
  }
`

export const bodyHeight = css`
  height: 4.5rem;
`
