import { Toast } from "@hubrise/react-components"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client"
import { changeLanguage } from "i18next"
import * as React from "react"
import { I18nextProvider } from "react-i18next"
import { BrowserRouter } from "react-router-dom"

import "styles/FontStyle.css"

import Routes from "Routes"
import MissingAppInstanceId from "components/MissingAppInstanceId"
import Notification from "components/Notification"
import { ClientIconContextProvider } from "contexts/ClientIconProvider"
import { ConfigContextProvider, useConfig } from "contexts/ConfigContext"
import { InventoryContextProvider } from "contexts/InventoryContext"
import { OrderContextProvider } from "contexts/OrderContext"
import { persister, queryClient } from "hooks/queries"
import { ServerConfig } from "models/Config"
import withStyles from "styles/withStyles"
import { appInstanceIdFromUrl } from "utils/browser"
import i18n from "utils/i18n"

interface AppProps {
  serverConfig: ServerConfig
}

const App = withStyles<AppProps>(({ serverConfig }) => {
  const appInstanceId = appInstanceIdFromUrl()
  if (appInstanceId === null) return <MissingAppInstanceId />

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <ConfigContextProvider serverConfig={serverConfig} appInstanceId={appInstanceId}>
        <I18nextProvider i18n={i18n}>
          <SetUserLanguage>
            <Toast />
            <Notification />
            <OrderContextProvider>
              <InventoryContextProvider>
                <ClientIconContextProvider>
                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>

                  <ReactQueryDevtools />
                </ClientIconContextProvider>
              </InventoryContextProvider>
            </OrderContextProvider>
          </SetUserLanguage>
        </I18nextProvider>
      </ConfigContextProvider>
    </PersistQueryClientProvider>
  )
})

const SetUserLanguage = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { user } = useConfig()
  React.useEffect(() => void changeLanguage(user.locale), [user.locale])
  return <>{children}</>
}

export default App
