import * as React from "react"

import { Item as ItemModel } from "models/hubrise/order/Item"

import { Spacer } from "../Styles"

import CustomerNotes from "./CustomerNotes"
import OptionLists from "./OptionLists"
import Top from "./Top"

const Item = ({ item, isDeal = false }: { item: ItemModel; isDeal?: boolean }): JSX.Element => {
  const common = { item, isDeal }

  return (
    <React.Fragment>
      <Top {...common} />
      <OptionLists {...common} />
      <CustomerNotes {...common} />

      {!isDeal && <Spacer />}
    </React.Fragment>
  )
}

export default Item
