import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import { Day } from "models/Day"
import { Subscription } from "models/Subscription"
import { Order } from "models/hubrise/Order"

import { HistoryFilter, OrderStore } from "./types"
import useOrderUpdated from "./useOrderUpdated"
import useServer from "./useServer"

const useHistoryOrders = (subscription: Subscription<Order>) => {
  const { cutoff, timezone } = useConfig()
  const { getHistoryOrders } = useServer()

  const [historyOrders, setHistoryOrders] = React.useState<OrderStore>({
    orders: undefined,
    isLoading: true,
  })

  const [from, setFrom] = React.useState<Day>(() => Day.today(cutoff, timezone))
  const [to, setTo] = React.useState<Day>(() => Day.today(cutoff, timezone))
  const [search, setSearch] = React.useState<string>("")
  const historyFilter = React.useMemo<HistoryFilter>(
    () => ({ from, to, search, setFrom, setTo, setSearch }),
    [from, to, search, setFrom, setTo, setSearch],
  )

  useOrderUpdated(subscription, setHistoryOrders)

  React.useEffect(() => {
    const fetchOrders = async () => {
      setHistoryOrders({ orders: undefined, isLoading: true })
      const orders = await getHistoryOrders(from, to, search)
      setHistoryOrders({ orders, isLoading: false })
    }
    void fetchOrders()
  }, [from, to, search, getHistoryOrders])

  const nbNewHistoryOrders = React.useMemo(
    () => (historyOrders.orders || []).filter((order) => order.status === "new").length,
    [historyOrders.orders],
  )

  return React.useMemo(
    () => ({ historyOrders, historyFilter, nbNewHistoryOrders }),
    [historyFilter, historyOrders, nbNewHistoryOrders],
  )
}

export default useHistoryOrders
