import { Button, toastSuccess } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import ExternalLink from "components/ExternalLink"
import { useLocationMutation } from "hooks/mutations"
import { Location } from "models/hubrise/Location"
import {
  DayOfTheWeek,
  DaysOfTheWeek,
  emptyOpeningHours,
  identicalOpeningHours,
  OpeningHours,
  TimeInterval,
  validateOpeningHours,
} from "models/hubrise/location/opening_hours"

import {
  Header,
  Page,
  Table,
  Row,
  OpeningHoursCell,
  DayCell,
  AddHoursCell,
  CopyToBottomCell,
  BottomBar,
  SaveButton,
  TimeIntervals,
  SetOpeningHoursLink,
  CancelButton,
  Buttons,
  GeneralError,
} from "./Styles"
import TimeIntervalSelector from "./TimeIntervalSelector"

const defaultTimeInterval = (isFirst: boolean): TimeInterval =>
  isFirst ? { from: "11:00", to: "14:00" } : { from: "18:00", to: "22:00" }

const defaultOpeningHours: OpeningHours = {
  ...emptyOpeningHours(),
  monday: [defaultTimeInterval(true), defaultTimeInterval(false)],
}

interface OpeningHoursTableProps {
  location: Location
}

const OpeningHoursTable = ({ location }: OpeningHoursTableProps): JSX.Element => {
  const { t } = useTranslation()
  const { mutate } = useLocationMutation()
  const [isUpdating, setIsUpdating] = React.useState(false)
  const [openingHours, setOpeningHours] = React.useState<OpeningHours | null>(location.opening_hours)

  const hasChanged: boolean =
    !!openingHours && (!location.opening_hours || !identicalOpeningHours(openingHours, location.opening_hours))

  const errors = React.useMemo(
    () => (openingHours ? validateOpeningHours(openingHours, location.cutoff_time) : undefined),
    [location.cutoff_time, openingHours],
  )
  const hasErrors = React.useMemo(
    () => !!errors && (errors.general !== null || Object.values(errors.days).some((error) => error.length > 0)),
    [errors],
  )

  const canCancel = hasChanged
  const canSave = hasChanged && !hasErrors
  const shouldBeVisible = canCancel || canSave || isUpdating
  const [areButtonsVisible, setAreButtonsVisible] = React.useState(shouldBeVisible)
  React.useEffect(() => {
    if (areButtonsVisible && !shouldBeVisible) {
      // Make the buttons disappear after a short delay
      setTimeout(() => setAreButtonsVisible(false), 2000)
    } else if (!areButtonsVisible && shouldBeVisible) {
      setAreButtonsVisible(true)
    }
  }, [areButtonsVisible, shouldBeVisible])

  const handleTimeIntervalChange = (day: DayOfTheWeek, idx: number, timeInterval: TimeInterval) => {
    if (!openingHours) return

    const newTimeIntervals = [...openingHours[day]]
    newTimeIntervals[idx] = timeInterval
    setOpeningHours({ ...openingHours, [day]: newTimeIntervals })
  }

  const handleCopy = (dayToCopy: DayOfTheWeek) => {
    if (!openingHours) return
    const newOpeningHours = { ...openingHours }
    DaysOfTheWeek.slice(DaysOfTheWeek.indexOf(dayToCopy) + 1).forEach((day) => {
      newOpeningHours[day] = [...openingHours[dayToCopy]]
    })
    setOpeningHours(newOpeningHours)
  }

  const handleAddHours = (day: DayOfTheWeek) => {
    if (!openingHours) return
    let newTimeIntervals: Array<TimeInterval>
    if (openingHours[day].length > 0) {
      newTimeIntervals = [...openingHours[day], defaultTimeInterval(false)]
    } else {
      newTimeIntervals = [defaultTimeInterval(true)]
    }
    setOpeningHours({ ...openingHours, [day]: newTimeIntervals })
  }

  const handleRemoveHours = (day: DayOfTheWeek, idx: number) => {
    if (!openingHours) return
    const newTimeIntervals = [...openingHours[day]]
    newTimeIntervals.splice(idx, 1)
    setOpeningHours({ ...openingHours, [day]: newTimeIntervals })
  }

  const handleCancel = () => {
    setOpeningHours(location.opening_hours)
  }

  const handleSave = () => {
    setIsUpdating(true)
    mutate(
      { opening_hours: openingHours },
      {
        onSuccess: (newLocation) => {
          setIsUpdating(false)
          toastSuccess(t("opening_hours.messages.saved"))
          setOpeningHours(newLocation.opening_hours)
        },
        onError: () => {
          setIsUpdating(false)
        },
      },
    )
  }

  return (
    <Page>
      <Header>
        {t("opening_hours.header.main") + " "}
        {t("opening_hours.header.link_before") + " "}
        <ExternalLink to={t("opening_hours.header.link")}>{t("opening_hours.header.link_label")}</ExternalLink>
        {t("opening_hours.header.link_after")}
      </Header>

      {!openingHours && (
        <SetOpeningHoursLink onClick={() => setOpeningHours(defaultOpeningHours)}>
          {t("opening_hours.set_opening_hours")}
        </SetOpeningHoursLink>
      )}

      {openingHours && (
        <Table>
          <tbody>
            {DaysOfTheWeek.map((day) => {
              const openingHoursOfDay = openingHours[day]

              return (
                <Row key={day} data-testid={day}>
                  <DayCell>{t(`days_of_week.${day}`) + t("general.colon")}</DayCell>

                  <OpeningHoursCell>
                    {openingHoursOfDay.length > 0 ? (
                      <TimeIntervals>
                        {openingHoursOfDay.map((timeInterval, intervalIndex) => (
                          <TimeIntervalSelector
                            key={`${day}.${intervalIndex}`}
                            from={timeInterval.from}
                            to={timeInterval.to}
                            onChange={(timeInterval) => {
                              handleTimeIntervalChange(day, intervalIndex, timeInterval)
                            }}
                            errors={errors!.days[day]
                              .filter((error) => error.intervalIndex === intervalIndex)
                              .map(({ errorType }) => errorType)}
                            onRemove={() => handleRemoveHours(day, intervalIndex)}
                          />
                        ))}
                      </TimeIntervals>
                    ) : (
                      <TimeIntervals>{t("opening_hours.closed")}</TimeIntervals>
                    )}
                  </OpeningHoursCell>

                  <AddHoursCell>
                    <Button onClick={() => handleAddHours(day)} variant="gray" data-testid="add-hours">
                      {t("opening_hours.add_hours")}
                    </Button>
                  </AddHoursCell>

                  {day !== "sunday" && (
                    <CopyToBottomCell>
                      <Button onClick={() => handleCopy(day)} variant="gray" data-testid="copy-to-bottom">
                        {t("opening_hours.copy_to_bottom")}
                      </Button>
                    </CopyToBottomCell>
                  )}
                </Row>
              )
            })}
          </tbody>
        </Table>
      )}

      {openingHours && (
        <BottomBar isVisible={areButtonsVisible}>
          <Buttons>
            <CancelButton disabled={!canCancel} onClick={handleCancel} variant="empty">
              {t("general.cancel")}
            </CancelButton>

            <SaveButton disabled={!canSave} onClick={handleSave} isWorking={isUpdating}>
              {t("general.save")}
            </SaveButton>
          </Buttons>

          {errors?.general && <GeneralError>{t(`opening_hours.errors.${errors.general}`)}</GeneralError>}
        </BottomBar>
      )}
    </Page>
  )
}

export default OpeningHoursTable
