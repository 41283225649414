import * as React from "react"

import ConnectionLost from "components/ConnectionLost"
import { withLayout } from "components/Layout"
import Loading from "components/Loading"
import NoOrders from "components/NoOrders"
import OrderList from "components/OrderList"
import SearchBar from "components/SearchBar"
import { useConfig } from "contexts/ConfigContext"
import { useOrderContext } from "contexts/OrderContext"
import useTitle from "hooks/useTitle"
import { Order } from "models/hubrise/Order"

const withSearchBar = <Props,>(component: React.FunctionComponent<Props>): React.FunctionComponent<Props> => {
  const Component: React.FunctionComponent<Props> = (props, context) => (
    <section>
      <SearchBar />
      {component(props, context)}
    </section>
  )
  Component.displayName = `HistoryWithSearchBar`
  return Component
}

const History = withLayout(
  withSearchBar((): JSX.Element => {
    const { cutoff } = useConfig()
    const { historyOrders, nbNewHistoryOrders, isConnectionLost } = useOrderContext()

    useTitle(nbNewHistoryOrders)

    if (isConnectionLost) {
      return <ConnectionLost />
    } else if (historyOrders.isLoading) {
      return <Loading />
    } else if (historyOrders.orders.length === 0) {
      return <NoOrders forSearch={true} />
    }

    const orderGroups = Order.groupByDate(historyOrders.orders, cutoff).reverse()

    return (
      <React.Fragment>
        {orderGroups.map(({ orders, day }, index) => (
          <OrderList key={index} orders={orders} day={day} />
        ))}
      </React.Fragment>
    )
  }),
)

export default History
