import { ModalStyle as Modal } from "@hubrise/react-components"
import * as React from "react"

import { Order } from "models/hubrise/Order"

import { Address, Title } from "./Styles"

const Header = ({ order, close }: { order: Order; close: () => void }): JSX.Element => {
  return (
    <Modal.Header>
      <Title>
        {order.customer.fullName}
        {order.serviceType === "delivery" && <Address>{order.customer.fullAddress}</Address>}
      </Title>
      <Modal.Close onClick={close} />
    </Modal.Header>
  )
}

export default Header
