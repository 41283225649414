import * as React from "react"
import { useTranslation } from "react-i18next"

import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Order } from "models/hubrise/Order"

import { Line, OriginAndCollectionCode, SummarySection } from "./Styles"

const Summary = ({ order }: { order: Order }): JSX.Element => {
  const { t } = useTranslation()
  const { formatDateTime } = useTranslationFormatters()

  const originAndCollectionCode = [order.type.name, order.collectionCode].filter((value) => value !== null).join(" ")

  const paymentHTML = order.paymentSummaryHTML

  return (
    <SummarySection>
      <Line>
        {order.serviceType !== null ? t(`popup.print_order.${order.serviceType}`) : null}
        {order.confirmedOrExpectedTime ? " - " + formatDateTime(order.confirmedOrExpectedTime, order.createdAt) : null}
      </Line>

      {paymentHTML !== null && <Line dangerouslySetInnerHTML={{ __html: paymentHTML }} />}

      {originAndCollectionCode !== "" && <OriginAndCollectionCode>{originAndCollectionCode}</OriginAndCollectionCode>}
    </SummarySection>
  )
}

export default Summary
