import { appendQueryParams, axiosErrorMessage } from "@hubrise/react-components"
import type { Method } from "@hubrise/react-components"
import axios, { isAxiosError } from "axios"
import type { RawAxiosRequestConfig, RawAxiosRequestHeaders, AxiosResponse } from "axios"

import { redirectToLogin } from "./browser"

/**
 * Return the CSRF token from the meta tag. Needed for targeting Rails servers.
 */
const getCsrfToken = (): string => {
  const meta = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
  if (meta) {
    return meta.content
  } else {
    throw new Error("Could not find CSRF token")
  }
}

/**
 * Send a request to the server with the given method and url.
 */
export const axiosServer = async <Data>(
  method: Method,
  url: string,
  appInstanceId: string,
  axiosConfig: RawAxiosRequestConfig = {},
): Promise<AxiosResponse<Data>> => {
  try {
    const params = { app_instance_id: appInstanceId }
    return await axios({
      method,
      url: appendQueryParams(url, params),
      ...{
        ...axiosConfig,
        headers: {
          ...(axiosConfig.headers as RawAxiosRequestHeaders),
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": getCsrfToken(),
        },
      },
    })
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401) {
        redirectToLogin(appInstanceId)
      } else {
        console.log(axiosErrorMessage(error))
      }
    }
    throw error
  }
}
