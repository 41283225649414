import * as React from "react"

const Map = ({ center, children }: { center: google.maps.LatLngLiteral; children: React.ReactNode }): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null)

  const [map, setMap] = React.useState<google.maps.Map>()

  React.useEffect(() => {
    if (ref.current && !map) {
      const map = new window.google.maps.Map(ref.current, {})
      map.setOptions({ center, zoom: 17 })
      setMap(map)
    }
  }, [ref, map, center])

  return (
    <React.Fragment>
      <div ref={ref} style={{ height: "100%" }} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // Set the map prop on the child component
          return React.cloneElement(child, { map })
        }
      })}
    </React.Fragment>
  )
}

export default Map
