import { Order } from "models/hubrise/Order"

export type Icon =
  | {
      type: "clientName"
      clientName: string
    }
  | {
      type: "imageURL"
      imageUrl: string
    }

abstract class Type {
  constructor(protected order: Order) {}

  abstract get icon(): Icon

  abstract get name(): string
}

export default Type
