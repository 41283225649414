import * as React from "react"
import { useTranslation } from "react-i18next"

import TextWithImage from "components/TextWithImage"
import { useConfig } from "contexts/ConfigContext"

const ConnectionLost = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    images: {
      icon_connection_lost: { url, retinaUrl },
    },
  } = useConfig()

  return <TextWithImage type="image" text={t("component.connection_lost.text")} $url={url} $retinaUrl={retinaUrl} />
}

export default ConnectionLost
