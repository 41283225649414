import * as React from "react"

import { Charge as ChargeModel } from "models/hubrise/order/Charge"

import { ItemName, Price, Spacer } from "./Styles"

const Charge = ({ charge }: { charge: ChargeModel }): JSX.Element => {
  const common = { $isDeleted: charge.deleted }
  return (
    <React.Fragment>
      <ItemName {...common}>{charge.name}</ItemName>
      <Price {...common} dangerouslySetInnerHTML={{ __html: charge.price.toHumanString() }} />
      <Spacer />
    </React.Fragment>
  )
}

export default Charge
