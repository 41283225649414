import { CatalogSummary } from "models/hubrise/CatalogSummary"

import { LocalStorage } from "./localStorage"

export const defaultCatalogId = (catalogSummaries: Array<CatalogSummary>): string => {
  const lastCatalogId = LocalStorage.lastCatalogId
  return catalogSummaries.some((catalogSummary) => catalogSummary.id === lastCatalogId)
    ? lastCatalogId!
    : catalogSummaries[0].id
}
