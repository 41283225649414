import { Button, Icon, colors, iconSizes } from "@hubrise/react-components"
import styled, { css } from "styled-components"

export const SearchInputButton = styled(Button)<{ $isExpanded: boolean }>`
  cursor: pointer;
  width: ${({ $isExpanded }) => ($isExpanded ? "min(25rem, 50vw)" : "auto")};
  background-color: ${({ $isExpanded }) => ($isExpanded ? colors.backgroundWhite : "transparent")};
`

export const StyledSearchInput = styled.input<{ $isExpanded: boolean }>`
  background-color: ${colors.backgroundWhite};
  border: none;
  outline: none;

  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          width: 100%;
          margin: 0 0.75rem;
          opacity: 1;
          visibility: visible;
        `
      : css`
          width: 0;
          margin: 0;
          opacity: 0;
          visibility: hidden;
        `}
`

export const CloseIcon = styled(Icon).attrs({ size: iconSizes._14, code: "close" })`
  cursor: pointer;
`

export const SearchIcon = styled(Icon).attrs({ size: iconSizes._20, code: "search" })`
  color: ${colors.textDark};
`
