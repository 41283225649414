import { burgerMenuBreakpoint } from "@hubrise/react-components"
import styled from "styled-components"

export const StyledCatalogSelector = styled.div`
  max-width: calc(min(70%, 35rem));

  @media (min-width: ${burgerMenuBreakpoint}) {
    max-width: 100%;
  }
`
