import * as React from "react"

interface CyclicRendererProps {
  children: () => React.ReactElement
}

/**
 * Renders its child on every minute change.
 */
const CyclicRenderer = ({ children }: CyclicRendererProps): React.ReactElement => {
  const [currentMinute, setCurrentMinute] = React.useState(new Date().getMinutes())

  React.useEffect(() => {
    const intervalId = setInterval(() => setCurrentMinute(new Date().getMinutes()), 1000)
    return () => clearInterval(intervalId)
  }, [currentMinute])

  return children()
}

export default CyclicRenderer
