import { colors, sizes, breakpoints } from "@hubrise/react-components"
import styled from "styled-components"

export const StyledMobile = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const StyledDesktop = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  }
`

export const DesktopItem = styled.button<{ $isActive?: boolean }>`
  width: 100%;
  max-width: 12.5rem;
  padding: 1rem 2rem;
  border-radius: ${sizes.borderRadius};

  color: ${colors.textDark};
  background-color: ${({ $isActive }) => ($isActive === true ? colors.backgroundLightest : colors.backgroundLight)};

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MobileDropdown = styled.ul`
  width: max-content;
`

export const MobileItem = styled.li`
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundLight};
  }
`
