import * as React from "react"

import type { Item } from "components/InventoryList/types"
import { useConfig } from "contexts/ConfigContext"
import { InventoryEntry } from "models/hubrise/CatalogInventory"

import { Dot } from "../Styles"

import Indicator from "./Indicator"
import {
  SkuItem,
  SkuList,
  Image,
  StyledRow,
  Thumbnail,
  Name,
  SkuName,
  SelectCheckbox,
  Title,
  StyledNoImage,
  Ref,
  ThumbnailAndTitle,
} from "./Styles"

interface ItemRowProps<I extends Item> {
  catalogId: string
  getInventoryEntry: (ref: string | null) => InventoryEntry | undefined
  item: I
  height: number
  isSelected: boolean
  isLastItem: boolean
  isEditable: boolean
  onCheck: (item: I) => void
  onClick: (item: I) => void
}

const ItemRow = <I extends Item>({
  catalogId,
  getInventoryEntry,
  item,
  height,
  isSelected,
  isLastItem,
  isEditable,
  onClick,
  onCheck,
}: ItemRowProps<I>): JSX.Element => {
  const { appInstanceId } = useConfig()

  const image_ids = "image_ids" in item ? item.image_ids : undefined

  const multiSkus = "skus" in item && item.skus.length > 1 ? item.skus : undefined
  const singleRef = multiSkus === undefined ? ("skus" in item ? item.skus[0].ref : item.ref) : null

  return (
    <StyledRow $isLastItem={isLastItem} onClick={() => isEditable && onClick(item)} $totalHeight={height}>
      <SelectCheckbox checked={isSelected} disabled={!isEditable} onChange={() => onCheck(item)} />

      <ThumbnailAndTitle>
        {image_ids !== undefined && (
          <Thumbnail>
            {image_ids.length > 0 ? (
              <Image
                src={`/catalogs/${catalogId}/images/${image_ids[0]}?app_instance_id=${appInstanceId}`}
                alt={item.name}
                loading="lazy"
              />
            ) : (
              <StyledNoImage />
            )}
          </Thumbnail>
        )}

        <Title>
          <Name>{item.name}</Name>
          {!multiSkus && (
            <>
              <RefWithTitle itemRef={singleRef} />
              <Indicator itemRef={singleRef} inventoryEntry={getInventoryEntry(singleRef)} />
            </>
          )}
        </Title>
      </ThumbnailAndTitle>

      {multiSkus && (
        <SkuList>
          {multiSkus.map((sku, index) => (
            <SkuItem key={index}>
              <Dot />
              <SkuName> {sku.name}</SkuName>
              <RefWithTitle itemRef={sku.ref} />
              <Indicator itemRef={sku.ref} inventoryEntry={getInventoryEntry(sku.ref)} />
            </SkuItem>
          ))}
        </SkuList>
      )}
    </StyledRow>
  )
}

export default ItemRow

const RefWithTitle = ({ itemRef }: { itemRef: string | null }): JSX.Element | null => {
  if (itemRef === null) {
    return null
  } else {
    return <Ref title={itemRef}>{itemRef}</Ref>
  }
}
