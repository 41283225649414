import _ from "lodash"
import * as React from "react"

import { isOptionUnavailable, isProductUnavailable } from "components/InventoryList/shared"
import { GroupOrItemWithType, OptionWithType, Section } from "components/InventoryList/types"
import { useInventoryContext } from "contexts/InventoryContext"
import { Catalog, Category, Option, OptionList, Product } from "models/hubrise/Catalog"
import { CatalogInventory } from "models/hubrise/CatalogInventory"

const useItems = (
  catalog: Catalog,
  catalogInventory: CatalogInventory,
  section: Section,
): Array<GroupOrItemWithType> => {
  const { showUnavailableItems, searchValue } = useInventoryContext()

  const cleansedSearchValue = searchValue.toLowerCase().trim()

  const match = React.useCallback(
    (nameOrRef: string | null): boolean => nameOrRef?.toLowerCase()?.includes(cleansedSearchValue) ?? false,
    [cleansedSearchValue],
  )

  const productsByCategoryId = React.useMemo(
    () => _.groupBy(catalog.data.products, "category_id"),
    [catalog.data.products],
  )

  const categoriesByParentId = React.useMemo(
    () => _.groupBy(catalog.data.categories, "parent_id"),
    [catalog.data.categories],
  )

  const rootCategories = React.useMemo(
    () => catalog.data.categories.filter((category) => category.parent_id === null),
    [catalog.data.categories],
  )

  const flattenOptions = React.useMemo(
    () => catalog.data.option_lists.flatMap((optionList) => optionList.options),
    [catalog.data.option_lists],
  )

  const optionsByOptionListId = React.useMemo(() => {
    return _.groupBy(flattenOptions, "option_list_id")
  }, [flattenOptions])

  const computeProductItems = React.useCallback((): Array<GroupOrItemWithType> => {
    const refMatch = (product: Product) => product.skus.some((sku) => match(sku.ref))

    const filterProducts = (ancestors: Array<Category>, products: Array<Product>): Array<Product> =>
      products.filter(
        (product) =>
          (ancestors.some((category) => match(category.name)) || match(product.name) || refMatch(product)) &&
          (!showUnavailableItems || isProductUnavailable(product, catalogInventory)),
      )

    const traverse = (ancestors: Array<Category>): Array<GroupOrItemWithType> => {
      const parentCategory = ancestors[ancestors.length - 1]

      const childCategories = categoriesByParentId[parentCategory.id] ?? []
      let result = childCategories.flatMap((category) => traverse([...ancestors, category]))

      const products = productsByCategoryId[parentCategory.id] ?? []
      result = result.concat(filterProducts(ancestors, products).map((product) => ({ type: "product", data: product })))

      return result.length > 0 ? [{ type: "category", data: parentCategory }, ...result] : []
    }

    return rootCategories.flatMap((category) => traverse([category]))
  }, [rootCategories, match, showUnavailableItems, catalogInventory, categoriesByParentId, productsByCategoryId])

  const computeOptionItems = React.useCallback((): Array<GroupOrItemWithType> => {
    const sort = (options: Array<Option>) => _.sortBy(options, (option) => option.name.toLowerCase())

    if (cleansedSearchValue || showUnavailableItems) {
      // With a filter applied, only show the matching options without option lists, deduplicated by ref
      const filterUnavailable = (options: Array<Option>) =>
        options.filter((option) => !showUnavailableItems || isOptionUnavailable(option, catalogInventory))

      const matchingOptions = (optionList: OptionList, options: Array<Option>) =>
        match(optionList.name) ? options : options.filter((option) => match(option.name) || match(option.ref))

      const options = Object.entries(optionsByOptionListId).flatMap(([optionListId, options]) => {
        const optionList = catalog.data.option_lists.find((optionList) => optionList.id === optionListId)!
        return filterUnavailable(matchingOptions(optionList, options))
      })

      return sort(_.uniqBy(options, "ref")).map((option) => ({ type: "option", data: option }))
    } else {
      // No filter, show all options within their option lists
      return catalog.data.option_lists.flatMap((optionList) => {
        const options = sort(optionsByOptionListId[optionList.id])
        if (options.length === 0) return []
        const optionItems: Array<OptionWithType> = options.map((option) => ({ type: "option", data: option }))
        return [{ type: "option_list", data: optionList }, ...optionItems]
      })
    }
  }, [
    catalog.data.option_lists,
    catalogInventory,
    cleansedSearchValue,
    match,
    optionsByOptionListId,
    showUnavailableItems,
  ])

  return React.useMemo(
    () => (section === "products" ? computeProductItems() : computeOptionItems()),
    [section, computeProductItems, computeOptionItems],
  )
}

export default useItems
