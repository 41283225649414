import { Icon } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import type { Status } from "models/hubrise/types"

import Popup from "./Popup"
import { Container, Label, StatusButton, Statuses } from "./Styles"

interface StatusSelectProps {
  label?: string
  popupTitle: string
  popupSubtitle?: string
  statuses: Array<Status>
  onChange: (statuses: Array<Status>) => void
}

const StatusSelect = ({
  label,
  popupTitle,
  popupSubtitle,
  statuses: initialStatuses,
  onChange,
}: StatusSelectProps): JSX.Element => {
  const { t } = useTranslation()
  const [popupOnElement, setPopupOnElement] = React.useState<HTMLElement | null>(null)

  const [statuses, setStatuses] = React.useState(initialStatuses)

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setPopupOnElement(event.currentTarget)
  }, [])

  return (
    <Container>
      {label !== undefined && <Label onClick={handleClick}>{label}</Label>}

      <Statuses>
        {statuses.map((status, index) => (
          <StatusButton key={index} $status={status} onClick={handleClick}>
            {t(`order.status.${status}`)}
          </StatusButton>
        ))}

        <StatusButton $status="gray" onClick={handleClick}>
          <Icon code={"add"} />
        </StatusButton>
      </Statuses>

      {popupOnElement && (
        <Popup
          element={popupOnElement}
          title={popupTitle}
          subtitle={popupSubtitle}
          statuses={statuses}
          onChange={(statuses) => {
            setStatuses(statuses)
          }}
          onClose={() => {
            setPopupOnElement(null)
            onChange(statuses)
          }}
        />
      )}
    </Container>
  )
}

export default StatusSelect
