import { DateTime, Interval } from "luxon"
import type { TFunction } from "react-i18next"

import { Catalog, Category, Option, Product } from "models/hubrise/Catalog"
import { CatalogInventory, InventoryEntry } from "models/hubrise/CatalogInventory"

import { Section } from "./types"

export const skuInventoryEntry = (skuRef: string, catalogInventory: CatalogInventory): InventoryEntry | undefined =>
  catalogInventory.filter((entry) => entry.type === "sku" && entry.ref === skuRef)?.[0]

export const optionInventoryEntry = (
  optionRef: string,
  catalogInventory: CatalogInventory,
): InventoryEntry | undefined =>
  catalogInventory.filter((entry) => entry.type === "option" && entry.ref === optionRef)?.[0]

// "15:30" for 15:30
// "Tomorrow morning" for D+1 @ cutoff
// "Tomorrow 9:00" for D+1 other times
// "Monday 10:30" for D+2 - D+7
// "3/7/2022 11:35" for other dates
export const readableExpiresAt = (
  expiresAt: Date,
  cutoff: number,
  timezone: string,
  t: TFunction<"translation", undefined>,
): string => {
  const now = DateTime.now().setZone(timezone)
  const tomorrow = now.plus({ days: 1 })
  const tomorrowAtCutoff = tomorrow.startOf("day").plus({ seconds: cutoff })
  const in7Days = now.plus({ days: 7 })

  const expiresAtLuxon = DateTime.fromJSDate(expiresAt).setZone(timezone)
  const readableDate = expiresAtLuxon.toFormat("d/M/yyyy")
  const readableDayOfWeek = expiresAtLuxon.toFormat("EEEE")
  const readableTime = expiresAtLuxon.toFormat("HH:mm")

  if (expiresAtLuxon.hasSame(now, "day")) {
    const minutesLeft = Math.round(Interval.fromDateTimes(now, expiresAtLuxon).length("minutes"))
    if (minutesLeft >= 60) {
      const hoursLeft = Math.round(minutesLeft / 60)
      return hoursLeft > 1 ? t("inventory.expiration.hours", { count: hoursLeft }) : t("inventory.expiration.hour")
    } else if (minutesLeft >= 1) {
      return minutesLeft > 1
        ? t("inventory.expiration.minutes", { count: minutesLeft })
        : t("inventory.expiration.minute")
    } else {
      return t("inventory.expiration.lessThanMinute")
    }
  } else if (expiresAtLuxon.hasSame(tomorrow, "day")) {
    if (expiresAtLuxon.equals(tomorrowAtCutoff)) {
      return t("inventory.expiration.tomorrowMorning")
    } else {
      return `${t("inventory.expiration.tomorrow")} ${readableTime}`
    }
  } else if (expiresAtLuxon < in7Days) {
    return `${readableDayOfWeek} ${readableTime}`
  } else {
    return `${readableDate} ${readableTime}`
  }
}

export const isProductEditable = (product: Product) => {
  return product.skus.some((sku) => sku.ref != null)
}

export const isOptionEditable = (option: Option) => {
  return option.ref != null
}

export const isProductUnavailable = (product: Product, catalogInventory: CatalogInventory) =>
  product.skus.some((sku) => {
    const entry = catalogInventory.find((item) => item.type === "sku" && item.ref === sku.ref)
    return entry?.stock === 0
  })

export const isOptionUnavailable = (option: Option, catalogInventory: CatalogInventory) => {
  const entry = catalogInventory.find((item) => item.type === "option" && item.ref === option.ref)
  return entry?.stock === 0
}

export const editableProductIdsFromCategory = (category: Category, catalog: Catalog): Array<string> => {
  const { products, categories } = catalog.data
  const childCategories = categories.filter((category) => category.parent_id === category.id)
  return [
    ...products
      .filter((product) => product.category_id === category.id && isProductEditable(product))
      .map((product) => product.id),
    ...childCategories.flatMap((category) => editableProductIdsFromCategory(category, catalog)),
  ]
}

export const adjustTitle = (
  section: Section,
  count: number,
  fallbackIfOne: string | undefined,
  t: TFunction<"translation", undefined>,
): string => {
  const target =
    count === 1 && fallbackIfOne !== undefined ? fallbackIfOne : t(`inventory.adjust.${section}`, { count })
  return t("inventory.adjust.title", { target })
}
