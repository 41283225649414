import { breakpoints, colors, fontSizes, mixin } from "@hubrise/react-components"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1.25rem;
`

export const Text = styled.div`
  margin-top: 1rem;
  color: ${colors.textMedium};
  font-size: ${fontSizes._18};

  @media (min-width: ${breakpoints.mobile}) {
    margin-top: 1.25rem;
    max-width: 28rem;
    line-height: 1.5;
  }
`

export const Image = styled.div<{ $url: string; $retinaUrl: string }>`
  ${({ $url, $retinaUrl }) => mixin.backgroundImage($url, $retinaUrl)}
  width: 100%;
  height: 3.25rem;
`
