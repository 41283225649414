import { useOnOutsideClick } from "@hubrise/react-components"
import * as React from "react"

import {
  CloseIcon,
  SearchIcon,
  SearchInputButton,
  StyledSearchInput,
} from "components/InventoryList/Toolbar/SearchInput/Styles"
import { useInventoryContext } from "contexts/InventoryContext"

interface SearchInputProps {
  className?: string
}

const SearchInput: React.FC<SearchInputProps> = ({ className }) => {
  const { searchValue, setSearchValue } = useInventoryContext()

  const [isExpanded, setIsExpanded] = React.useState(searchValue !== "")

  const inputRef = React.useRef<HTMLInputElement>(null)
  const inputWrapperRef = React.useRef<HTMLButtonElement>(null)

  useOnOutsideClick([inputWrapperRef], true, () => {
    if (searchValue === "") setIsExpanded(false)
  })

  const focusInput = () => inputRef.current?.focus()

  const selectAll = () => inputRef.current?.select()

  const onClick = () => {
    if (isExpanded && searchValue !== "") {
      selectAll()
    } else {
      setIsExpanded((prevExpanded) => {
        if (!prevExpanded) setTimeout(focusInput, 0)
        return !prevExpanded
      })
    }
  }

  const clearText = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation() // Prevent propagation of click, which would collapse the input
    setSearchValue("")
    focusInput()
  }

  return (
    <SearchInputButton
      onClick={onClick}
      $isExpanded={isExpanded}
      ref={inputWrapperRef}
      className={className}
      variant="emptyOverGray"
    >
      <SearchIcon />

      <StyledSearchInput
        $isExpanded={isExpanded}
        tabIndex={-1}
        ref={inputRef}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyUp={(e) => {
          // Necessary on Chrome to prevent propagation of the space key, which would trigger a click and select all.
          e.preventDefault()
        }}
      />

      {searchValue !== "" && <CloseIcon onClick={clearText} />}
    </SearchInputButton>
  )
}

export default SearchInput
