import * as React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import useTabName from "./useTabName"

const useTitle = (nb: number) => {
  const { t } = useTranslation()
  const tabName = useTabName()

  const { orderId } = useParams<{ orderId: string }>()

  const title = React.useCallback(() => {
    if (orderId !== undefined) {
      return t("titles.order", { orderId: orderId })
    }

    const title = t(`titles.${tabName}`)
    return nb > 0 ? t(`titles.with_nb`, { nb, title }) : title
  }, [orderId, tabName, t, nb])

  React.useEffect(() => {
    document.title = title()
  }, [title])
}

export default useTitle
