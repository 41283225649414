import { colors, ModalStyle as Modal } from "@hubrise/react-components"
import styled from "styled-components"

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 2rem;
`

export const Address = styled.div`
  font-weight: normal;
  color: ${colors.textLight};
  margin-top: 0.5rem;
`

export const Body = styled(Modal.Body)`
  height: 42rem;
  max-height: calc(100vh - 4.5rem);
  padding: 0;
`
