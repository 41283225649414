import { breakpoints, mixin, zIndexValues, Button, burgerMenuBreakpoint } from "@hubrise/react-components"
import styled from "styled-components"

export const DesktopButton = styled(Button)`
  display: none;

  @media (min-width: ${burgerMenuBreakpoint}) {
    display: block;
    margin-left: auto;
  }
`

export const MobileButton = styled(Button)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3rem;
  z-index: ${zIndexValues.dropdownInModal};
  ${mixin.centerElement};

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
