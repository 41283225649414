import { DateTime } from "luxon"

export function parseDate(date: string): DateTime
export function parseDate(date: string | null): DateTime | null
export function parseDate(date: string | null) {
  return date === null ? null : DateTime.fromISO(date, { setZone: true })
}

export function parseDecimal(decimal: string, options?: { nullIfZero: boolean }): number
export function parseDecimal(decimal: string | null, options?: { nullIfZero: boolean }): number | null
export function parseDecimal(decimal: string | null, options?: { nullIfZero: boolean }) {
  if (decimal === null || (Number(decimal) === 0 && options && options["nullIfZero"])) {
    return null
  } else {
    return Number(decimal)
  }
}
