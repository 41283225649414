import * as React from "react"

import ConnectionLost from "components/ConnectionLost"
import { withLayout } from "components/Layout"
import Loading from "components/Loading"
import NoOrders from "components/NoOrders"
import OrderList from "components/OrderList"
import { useConfig } from "contexts/ConfigContext"
import { useOrderContext } from "contexts/OrderContext"
import useTitle from "hooks/useTitle"
import { Order } from "models/hubrise/Order"

const Today = withLayout((): JSX.Element => {
  const { cutoff } = useConfig()
  const { todayAndFutureOrders, futureOrders, nbNewFutureOrders, isConnectionLost } = useOrderContext()

  useTitle(nbNewFutureOrders)

  if (isConnectionLost) {
    return <ConnectionLost />
  } else if (todayAndFutureOrders.isLoading) {
    return <Loading />
  } else if (futureOrders.length === 0) {
    return <NoOrders />
  }

  const orderGroups = Order.groupByDate(futureOrders, cutoff)

  return (
    <React.Fragment>
      {orderGroups.map(({ orders, day }, index) => (
        <OrderList key={index} orders={orders} day={day} />
      ))}
    </React.Fragment>
  )
}, "Future")

export default Today
