import * as React from "react"
import { useSearchParams } from "react-router-dom"

import { useConfig } from "contexts/ConfigContext"
import { useOrderContext } from "contexts/OrderContext"
import { HistoryFilter } from "contexts/OrderContext/types"
import { Day } from "models/Day"

const useSearchBarParams = (): HistoryFilter => {
  const { appInstanceId } = useConfig()

  const [searchParams, setSearchParamsNoUse] = useSearchParams()

  const {
    historyFilter: { from, to, search, setFrom, setTo, setSearch },
  } = useOrderContext()

  const setSearchParam = React.useCallback(
    (key: "from" | "to" | "search", value: string): void => {
      const defaultParams = { from: dayToParam(from), to: dayToParam(to), search }
      setSearchParamsNoUse({ ...defaultParams, [key]: value, app_instance_id: appInstanceId })
    },
    [from, to, search, setSearchParamsNoUse, appInstanceId],
  )

  const fromParam = paramToDay(searchParams.get("from"))
  const toParam = paramToDay(searchParams.get("to"))
  const searchParam = searchParams.get("search")

  React.useEffect(() => {
    if (fromParam && !fromParam.equals(from)) setFrom(fromParam)
    if (toParam && !toParam.equals(to)) setTo(toParam)
    if (searchParam !== null && searchParam !== search) setSearch(searchParam)
  }, [fromParam, toParam, searchParam, setFrom, setTo, setSearch, from, to, search])

  return React.useMemo(() => {
    return {
      from,
      to,
      search,
      setFrom: (day: Day) => {
        setSearchParam("from", dayToParam(day))
        setFrom(day)
      },
      setTo: (day: Day) => {
        setSearchParam("to", dayToParam(day))
        setTo(day)
      },
      setSearch: (search: string) => {
        setSearchParam("search", search)
        setSearch(search)
      },
    }
  }, [setSearchParam, from, setFrom, to, setTo, search, setSearch])
}

export default useSearchBarParams

const paramToDay = (str: string | null): Day | null => {
  if (str === null) return null
  const [year, month, day] = str.split("-")
  return new Day(parseInt(year), parseInt(month), parseInt(day))
}

const dayToParam = (theDay: Day): string => {
  const { day, month, year } = theDay
  return [year, month, day].join("-")
}
