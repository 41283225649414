import * as React from "react"

import Loading from "components/Loading"
import OrderReceipt from "components/OrderReceipt"
import useOrder from "hooks/useOrder"
import usePrintIframe from "hooks/usePrintIframe"

const Receipt = (): JSX.Element => {
  const order = useOrder()
  const { markAsLoaded } = usePrintIframe()

  React.useEffect(() => {
    if (order) markAsLoaded()
  }, [order, markAsLoaded])

  React.useEffect(() => {
    document.title = order ? [order.createdAt.toFormat("yyyyMMdd_HHmm"), order.id].join("_") : "Loading..."
  }, [order])

  if (!order) return <Loading />

  return <OrderReceipt order={order} />
}

export default Receipt
