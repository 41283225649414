import { fontSizes, Icon } from "@hubrise/react-components"
import styled from "styled-components"

export const Text = styled.div<{ $strong?: boolean }>`
  font-size: ${fontSizes._14};
  font-weight: ${({ $strong }) => ($strong === true ? "bold" : "normal")};
  white-space: nowrap;
`

export const ExpiresAt = styled.div`
  font-size: ${fontSizes._14};
  white-space: nowrap;
`

export const IndicatorIcon = styled(Icon)`
  margin-right: -0.25rem;
`
