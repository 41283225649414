import { toastError } from "@hubrise/react-components"
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { generateQueryKey, ResourcePath } from "hooks/queries"
import Settings from "models/Settings"
import { ApiCatalogInventory, ApiInventoryEntry } from "models/hubrise/CatalogInventory"
import { Location } from "models/hubrise/Location"
import { User } from "models/hubrise/User"
import { axiosServer } from "utils/server"

type UseMutation = <Data, Variables, Context>(
  options: UseMutationOptions<Data, Error, Variables, Context>,
) => UseMutationResult<Data, Error, Variables, Context>

const useMutationWithErrorHandler: UseMutation = (options) => {
  const { t } = useTranslation()
  const { onError, ...otherOptions } = options

  return useMutation({
    onError: (error, variables, context) => {
      const response = (error as AxiosError)?.response
      toastError(
        t("general.error"),
        t("general.server_returned_error", { status: response?.status, message: JSON.stringify(response?.data) }),
      )
      onError?.(error, variables, context)
    },
    ...otherOptions,
  })
}

const useMutationWithOptimisticUpdate = <ResourceType>(method: "PATCH", path: ResourcePath) => {
  const queryClient = useQueryClient()
  const { appInstanceId } = useConfig()
  const queryKey = generateQueryKey("GET", path, appInstanceId)

  return useMutationWithErrorHandler({
    mutationFn: async (newResource: Partial<ResourceType>) => {
      const response = await axiosServer<ResourceType>(method, path, appInstanceId, { data: newResource })
      return response.data
    },
    onMutate: async (newResource) => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData(queryKey) as ResourceType
      // Optimistic update
      queryClient.setQueryData<ResourceType>(queryKey, (oldResource) => ({
        ...(oldResource as ResourceType),
        ...newResource,
      }))
      return { previous }
    },
    onError: (_, __, context?: { previous: ResourceType }) => {
      // Rollback optimistic update
      queryClient.setQueryData<ResourceType>(queryKey, context?.previous)
    },
    onSuccess: (data) => {
      queryClient.setQueryData<ResourceType>(queryKey, data)
    },
  })
}

export const useSettingsMutation = () => {
  return useMutationWithOptimisticUpdate<Settings>("PATCH", "/settings")
}

export const useLocationMutation = () => {
  return useMutationWithOptimisticUpdate<Location>("PATCH", "/location")
}

export const useUserMutation = () => {
  return useMutationWithOptimisticUpdate<User>("PATCH", "/user")
}

export const useCatalogInventoryMutation = (catalogId: string) => {
  const path: ResourcePath = `/catalogs/${catalogId}/inventory`
  const queryClient = useQueryClient()
  const { appInstanceId } = useConfig()
  const queryKey = generateQueryKey("GET", path, appInstanceId)

  const areEntryRefsEqual = (entry: ApiInventoryEntry, modifiedEntry: ApiInventoryEntry): boolean => {
    return (
      (entry.sku_ref !== undefined && entry.sku_ref === modifiedEntry.sku_ref) ||
      (entry.option_ref !== undefined && entry.option_ref === modifiedEntry.option_ref)
    )
  }

  return useMutationWithErrorHandler({
    mutationFn: async (newResource: Partial<ApiCatalogInventory>) => {
      const response = await axiosServer<ApiCatalogInventory>("PATCH", path, appInstanceId, { data: newResource })
      return response.data
    },
    onMutate: async (modifiedEntries) => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData(queryKey) as ApiCatalogInventory
      // The PATCH request does not return the updated inventory, so we need to update it optimistically
      queryClient.setQueryData<ApiCatalogInventory>(queryKey, (oldInventory) => {
        let newInventory = oldInventory as ApiCatalogInventory
        for (const modifiedEntry of modifiedEntries as Array<ApiInventoryEntry>) {
          const index = newInventory.findIndex((entry) => areEntryRefsEqual(entry, modifiedEntry))
          if (index === -1) {
            newInventory = [...newInventory, modifiedEntry]
          } else if (modifiedEntry.stock === null) {
            newInventory = newInventory.filter((entry) => !areEntryRefsEqual(entry, modifiedEntry))
          } else {
            newInventory = [...newInventory.filter((entry) => !areEntryRefsEqual(entry, modifiedEntry)), modifiedEntry]
          }
        }
        return newInventory
      })
      return { previous }
    },
    onError: (_, __, context?: { previous: ApiCatalogInventory }) => {
      // Rollback optimistic update
      queryClient.setQueryData<ApiCatalogInventory>(queryKey, context?.previous)
    },
  })
}
