import { Field } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { useSettingsMutation } from "hooks/mutations"

import { Row } from "../Styles"

const Inventory = (): JSX.Element => {
  const { t } = useTranslation()

  const { settings } = useConfig()
  const { mutate } = useSettingsMutation()

  return (
    <React.Fragment>
      <Row>
        <Field.Checkbox
          name="inventory_stock_counter"
          text={t("popup.settings.tabs.inventory.stock_counter")}
          value={settings.inventory_stock_counter}
          onChange={(value) => mutate({ inventory_stock_counter: value })}
        />
      </Row>
    </React.Fragment>
  )
}

export default Inventory
