import * as React from "react"
import { useTranslation } from "react-i18next"

import { adjustTitle } from "components/InventoryList/shared"
import { Section } from "components/InventoryList/types"

import { MobileButton } from "./Styles"

interface ButtonProps {
  openModal: () => void
  section: Section
  count: number
}

const MobileAdjustInventoryButton = ({ openModal, section, count }: ButtonProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <MobileButton variant="primary" onClick={openModal}>
      {adjustTitle(section, count, undefined, t)}
    </MobileButton>
  )
}

export default MobileAdjustInventoryButton
