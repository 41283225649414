import { Field } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { useSettingsMutation } from "hooks/mutations"
import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Intervals, Repeats, Ringtones } from "models/Settings"
import type { Ringtone } from "models/Settings"
import { playRingtone } from "utils/ringtone"

import { Form, Row, Subtitle, Title } from "../Styles"

const Notifications = () => {
  const { t } = useTranslation()
  const { colon } = useTranslationFormatters()
  const { settings } = useConfig()
  const { notification_ringtone, notification_repeats, notification_interval } = settings
  const { mutate } = useSettingsMutation()

  const ringtoneOptions = Ringtones.map((ringtone) => ({
    value: ringtone,
    label: t(`popup.settings.tabs.notifications.sound.ringtones.${ringtone}`),
  }))

  const updateRingtone = (value: Array<string>) => {
    const notification_ringtone = value[0] as Ringtone
    playRingtone(notification_ringtone)
    mutate({ notification_ringtone })
  }

  const repeatOptions = Repeats.map((repeat) => ({
    value: String(repeat),
    label: t(`popup.settings.tabs.notifications.sound.repeats.${repeat}`),
  }))

  const updateRepeats = (value: Array<string>) => {
    const notification_repeats = Number(value[0])
    mutate({ notification_repeats })
  }

  const intervalOptions = Intervals.map(({ label }) => ({
    value: label,
    label: t(`popup.settings.tabs.notifications.sound.interval.${label}`),
  }))

  const updateInterval = (value: Array<string>) => {
    const notification_interval = value[0]
    mutate({ notification_interval })
  }

  return (
    <React.Fragment>
      <Row>
        <Title>
          {t(`popup.settings.tabs.notifications.sound.title`)}
          <Subtitle>{t(`popup.settings.tabs.notifications.sound.subtitle`)}</Subtitle>
        </Title>

        <Form>
          <Field.Select
            options={ringtoneOptions}
            value={[notification_ringtone]}
            onChange={updateRingtone}
            label={t(`popup.settings.tabs.notifications.sound.new_order_sound`) + colon}
          />

          {notification_ringtone !== "silent" && (
            <React.Fragment>
              <Field.Select
                options={repeatOptions}
                value={[String(notification_repeats)]}
                onChange={updateRepeats}
                label={t(`popup.settings.tabs.notifications.sound.new_order_repeats`) + colon}
              />
              <Field.Select
                options={intervalOptions}
                value={[notification_interval]}
                onChange={updateInterval}
                label={t(`popup.settings.tabs.notifications.sound.new_order_interval`) + colon}
              />
            </React.Fragment>
          )}
        </Form>
      </Row>
    </React.Fragment>
  )
}

export default Notifications
