import * as React from "react"

import { Order } from "models/hubrise/Order"

import Customer from "./Customer"
import CustomerNotes from "./CustomerNotes"
import Details from "./Details"
import Items from "./Items"
import LocationInfo from "./LocationInfo"
import Loyalty from "./Loyalty"
import { GlobalStyle, Receipt } from "./Styles"
import Summary from "./Summary"
import Title from "./Title"

const OrderReceipt = ({ order }: { order: Order }): JSX.Element => (
  <Receipt>
    <GlobalStyle />

    <Title />
    <Summary order={order} />
    <Customer order={order} />
    <Items order={order} />
    <CustomerNotes order={order} />
    <Loyalty order={order} />
    <Details order={order} />
    <LocationInfo />
  </Receipt>
)

export default OrderReceipt
