import * as React from "react"
import { useTranslation } from "react-i18next"

import TextWithImage from "components/TextWithImage"
import { useConfig } from "contexts/ConfigContext"

const NoOrders = ({ forSearch = false }: { forSearch?: boolean }): JSX.Element => {
  const { t } = useTranslation()
  const {
    images: {
      icon_no_orders: { url, retinaUrl },
    },
  } = useConfig()

  return (
    <TextWithImage
      type="image"
      text={forSearch ? t("component.search_no_orders.text") : t("component.generally_no_orders.text")}
      $url={url}
      $retinaUrl={retinaUrl}
    />
  )
}

export default NoOrders
