import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"

import { Line, LocationSection, ThankYou } from "./Styles"

const LocationInfo = (): JSX.Element => {
  const { t } = useTranslation()
  const { location } = useConfig()

  const lines = [
    location.account.name + " " + location.name,
    location.address,
    [location.postal_code, location.city].filter(Boolean).join(" "),
  ].filter(Boolean)

  return (
    <LocationSection>
      {lines.map((line, index) => (
        <Line key={index}>{line}</Line>
      ))}
      <ThankYou>{t("popup.print_order.thank_you")}</ThankYou>
    </LocationSection>
  )
}

export default LocationInfo
