import { DateTime } from "luxon"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"

import {
  AvailableOptions,
  OptionsLabel,
  StyledAvailability,
  RadioButton,
  StyledNumberInput,
  UnavailableOptions,
} from "./Styles"

export type AvailabilityState =
  | {
      available: true
      stock: number | null
      expiresAt: null
    }
  | {
      available: false
      stock: 0
      expiresAt: DateTime | null
    }

interface AvailabilityProps {
  availability: AvailabilityState
  setAvailability: React.Dispatch<React.SetStateAction<AvailabilityState>>
}

const Availability = ({ availability, setAvailability }: AvailabilityProps): JSX.Element => {
  const { t } = useTranslation()
  const { cutoff, timezone } = useConfig()
  const { settings } = useConfig()

  const now = DateTime.now().setZone(timezone)
  const tomorrowAtCutoff = now.startOf("day").plus({ days: 1, seconds: cutoff })

  const expiresWithin = (nbHours: number): boolean => {
    return availability.expiresAt !== null && availability.expiresAt.diff(now, "hours").hours <= nbHours
  }

  return (
    <StyledAvailability>
      <RadioButton
        $isSelected={availability.available}
        onClick={() => {
          setAvailability({ available: true, stock: null, expiresAt: null })
        }}
      >
        {t("inventory.modal.button_available")}
      </RadioButton>

      <RadioButton
        $isSelected={!availability.available}
        onClick={() => setAvailability({ available: false, stock: 0, expiresAt: null })}
      >
        {t("inventory.modal.button_unavailable")}
      </RadioButton>

      {availability.available ? (
        settings.inventory_stock_counter && (
          <AvailableOptions>
            <OptionsLabel>{t("inventory.modal.available_input_label")}</OptionsLabel>

            <StyledNumberInput
              step={1}
              value={availability.stock}
              onChange={(stock) => {
                setAvailability({ available: true, stock: Number(stock), expiresAt: null })
              }}
            />
          </AvailableOptions>
        )
      ) : (
        <UnavailableOptions>
          <OptionsLabel>{t("inventory.modal.unavailable_select_label")}</OptionsLabel>

          <RadioButton
            $isSelected={!availability.available && availability.expiresAt == null}
            onClick={() => setAvailability({ available: false, stock: 0, expiresAt: null })}
          >
            {t("inventory.status.unavailable_until.unlimited")}
          </RadioButton>

          <RadioButton
            $isSelected={expiresWithin(1)}
            onClick={() => setAvailability({ available: false, stock: 0, expiresAt: now.plus({ hours: 1 }) })}
          >
            {t("inventory.status.unavailable_until.1_hour")}
          </RadioButton>

          <RadioButton
            $isSelected={expiresWithin(4) && !expiresWithin(1)}
            onClick={() => setAvailability({ available: false, stock: 0, expiresAt: now.plus({ hours: 4 }) })}
          >
            {t("inventory.status.unavailable_until.4_hours")}
          </RadioButton>

          <RadioButton
            $isSelected={expiresWithin(24) && !expiresWithin(4)}
            onClick={() => setAvailability({ available: false, stock: 0, expiresAt: tomorrowAtCutoff })}
          >
            {t("inventory.status.unavailable_until.tomorrow_morning")}
          </RadioButton>
        </UnavailableOptions>
      )}
    </StyledAvailability>
  )
}

export default Availability
