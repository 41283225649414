import * as React from "react"

import { withLayout } from "components/Layout"
import OpeningHoursTable from "components/OpeningHoursTable"
import { useConfig } from "contexts/ConfigContext"
import useTitle from "hooks/useTitle"

const OpeningHours = withLayout((): JSX.Element => {
  const { location } = useConfig()
  useTitle(0)

  return <OpeningHoursTable location={location} />
})

export default OpeningHours
