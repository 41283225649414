import { colors, Button, burgerMenuBreakpoint, breakpoints } from "@hubrise/react-components"
import styled from "styled-components"

import { DESKTOP_ASIDE_WIDTH } from "components/Layout/Styles"

export const StyledToolbar = styled.div`
  display: grid;
  grid-template-rows: 1fr;

  @media (min-width: ${burgerMenuBreakpoint}) {
    grid-template-columns: ${DESKTOP_ASIDE_WIDTH} 1fr;
  }
`

export const ToolbarPart = styled.div`
  height: 3.3125rem;
  width: 100vw;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.borderLight};

  @media (min-width: ${burgerMenuBreakpoint}) {
    width: unset;
    justify-content: flex-start;
    border-bottom: 0;
  }
`

export const ControlGroup = styled.div`
  display: flex;
  align-items: center;
`

export const IconButton = styled(Button)`
  @media (min-width: ${breakpoints.tablet}) {
    margin-left: -1rem;
  }
`
