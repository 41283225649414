import type { Method } from "@hubrise/react-components"
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister"
import { QueryClient, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"
import { AxiosError } from "axios"

import Settings from "models/Settings"
import { Catalog } from "models/hubrise/Catalog"
import { ApiCatalogInventory } from "models/hubrise/CatalogInventory"
import { CatalogSummary } from "models/hubrise/CatalogSummary"
import { Location } from "models/hubrise/Location"
import { User } from "models/hubrise/User"
import { defaultCatalogId } from "utils/paths"
import { axiosServer } from "utils/server"

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

const ONE_HOUR = 60 * 60 * 1000

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // gcTime should be greater or equal to maxAge, whose default is 24 hours.
      // See https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient
      gcTime: 24 * ONE_HOUR,
    },
  },
})

export type ResourcePath =
  | "/settings"
  | "/location"
  | "/user"
  | "/catalogs"
  | `/catalogs/${string}`
  | `/catalogs/${string}/inventory`

export const generateQueryKey = (method: Method, path: ResourcePath, appInstanceId: string) => {
  return [method, path, appInstanceId]
}

export const useSettings = (appInstanceId: string): UseQueryResult<Settings> => {
  return useServerQuery<Settings>("/settings", appInstanceId, { staleTime: ONE_HOUR })
}

export const useLocation = (appInstanceId: string): UseQueryResult<Location> => {
  return useServerQuery<Location>("/location", appInstanceId, { staleTime: ONE_HOUR })
}

export const useUser = (appInstanceId: string): UseQueryResult<User> => {
  return useServerQuery<User>("/user", appInstanceId, { staleTime: ONE_HOUR })
}

export const useCatalogSummariesQuery = (appInstanceId: string): UseQueryResult<CatalogSummary[]> => {
  return useServerQuery<CatalogSummary[]>("/catalogs", appInstanceId, { staleTime: ONE_HOUR })
}

export const useCatalogQuery = (catalogId: string, appInstanceId: string): UseQueryResult<Catalog> => {
  return useServerQuery<Catalog>(`/catalogs/${catalogId}`, appInstanceId, { staleTime: 24 * ONE_HOUR })
}

export const useCatalogInventoryQuery = (
  catalogId: string,
  appInstanceId: string,
): UseQueryResult<ApiCatalogInventory> => {
  return useServerQuery<ApiCatalogInventory>(`/catalogs/${catalogId}/inventory`, appInstanceId, { staleTime: ONE_HOUR })
}

export const usePrefetchCatalogAndInventory = (appInstanceId: string) => {
  const queryClient = useQueryClient()
  useQuery({
    queryKey: ["prefetchCatalogAndInventory", appInstanceId],
    queryFn: async () => {
      const { data: catalogSummaries } = await axiosServer<CatalogSummary[]>("GET", "/catalogs", appInstanceId, {})
      const catalogId = defaultCatalogId(catalogSummaries)

      await Promise.all([
        prefetchServerQuery<Catalog>(`/catalogs/${catalogId}`, appInstanceId, queryClient),
        prefetchServerQuery<ApiCatalogInventory>(`/catalogs/${catalogId}/inventory`, appInstanceId, queryClient),
      ])
      return true // Return some data to avoid a warning in the console
    },
  })
}

type UseServerOptions = {
  staleTime: number
}

function useServerQuery<Data>(
  path: ResourcePath,
  appInstanceId: string,
  { staleTime }: UseServerOptions,
): UseQueryResult<Data, AxiosError> {
  const result = useQuery<Data, AxiosError>({
    ...queryKeyAndFn("GET", path, appInstanceId),
    staleTime,
  })

  // Errors raised within useQuery are not logged by React Query
  if (result.error) console.error(`useServerQuery GET ${path}: ${result.error}`)
  return result
}

async function prefetchServerQuery<Data>(
  path: ResourcePath,
  appInstanceId: string,
  queryClient: QueryClient,
): Promise<void> {
  await queryClient.prefetchQuery<Data>(queryKeyAndFn("GET", path, appInstanceId))
}

function queryKeyAndFn<Data>(method: Method, path: ResourcePath, appInstanceId: string) {
  return {
    queryKey: generateQueryKey(method, path, appInstanceId),
    queryFn: async () => {
      const { data } = await axiosServer<Data>(method, path, appInstanceId, {})
      return data
    },
  }
}
