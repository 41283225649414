import { Icon } from "models/hubrise/order/Type"

import DefaultType from "models/hubrise/order/types/DefaultType"

class JustEatType extends DefaultType {
  get name() {
    return this.order.channel
  }

  get icon() {
    return { type: "clientName", clientName: "Just Eat Flyt Bridge" } as Icon
  }
}

export default JustEatType
