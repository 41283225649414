import * as React from "react"

import { useConfig } from "contexts/ConfigContext"

const RETENTION_TIME = 10 * 60 * 1000 // 10 minutes

interface IconData {
  objectUrl: string
  lastFetched: number
}

interface ClientIconContextInterface {
  getIcon: (clientName: string) => Promise<string | null>
}

const ClientIconContext = React.createContext<ClientIconContextInterface & { initialised: boolean }>({
  ...({} as ClientIconContextInterface),
  initialised: false,
})

export const ClientIconContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
  const { appInstanceId } = useConfig()

  // Map: clientName -> IconData
  const [iconMap] = React.useState<Map<string, IconData>>(new Map())

  const [, forceUpdate] = React.useState({})

  const getIcon = React.useCallback(
    async (clientName: string) => {
      const now = Date.now()
      const iconData = iconMap.get(clientName)

      if (iconData && now - iconData.lastFetched < RETENTION_TIME) {
        return iconData.objectUrl
      }

      try {
        const response = await fetch(
          `/client_icon?name=${encodeURIComponent(clientName)}&app_instance_id=${appInstanceId}`,
        )
        if (!response.ok) throw new Error(`HTTP ${response.status}`)

        const blob = await response.blob()
        const objectUrl = URL.createObjectURL(blob)

        if (iconData) {
          URL.revokeObjectURL(iconData.objectUrl)
        }

        iconMap.set(clientName, { objectUrl, lastFetched: now })
        forceUpdate({}) // Trigger a re-render
        return objectUrl
      } catch (error) {
        console.error(`Error fetching icon for client "${clientName}":`, error)
        return null
      }
    },
    [appInstanceId, iconMap],
  )

  return <ClientIconContext.Provider value={{ getIcon, initialised: true }}>{children}</ClientIconContext.Provider>
}

export const useClientIcon = (): ClientIconContextInterface => {
  const context = React.useContext(ClientIconContext)
  if (!context.initialised) {
    throw new Error("useClientIcon must be used within a ClientIconContextProvider")
  }
  return context
}
