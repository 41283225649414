import { ButtonWithDropdown } from "@hubrise/react-components"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Section } from "components/InventoryList/types"
import useAppNavigation from "hooks/useAppNavigation"

import { MobileDropdown, MobileItem, StyledMobile } from "./Styles"

interface MobileNavigationProps {
  catalogId: string
  section: Section
}

const MobileNavigation = ({ catalogId, section }: MobileNavigationProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { inventoryPath } = useAppNavigation()

  const navigateAndClose = (newSection: Section, close: () => void) => {
    navigate(inventoryPath(catalogId, newSection))
    close()
  }

  return (
    <StyledMobile>
      <ButtonWithDropdown
        buttonContent={t(`inventory.navigation.${section}`)}
        variant="emptyOverGray"
        reducedHeight={true}
        renderDropdown={({ close }) => (
          <MobileDropdown>
            <MobileItem onClick={() => navigateAndClose("products", close)}>
              {t("inventory.navigation.products")}
            </MobileItem>
            <MobileItem onClick={() => navigateAndClose("options", close)}>
              {t("inventory.navigation.options")}
            </MobileItem>
          </MobileDropdown>
        )}
      />
    </StyledMobile>
  )
}

export default MobileNavigation
