import * as React from "react"

import useAppNavigation from "hooks/useAppNavigation"
import { Order } from "models/hubrise/Order"

import { iframeLoadedAction } from "./usePrintIframe"

type IFrameStatus = "not_loaded" | "loading" | "loaded"

interface usePrintReturn {
  printIframe: JSX.Element
  print: () => void
  iFrameStatus: IFrameStatus
}

const usePrint = (order: Order): usePrintReturn => {
  const { orderReceiptPath } = useAppNavigation()

  const [iFrameStatus, setIFrameStatus] = React.useState<IFrameStatus>("not_loaded")

  const openPrintWindow = React.useCallback(() => {
    const iframe = iFrameElement()
    iframe.focus()
    iframe.contentWindow!.print()
  }, [])

  React.useEffect(() => {
    if (iFrameStatus === "loaded") {
      openPrintWindow()
    }
  }, [iFrameStatus, openPrintWindow])

  const handleWindowMessage = (event: MessageEvent<{ action: string }>) => {
    if (event.data.action === iframeLoadedAction) setIFrameStatus("loaded")
  }

  React.useEffect(() => {
    window.addEventListener("message", handleWindowMessage)
    return () => window.removeEventListener("message", handleWindowMessage)
  }, [])

  const print = React.useCallback(() => {
    switch (iFrameStatus) {
      case "not_loaded":
        setIFrameStatus("loading")
        const iframe = iFrameElement()
        iframe.src = orderReceiptPath(order.id)
        break
      case "loading":
        break
      case "loaded":
        openPrintWindow()
        break
    }
  }, [iFrameStatus, openPrintWindow, order.id, orderReceiptPath])

  const printIframe = React.useMemo(() => {
    return <iframe id="print-frame" style={{ display: "none" }} title={"Your receipt"} />
  }, [])

  return { printIframe, print, iFrameStatus }
}

export default usePrint

const iFrameElement = () => document.getElementById("print-frame") as HTMLIFrameElement
