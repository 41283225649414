import { Money } from "@hubrise/react-components"

import { DealLine } from "./DealLine"
import { GroupedOptions, Option } from "./Option"

export class Item {
  private readonly _groupedOptions: GroupedOptions

  constructor(
    public productName: string,
    public skuName: string | null,
    public skuRef: string | null,
    public price: Money,
    public quantity: number,
    public subtotal: Money,
    public taxRate: number | null,
    public customerNotes: string | null,
    public pointsEarned: number | null,
    public pointsUsed: number | null,
    public options: Array<Option>,
    public deleted: boolean,
    public dealLine: DealLine | null,
  ) {
    this._groupedOptions = {}
    this.options.forEach((option: Option) => {
      if (this._groupedOptions[option.optionListName] === undefined) {
        this._groupedOptions[option.optionListName] = []
      }
      this._groupedOptions[option.optionListName].push(option)
    })
  }

  get groupedOptions(): GroupedOptions {
    return this._groupedOptions
  }

  get productFullName(): string {
    return [this.productName, this.skuName].filter(Boolean).join(" - ")
  }
}
