import { breakpoints, colors } from "@hubrise/react-components"
import styled from "styled-components"

export const Counter = styled.div<{ $tabName: "today" | "future" }>`
  background-color: ${({ $tabName }) => ($tabName === "today" ? colors.primary : colors.backgroundMedium)};
  color: ${colors.white};
  font-weight: 500;
  border-radius: 1rem;
  min-width: 3rem;
  text-align: center;
  line-height: 2;

  @media (min-width: ${breakpoints.tablet}) {
    line-height: 1.5;
  }
`
