import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import CyclicRenderer from "components/CyclicRenderer"
import useAppNavigation, { LocationState } from "hooks/useAppNavigation"
import useTabName from "hooks/useTabName"
import { Day } from "models/Day"
import { Order } from "models/hubrise/Order"

import HiddenOrderContent from "./HiddenOrderContent"
import OrderContent from "./OrderContent"
import { OrderCard, Container, Date, Header, List, Summary } from "./Styles"

interface OrderListProps {
  orders: Array<Order>
  day?: Day
  nbHiddenOrders?: number
}

const OrderList = ({ orders, day, nbHiddenOrders = 0 }: OrderListProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const tabName = useTabName()
  const { orderPath } = useAppNavigation()

  const openOrder = (order: Order) => {
    const path = orderPath(tabName, order.id)
    const state: LocationState = { redirectUrl: window.location.href }
    navigate(path, { state })
  }

  const summary = () => {
    const groupTotal = orders.map((order) => order.total).reduce((sum, money) => sum.plus(money))
    return t("order.total", { count: orders.length }) + " - " + groupTotal.toHumanString()
  }

  return (
    <Container>
      {day && (
        <Header>
          <Date>{day.toHumanString()}</Date>
          <Summary dangerouslySetInnerHTML={{ __html: summary() }} />
        </Header>
      )}
      <List>
        {orders.map((order, index) => (
          <OrderCard data-testid="order-card" key={index} onClick={() => openOrder(order)}>
            <CyclicRenderer>
              {() => <OrderContent order={order} colorScheme={tabName === "today" ? "normal" : "dimmed"} />}
            </CyclicRenderer>
          </OrderCard>
        ))}

        {nbHiddenOrders > 0 && (
          <OrderCard data-testid="order-card-hidden" onClick={() => navigate("/orders/history")}>
            <HiddenOrderContent nbHiddenOrders={nbHiddenOrders} />
          </OrderCard>
        )}
      </List>
    </Container>
  )
}

export default OrderList
