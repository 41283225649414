import { colors, fontSizes, mixin, sizes } from "@hubrise/react-components"
import styled, { css } from "styled-components"

import FancyCheckbox from "components/FancyCheckbox"
import { NoImage } from "styles/common"

import { checkboxPositionCss } from "../Styles"
import { ITEM_HEIGHT, SKU_HEIGHT } from "../heights"

export const StyledRow = styled.div<{ $isLastItem: boolean; $totalHeight: number }>`
  height: ${({ $totalHeight }) => $totalHeight - 2}px;

  display: grid;
  grid-template-columns: 3.5rem fit-content(100%);
  grid-template-rows: ${ITEM_HEIGHT}px auto;
  grid-template-areas: "checkbox thumbnail_and_title" ". sku_list";
  align-items: center;

  cursor: pointer;
  background-color: ${colors.backgroundWhite};

  ${({ $isLastItem }) =>
    $isLastItem &&
    css`
      border-bottom-right-radius: ${sizes.borderRadius};
      border-bottom-left-radius: ${sizes.borderRadius};
    `}
`

export const SelectCheckbox = styled(FancyCheckbox)`
  grid-area: checkbox;
  height: 100%;
  width: 100%;
  ${checkboxPositionCss};
`

export const ThumbnailAndTitle = styled.div`
  grid-area: thumbnail_and_title;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Thumbnail = styled.div`
  width: 70px; // Api::ImagesController renders a 70px thumbnail
  height: 90%;
  ${mixin.centerElement};
`

export const Image = styled.img`
  height: 100%;
  border-radius: ${sizes.borderRadius};
  object-fit: contain;
`

export const StyledNoImage = styled(NoImage)`
  width: 100%;
  height: 100%;
  border-radius: ${sizes.borderRadius};
`

export const Title = styled.div`
  display: flex;
  overflow: hidden;
  gap: 0.5rem;
  align-items: baseline;
`

export const Name = styled.h2`
  font-size: ${fontSizes._18};
  ${mixin.noWrapEllipsis};
`

export const SkuList = styled.div`
  grid-area: sku_list;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
`

export const SkuItem = styled.div`
  height: ${SKU_HEIGHT}px;
  max-width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
`

export const SkuName = styled.div`
  ${mixin.noWrapEllipsis};
`

export const Ref = styled.span`
  color: ${colors.textLight};
  font-size: ${fontSizes._12};
  max-width: 15rem;
  ${mixin.noWrapEllipsis};
`
