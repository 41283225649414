import { breakpoints, fontSizes } from "@hubrise/react-components"
import styled from "styled-components"

export const DefaultLayout = styled.div`
  margin: 0 auto;
  padding-top: 1.25rem;
  width: 92%;

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 2.25rem;
    width: calc(100% - 2 * 2.25rem);
  }
`

export const DefaultMobileTitle = styled.div`
  margin-bottom: 1.25rem;
  text-align: center;
  font-size: ${fontSizes._18};
  font-weight: 500;

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const DESKTOP_ASIDE_WIDTH = "25rem"

export const ToolbarLayout = styled.div`
  padding: 0.75rem 0.5rem 0;
  height: 100%;
  display: flex;
  gap: 0 2rem;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 1.5rem 1.5rem 0;
  }
`

export const ToolbarAsideDesktop = styled.aside`
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: block;
    flex: calc(${DESKTOP_ASIDE_WIDTH} - 3.75rem) 0 0;
  }
`

export const ToolbarMain = styled.main`
  flex: 1;
`
