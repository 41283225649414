import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { Day } from "models/Day"
import { Item } from "models/hubrise/order/Item"
import { Option } from "models/hubrise/order/Option"
import { toDateStringOl, toTimeStringOl } from "utils/dateHelpers"

const useTranslationFormatters = () => {
  const { t } = useTranslation()
  const { cutoff } = useConfig()

  const colon = t("general.colon")

  const formatDateTime = (dateTime: DateTime | null, refDateTime: DateTime | null) => {
    if (!dateTime) return t("popup.show_order.not_available")

    if (refDateTime !== null && Day.fromDateTime(dateTime, cutoff).equals(Day.fromDateTime(refDateTime, cutoff))) {
      return toTimeStringOl(dateTime)
    } else {
      return [toDateStringOl(dateTime), toTimeStringOl(dateTime)].join(" ")
    }
  }

  const formatItemPrice = (item: Item): string => {
    const loyaltyPoints = item.pointsUsed ?? 0
    return [
      (item.subtotal.cents !== 0 || loyaltyPoints === 0) && item.subtotal.toHumanString(),
      loyaltyPoints !== 0 && t("popup.show_order.loyalty_points", { count: loyaltyPoints }),
    ]
      .filter(Boolean)
      .join(" + ")
  }

  const formatOptionListName = (optionListName: string): string => {
    return t(`order.options.${optionListName as "Toppings"}`, { defaultValue: optionListName })
  }

  const formatGroupedOptions = (optionListName: string, options: Option[]): string => {
    const formattedOptions = options.map((option) => (option.removed ? `<strike>${option.name}</strike>` : option.name))
    return formatOptionListName(optionListName) + colon + " " + formattedOptions.join(", ")
  }

  return {
    colon,
    formatDateTime,
    formatItemPrice,
    formatOptionListName,
    formatGroupedOptions,
  }
}

export default useTranslationFormatters
