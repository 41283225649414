import * as React from "react"

import { Section } from "components/InventoryList/types"
import { useConfig } from "contexts/ConfigContext"
import { TabName } from "hooks/useTabName"

export interface LocationState {
  redirectUrl?: string
}

const useAppNavigation = () => {
  const { appInstanceId } = useConfig()

  const withAppInstanceId = React.useCallback(
    (to: string): string => {
      if (to.includes("app_instance_id=")) {
        throw `Path should not contain ?app_instance_id=. Received value: ${to}. Use Link instead.`
      }
      return to.includes("?") ? `${to}&app_instance_id=${appInstanceId}` : `${to}?app_instance_id=${appInstanceId}`
    },
    [appInstanceId],
  )

  const tabPath = (tabName: TabName): string =>
    withAppInstanceId(tabName === "today" ? "/orders" : "/orders/" + tabName)

  const orderPath = (tabName: TabName, orderId: string) =>
    withAppInstanceId((tabName === "today" ? "/orders" : "/orders/" + tabName) + "/" + orderId)

  const orderReceiptPath = (orderId: string) => withAppInstanceId("/orders/" + orderId + "/receipt")

  const inventoryLoaderPath = () => withAppInstanceId("/inventory")

  const inventoryPath = (catalogId: string, section: Section) =>
    withAppInstanceId(["/inventory", catalogId, section].join("/"))

  return {
    withAppInstanceId,
    tabPath,
    orderPath,
    orderReceiptPath,
    inventoryLoaderPath,
    inventoryPath,
  }
}

export default useAppNavigation
