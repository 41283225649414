import * as React from "react"

import { Discount as DiscountModel } from "models/hubrise/order/Discount"

import { TdName, TdPrice } from "./Styles"

const Discount = ({ discount }: { discount: DiscountModel }): JSX.Element => (
  <React.Fragment>
    <TdName>{discount.name}</TdName>
    <TdPrice dangerouslySetInnerHTML={{ __html: `-&nbsp;${discount.priceOff.toHumanString()}` }} />
  </React.Fragment>
)

export default Discount
