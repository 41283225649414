import * as React from "react"
import { useTranslation } from "react-i18next"

import { Order } from "models/hubrise/Order"

import Charge from "./Charge"
import Deal from "./Deal"
import Discount from "./Discount"
import Item from "./Item"
import { Container, ItemsSection, Title } from "./Styles"
import Total from "./Total"

const Content = ({ order, className }: { order: Order; className?: string }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <Title>{t("popup.show_order.products")}</Title>

      <ItemsSection>
        {order.groupedItemsByDeal.map(({ deal, items }, index) => (
          <Deal key={index} deal={deal} items={items} />
        ))}
        {order.itemsWithoutDeals.map((item, index) => (
          <Item key={index} item={item} />
        ))}
        {order.charges.map((charge, index) => (
          <Charge key={index} charge={charge} />
        ))}
        {order.discounts.map((discount, index) => (
          <Discount key={index} discount={discount} />
        ))}
        <Total order={order} />
      </ItemsSection>
    </Container>
  )
}

export default Content
