import * as React from "react"
import { useTranslation } from "react-i18next"

import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Order } from "models/hubrise/Order"
import { toDateStringOl, toTimeStringOl } from "utils/dateHelpers"

import { DetailsSection, Line } from "./Styles"

const Details = ({ order }: { order: Order }): JSX.Element => {
  const { t } = useTranslation()
  const { colon } = useTranslationFormatters()

  return (
    <DetailsSection>
      <Line>
        {t("popup.print_order.reference") + colon} {order.id}
      </Line>
      <Line>
        {t("popup.print_order.ordered_on") + " "}
        {toDateStringOl(order.createdAt)} - {toTimeStringOl(order.createdAt)}
      </Line>
    </DetailsSection>
  )
}

export default Details
