import { breakpoints, mixin } from "@hubrise/react-components"
import styled from "styled-components"

import FancyCheckbox from "components/FancyCheckbox"

export const StyledSelector = styled.div`
  margin: 2rem 0;
`

export const SkuList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem 1rem;

  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Sku = styled.div`
  padding: 0.5rem 0;
  ${mixin.noWrapEllipsis};
  ${mixin.clickable};
`

export const SelectAll = styled(Sku)`
  grid-column: 1 / -1;
  margin-bottom: 0.5rem;
`

export const StyledCheckbox = styled(FancyCheckbox)`
  display: inline-block;
  margin-right: 0.5rem;
`
