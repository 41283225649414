import * as React from "react"

import type { Group } from "components/InventoryList/types"

import { StyledRow, Name, SelectCheckbox } from "./Styles"

interface GroupRowProps<G extends Group> {
  group: G
  height: number
  isSelected: boolean
  onCheck: (group: G) => void
  onClick: (group: G) => void
}

const GroupRow = <G extends Group>({ group, height, isSelected, onCheck, onClick }: GroupRowProps<G>): JSX.Element => {
  return (
    <StyledRow onClick={() => onClick(group)} $height={height}>
      <SelectCheckbox checked={isSelected} onChange={() => onCheck(group)} />
      <Name>{group.name}</Name>
    </StyledRow>
  )
}

export default GroupRow
