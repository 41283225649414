import { Icon, iconSizes } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { useOrderContext } from "contexts/OrderContext"
import { Order } from "models/hubrise/Order"
import { Status } from "models/hubrise/types"

import EditStatus from "./EditStatus"
import { StatusButton, StyledStatusTransition } from "./Styles"

interface StatusTransitionProps {
  order: Order
  status: Status
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

const StatusTransition = ({ order, status, isEditing, setIsEditing }: StatusTransitionProps): JSX.Element => {
  const { t } = useTranslation()
  const { settings } = useConfig()
  const { status_with_editable_time, status_with_editable_comment } = settings
  const { updateOrder } = useOrderContext()

  const isEditable = status_with_editable_time.includes(status) || status_with_editable_comment.includes(status)

  const updateStatus = (): void => {
    void updateOrder({ orderId: order.id, status })
    setIsEditing(false)
  }

  return (
    <StyledStatusTransition>
      <StatusButton $status={status} onClick={updateStatus}>
        {t(`order.status.${status}`)}
      </StatusButton>

      {isEditable && (
        <StatusButton $status={status} $fixedWidth={true} $isEditing={isEditing} onClick={() => setIsEditing(true)}>
          <Icon code="edit" size={iconSizes._20} />
        </StatusButton>
      )}

      {isEditing && <EditStatus order={order} status={status} onClose={() => setIsEditing(false)} />}
    </StyledStatusTransition>
  )
}

export default StatusTransition
