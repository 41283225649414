import { locales } from "@hubrise/react-components"
import i18n, { use } from "i18next"
import { initReactI18next } from "react-i18next"

import { Locale } from "models/hubrise/User"

import translationEn from "./locales/en.json"
import translationFr from "./locales/fr.json"

export const resources = {
  en: { translation: { ...locales.en, ...translationEn } },
  fr: { translation: { ...locales.fr, ...translationFr } },
} as const

export const languageDropdown: Array<{ label: string; value: Locale }> = [
  { label: "English", value: "en-GB" },
  { label: "Français", value: "fr-FR" },
]

void use(initReactI18next).init({
  resources,
  lng: "en-GB",
  fallbackLng: "en-GB",
  returnNull: false,
  interpolation: {
    escapeValue: false, // Not needed for React as it escapes by default
  },
})

export default i18n
