import * as React from "react"

import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Item } from "models/hubrise/order/Item"

import { OptionList, TdOptionLists } from "../Styles"

const OptionLists = ({ item }: { item: Item }): JSX.Element => {
  const { formatGroupedOptions } = useTranslationFormatters()

  return (
    <React.Fragment>
      {Object.keys(item.groupedOptions).length > 0 && (
        <TdOptionLists>
          {Object.entries(item.groupedOptions).map(([optionListName, options], index) => (
            <OptionList
              key={index}
              dangerouslySetInnerHTML={{ __html: formatGroupedOptions(optionListName, options) }}
            />
          ))}
        </TdOptionLists>
      )}
    </React.Fragment>
  )
}

export default OptionLists
