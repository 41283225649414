import { DateTime } from "luxon"

export const toDateStringOl = (date: DateTime): string => date.toFormat("dd/MM/yyyy")

export const toTimeStringOl = (date: DateTime): string => date.toFormat("HH:mm")

/**
 * Convert to seconds.
 * @param cutoffTime Cutoff time in "HH:mm" format.
 */
export const cutoffInSeconds = (cutoffTime: string): number => {
  const [hours, minutes] = cutoffTime.split(":").map(Number)
  return hours * 3600 + minutes * 60
}
