import * as React from "react"

import { BaseStyles } from "styles/BaseStyles"
import { ExtraNormalizeStyles } from "styles/ExtraNormalizeStyles"
import { NormalizeStyles } from "styles/NormalizeStyles"

const withStyles = <Props,>(Component: React.FunctionComponent<Props>) => {
  const ComponentWithStyles = (props: Props): JSX.Element => {
    return (
      <React.Fragment>
        <NormalizeStyles />
        <ExtraNormalizeStyles />
        <BaseStyles />

        <Component {...props} />
      </React.Fragment>
    )
  }
  ComponentWithStyles.displayName = `${Component.displayName}WithStyles`
  return ComponentWithStyles
}

export default withStyles
