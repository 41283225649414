import * as React from "react"

import Loading from "components/Loading"
import { useLocation, useSettings, useUser } from "hooks/queries"
import useWebsocket from "hooks/useWebsocket"
import { Config, ServerConfig } from "models/Config"
import { Subscription } from "models/Subscription"
import Websocket from "models/Websocket"
import { cutoffInSeconds } from "utils/dateHelpers"

interface ConfigContextInterface {
  config: Config
}

const ConfigContext = React.createContext<ConfigContextInterface & { initialised: boolean }>({
  ...({} as ConfigContextInterface),
  initialised: false,
})

export const ConfigContextProvider = ({
  serverConfig,
  appInstanceId,
  children,
}: {
  serverConfig: ServerConfig
  appInstanceId: string
  children: React.ReactNode
}): JSX.Element | null => {
  const { data: location, refetch: refetchLocation } = useLocation(appInstanceId)
  const { data: user } = useUser(appInstanceId)
  const { data: settings } = useSettings(appInstanceId)

  const pusherWebsocket = React.useMemo(() => new Websocket(serverConfig.websocket), [serverConfig.websocket])

  useWebsocket(
    () => Subscription.createForLocation(pusherWebsocket, appInstanceId),
    () => refetchLocation(),
  )

  const config = React.useMemo<Config>(() => {
    return {
      ...serverConfig,
      appInstanceId,
      // This object is thrown away if location or user are undefined, so we don't need to worry about nullability.
      location: location!,
      user: user!,
      settings: settings!,
      timezone: location ? location.timezone.name : "UTC",
      cutoff: location ? cutoffInSeconds(location.cutoff_time) : 0,
      pusherWebsocket,
    }
  }, [serverConfig, appInstanceId, location, user, settings, pusherWebsocket])

  if (!location || !user || !settings) return <Loading />

  return <ConfigContext.Provider value={{ config, initialised: true }}>{children}</ConfigContext.Provider>
}

export const useConfig = (): Config => {
  const context = React.useContext(ConfigContext)
  if (!context.initialised) {
    throw new Error("useConfig must be used within an ConfigContextProvider")
  }
  return context.config
}
