import { Money } from "@hubrise/react-components"

export class Charge {
  constructor(
    public name: string | null,
    public ref: string | null,
    public price: Money,
    public taxRate: number | null,
    public deleted: boolean,
  ) {}
}
