import { DateTime } from "luxon"
import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import { Day } from "models/Day"

const useConfirmedTime = (expectedTime: DateTime | null) => {
  const { cutoff, timezone } = useConfig()

  const [wasUpdated, setWasUpdated] = React.useState(false)

  const datetime = (expectedTime || DateTime.now()).setZone(timezone)
  const [day, setDay] = React.useState<Day>(Day.fromDateTime(datetime, cutoff, timezone))
  const [time, setTime] = React.useState<{ hour: number; min: number }>({
    hour: datetime.hour,
    min: Math.ceil(datetime.minute / 5) * 5,
  })

  const setFromDate = React.useCallback((date: Date) => {
    setDay(Day.fromDate(date))
    setTime({ hour: date.getHours(), min: date.getMinutes() })
    setWasUpdated(true)
  }, [])

  const dayWithCutoff = (time.hour * 60 + time.min) * 60 >= cutoff ? day.day : day.day + 1
  const confirmedTime = DateTime.local(day.year, day.month, dayWithCutoff, time.hour, time.min, { zone: timezone })

  return { day, time, setFromDate, wasUpdated, confirmedTime }
}

export default useConfirmedTime
