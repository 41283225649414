import * as React from "react"
import { useTranslation } from "react-i18next"

import { Card, Header, Body } from "./Styles"

const HiddenOrderContent = ({ nbHiddenOrders }: { nbHiddenOrders: number }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Card>
      <Header>{t("order.hidden.title", { count: nbHiddenOrders })}</Header>
      <Body>{t("order.hidden.show")}</Body>
    </Card>
  )
}

export default HiddenOrderContent
