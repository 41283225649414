import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Order } from "models/hubrise/Order"

import { Address, CustomerSection, Line } from "./Styles"

const Customer = ({ order }: { order: Order }): JSX.Element => {
  const { t } = useTranslation()
  const { location } = useConfig()
  const { colon } = useTranslationFormatters()

  const { customer, serviceType } = order

  const phoneHuman = customer.phoneHuman(location.country)
  const spaceSeparator = " "

  const addressLines = [customer.address1And2, customer.postalCodeAndCity, customer.deliveryNotes]
    .filter(Boolean)
    .map((value, index) => <Line key={index} dangerouslySetInnerHTML={{ __html: value! }} />)
  if (customer.coordinates) {
    addressLines.push(
      <Line key="coordinates">
        {t("popup.print_order.coordinates") + colon}
        {spaceSeparator}
        {customer.coordinates.lat} ; {customer.coordinates.lng}
      </Line>,
    )
  }

  return (
    <CustomerSection>
      <Line highlight={true}>{customer.fullName}</Line>

      {phoneHuman !== null && <Line highlight={true}>{phoneHuman}</Line>}

      {customer.phoneAccessCode !== null && (
        <Line>
          {t("popup.print_order.phone_access_code") + colon}
          {spaceSeparator}
          {customer.phoneAccessCode}
        </Line>
      )}

      {serviceType === "delivery" && addressLines.length > 0 && <Address>{addressLines}</Address>}
    </CustomerSection>
  )
}

export default Customer
