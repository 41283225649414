import { colors, fontSizes } from "@hubrise/react-components"
import { createGlobalStyle } from "styled-components"

export const BaseStyles = createGlobalStyle`
  body {
    font-family: Ubuntu, Arial, Helvetica, sans-serif;
    font-size: ${fontSizes._14};
    font-weight: 400;
    line-height: 1.15;
    color: ${colors.textDark};
    background-color: ${colors.backgroundLightest};
  }
`
