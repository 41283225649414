import { ModalStyle as Modal } from "@hubrise/react-components"
import * as React from "react"

import { useOrderContext } from "contexts/OrderContext"
import { Order } from "models/hubrise/Order"

import Header from "./Header"
import { Grid, PositionedActions, PositionedItems, PositionedInfo } from "./Styles"

interface OrderModalContentProps {
  order: Order
  close: () => void
}

const OrderModalContent = ({ order, close }: OrderModalContentProps): JSX.Element => {
  const { orderNotificationStore } = useOrderContext()

  React.useEffect(() => {
    orderNotificationStore.removeOrder(order.id)
  }, [order.id, orderNotificationStore])

  return (
    <Modal.Content>
      <Header order={order} close={close} />

      <Grid>
        <PositionedActions order={order} />
        <PositionedInfo order={order} />
        <PositionedItems order={order} />
      </Grid>
    </Modal.Content>
  )
}

export default OrderModalContent
