import _ from "lodash"
import * as React from "react"

import { isProductEditable } from "components/InventoryList/shared"
import { GroupOrItemWithType } from "components/InventoryList/types"
import { Category, Product } from "models/hubrise/Catalog"

interface CategoryHooks {
  categoryDescendants: (category: Category) => Array<Product>
  isCategorySelected: (category: Category) => boolean
  isLastOfCategory: (index: number) => boolean
}

const useCategoryHooks = (items: Array<GroupOrItemWithType>, selectProductIds: Array<string>): CategoryHooks => {
  const productsByCategoryId = React.useMemo(() => {
    const products = items
      .filter((item) => item.type === "product" && isProductEditable(item.data))
      .map((item) => item.data as Product)
    return _.groupBy(products, "category_id")
  }, [items])

  const categoriesByParentId = React.useMemo(() => {
    const categories = items.filter((item) => item.type === "category").map((item) => item.data as Category)
    return _.groupBy(categories, "parent_id")
  }, [items])

  const categoryDescendants = React.useCallback(
    (category: Category): Array<Product> => {
      const children = categoriesByParentId[category.id] ?? []
      const products = productsByCategoryId[category.id] ?? []
      return children.flatMap((category) => categoryDescendants(category)).concat(products)
    },
    [categoriesByParentId, productsByCategoryId],
  )

  const isCategorySelected = React.useCallback(
    (category: Category) => categoryDescendants(category).every((product) => selectProductIds.includes(product.id)),
    [categoryDescendants, selectProductIds],
  )

  const isLastOfCategory = React.useCallback(
    (index: number) => index === items.length - 1 || items[index + 1]?.type === "category",
    [items],
  )

  return { categoryDescendants, isCategorySelected, isLastOfCategory }
}

export default useCategoryHooks
