import { zIndexValues } from "@hubrise/react-components"
import styled from "styled-components"

import { Status } from "models/hubrise/types"
import { orderColors } from "styles/common"

import { Button } from "../Styles"

export const StyledStatusTransition = styled.div`
  display: flex;
  gap: 0 0.5rem;
  position: relative;
`

export const StatusButton = styled(Button)<{ $status: Status; $fixedWidth?: boolean; $isEditing?: boolean }>`
  flex: ${({ $fixedWidth }) => ($fixedWidth === true ? "0 0 5rem" : "1 0 auto")};

  background-color: ${({ $status }) => orderColors.normal.statusBackground[$status]};
  ${({ $isEditing }) => $isEditing === true && `z-index: ${zIndexValues.dropdownInModal};`}
`
