import * as React from "react"
import { useTranslation } from "react-i18next"

import TextWithImage from "components/TextWithImage"

import { StyledSpinner } from "./Styles"

const Loading = (): JSX.Element => {
  const { t } = useTranslation()

  // Avoid flickering when loading is fast
  const [showSpinner, setShowSpinner] = React.useState(false)

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setShowSpinner(true), 500)
    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <TextWithImage type="children" text={t("component.loading.text")}>
      {showSpinner && <StyledSpinner />}
    </TextWithImage>
  )
}

export default Loading
