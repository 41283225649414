import { colors, Button, fontSizes, mixin } from "@hubrise/react-components"
import styled from "styled-components"

const breakpoint = "768px"

export const Page = styled.div`
  max-width: 55rem;
  margin: 0 auto;
`

export const Header = styled.p`
  margin: 1rem 0;
  font-size: ${fontSizes._14};
  line-height: 1.5;
`

export const SetOpeningHoursLink = styled.a`
  color: ${colors.primary};
`

export const Table = styled.table`
  width: 100%;
  font-size: ${fontSizes._14};
  border-spacing: 0 0.5rem;
`

export const Row = styled.tr`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.75rem;
    border-bottom: 1px solid ${colors.backgroundLight};
  }

  @media (min-width: ${breakpoint}) {
    display: table-row;

    &:not(:last-child) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`

export const Cell = styled.td`
  @media (min-width: ${breakpoint}) {
    padding: 0.5rem 0.625rem;
  }
`

export const DayCell = styled(Cell)`
  padding-left: 0;
  color: ${colors.textDark};
  order: 0;
  text-transform: capitalize;
  white-space: nowrap;

  @media (min-width: ${breakpoint}) {
    order: 0;
    vertical-align: baseline;
  }
`

export const OpeningHoursCell = styled(Cell)`
  position: relative;
  order: 3;
  flex-basis: 100%;

  @media (min-width: ${breakpoint}) {
    order: 1;
    flex-basis: unset;
    width: 100%;
    vertical-align: baseline;
  }
`

export const TimeIntervals = styled.div`
  margin: 1.75rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1.25rem;

  @media (min-width: ${breakpoint}) {
    margin: 0;
    gap: 1.25rem 2.5rem;
  }
`

export const AddHoursCell = styled(Cell)`
  order: 1;
  margin-left: auto;
  margin-right: 0.625rem;

  @media (min-width: ${breakpoint}) {
    margin-left: unset;
    order: 2;
    text-align: right;
    vertical-align: baseline;
  }
`

export const CopyToBottomCell = styled(Cell)`
  order: 2;
  padding-right: 0;

  @media (min-width: ${breakpoint}) {
    order: 3;
    vertical-align: baseline;
  }
`

export const BottomBar = styled.div<{ isVisible: boolean }>`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 2rem;
  padding: 2rem 0;
  z-index: 1;
  border-top: 1px solid ${colors.borderLight};
  background-color: ${colors.backgroundLightest};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`

export const CancelButton = styled(Button)``

export const SaveButton = styled(Button)`
  min-width: 7.5rem;
`

export const GeneralError = styled.div`
  ${mixin.centerElement};
  color: ${colors.danger};
  font-size: ${fontSizes._14};
  line-height: 1.5;
`
