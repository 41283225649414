import { Icon, useKeyboard, UseKeyboardLevel, useOnOutsideClick, useOnWindowResize } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { Status, Statuses } from "models/hubrise/types"

import { Clear, ClickableOverlay, StatusButton, StyledIcon, StyledPopup, Subtitle, Title } from "./Styles"

interface PopupProps {
  element: HTMLElement
  title: string
  subtitle?: string
  statuses: Array<Status>
  onChange: (statuses: Array<Status>) => void
  onClose: () => void
}

const Popup = ({ element, title, subtitle, statuses, onChange, onClose }: PopupProps): JSX.Element => {
  const { t } = useTranslation()

  const $popupRef = React.useRef<HTMLDivElement>(null)
  const $clickableOverlayRef = React.useRef<HTMLDivElement>(null)

  useOnOutsideClick([$popupRef], true, onClose, $clickableOverlayRef)
  useKeyboard(UseKeyboardLevel.ModalInModal, { code: "Escape" }, onClose, { description: "closePopupInStatusSelect" })

  const setPosition = React.useCallback(() => {
    const { top, left, height } = element.getBoundingClientRect()
    const popup = $popupRef.current!.getBoundingClientRect()

    const topOffset = Math.min(top + height + 3, window.innerHeight - popup.height)
    const leftOffset = Math.min(left, window.innerWidth - popup.width)

    $popupRef.current!.style.top = `${topOffset}px`
    $popupRef.current!.style.left = `${leftOffset}px`
    $popupRef.current!.style.visibility = "visible"
  }, [element])

  React.useLayoutEffect(setPosition, [setPosition])
  useOnWindowResize(setPosition)

  const switchStatus = (status: Status): void => {
    const newStatuses = Statuses.filter((s) => (s === status ? !statuses.includes(s) : statuses.includes(s)))
    onChange(newStatuses)
  }

  return (
    <React.Fragment>
      <ClickableOverlay ref={$clickableOverlayRef} />

      <StyledPopup ref={$popupRef}>
        <Title>
          {title}
          {subtitle !== undefined && <Subtitle>{subtitle}</Subtitle>}
        </Title>

        {Statuses.map((status: Status) => (
          <StatusButton key={status} $status={status} onClick={() => switchStatus(status)}>
            {t(`order.status.${status}`)}
            <StyledIcon>{statuses.includes(status) ? <Icon code={"check"} /> : <Icon code={"add"} />}</StyledIcon>
          </StatusButton>
        ))}

        <Clear onClick={() => onChange([])}>{t("popup.statuses_list.clear_all")}</Clear>
      </StyledPopup>
    </React.Fragment>
  )
}

export default Popup
