import { colors, ModalStyle as Modal } from "@hubrise/react-components"
import styled from "styled-components"

export const Title = styled(Modal.Title)`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const Address = styled.div`
  font-weight: normal;
  color: ${colors.textMedium};
`
