import * as React from "react"

import { Item } from "models/hubrise/order/Item"

import { TdCustomerNotes } from "../Styles"

const CustomerNotes = ({ item, isDeal }: { item: Item; isDeal: boolean }): JSX.Element | null => {
  const { customerNotes } = item

  if (customerNotes === null) return null

  return <TdCustomerNotes $isDeal={isDeal}>{customerNotes}</TdCustomerNotes>
}

export default CustomerNotes
