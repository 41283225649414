import { useMatch } from "react-router-dom"

export type TabName = "today" | "future" | "history" | "opening-hours" | "inventory"

const useTabName = (): TabName => {
  const futurePathMatch = useMatch("/orders/future/*")
  const historyPathMatch = useMatch("/orders/history/*")
  const todayPathMatch = useMatch("/orders/*")
  const openingHoursPathMatch = useMatch("/opening-hours/*")
  const inventoryPathMatch = useMatch("/inventory/*")

  const tabName: TabName | null = futurePathMatch
    ? "future"
    : historyPathMatch
    ? "history"
    : todayPathMatch
    ? "today"
    : openingHoursPathMatch
    ? "opening-hours"
    : inventoryPathMatch
    ? "inventory"
    : null

  if (tabName) {
    return tabName
  } else {
    throw new Error("Invalid tab name")
  }
}

export default useTabName
