import type { AxiosResponse } from "axios"
import { DateTime } from "luxon"
import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import { Day } from "models/Day"
import { ImportedOrder } from "models/ImportedOrder"
import { Order } from "models/hubrise/Order"
import { Status } from "models/hubrise/types"
import importOrder from "services/importOrder"
import { axiosServer } from "utils/server"

const useServer = () => {
  const { appInstanceId } = useConfig()

  return React.useMemo(() => {
    type AxiosServerArgs = Parameters<typeof axiosServer>

    const myAxiosServer = <Data>(
      method: AxiosServerArgs[0],
      url: AxiosServerArgs[1],
      axiosConfig: AxiosServerArgs[3] = {},
    ): Promise<AxiosResponse<Data>> => axiosServer<Data>(method, url, appInstanceId, axiosConfig)

    /**
     * Retrieve the orders due today or in the future from the server.
     */
    const getTodayAndFutureOrders = async (): Promise<Array<Order>> => {
      const response = await myAxiosServer<Array<ImportedOrder>>("GET", "/orders")
      return response.data.map(importOrder)
    }

    /**
     * Retrieve the orders matching the time range and search filter.
     * @param from
     * @param to
     * @param search Filter the orders by customer name or order id.
     */
    const getHistoryOrders = async (from: Day, to: Day, search: string): Promise<Array<Order>> => {
      const response = await myAxiosServer<Array<ImportedOrder>>("GET", "/orders/history", {
        params: { from: from.toString(), to: to.toString(), search },
      })
      return response.data.map(importOrder)
    }

    /**
     * Retrieve a single order from the server.
     * @param orderId
     */
    const getOrder = async (orderId: string): Promise<Order> => {
      const response = await myAxiosServer<ImportedOrder>("GET", `/orders/${orderId}`)
      return importOrder(response.data)
    }

    const updateOrder = async ({
      orderId,
      status,
      seller_notes,
      confirmed_time,
    }: UpdateOrderParams): Promise<Order> => {
      const response = await myAxiosServer<ImportedOrder>("PUT", `/orders/${orderId}`, {
        data: { status, seller_notes, confirmed_time },
      })
      return importOrder(response.data)
    }

    return {
      getTodayAndFutureOrders,
      getHistoryOrders,
      getOrder,
      updateOrder,
    }
  }, [appInstanceId])
}

export default useServer

export interface UpdateOrderParams {
  orderId: string
  status?: Status
  seller_notes?: string
  confirmed_time?: DateTime
}
