import { Product } from "models/hubrise/Catalog"

const GROUP_HEIGHT = 52
export const ITEM_HEIGHT = 52

const SKU_TOTAL_PADDING = 12
export const SKU_HEIGHT = 24

const SMALL_SEPARATION = 1 // Separation between products
const LARGE_SEPARATION = 24 // Separation between last product and next category

export const categoryHeight = (separation: "small" | null = null) =>
  GROUP_HEIGHT + (separation === "small" ? SMALL_SEPARATION : 0)

export const productHeight = (product: Product, separation: "small" | "large" | null = null) =>
  ITEM_HEIGHT +
  (product.skus.length > 1 ? product.skus.length * SKU_HEIGHT + SKU_TOTAL_PADDING : 0) +
  (separation === "small" ? SMALL_SEPARATION : separation === "large" ? LARGE_SEPARATION : 0)

export const optionListHeight = (separation: "small" | null = null) => categoryHeight(separation)

export const optionHeight = (separation: "small" | "large" | null = null) =>
  ITEM_HEIGHT + (separation === "small" ? SMALL_SEPARATION : separation === "large" ? LARGE_SEPARATION : 0)
