import { colors, fontSizes } from "@hubrise/react-components"
import styled from "styled-components"

export const RightHandSide = styled.div<{ $isVisible: boolean }>`
  align-self: center;
  min-width: 2.25rem;
  padding: 0.25rem 0.35rem 0.25rem 1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  font-size: ${fontSizes._18};
  font-weight: 500;
  color: ${colors.white};
  background-color: ${colors.primary};
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
`
