import * as React from "react"

import { Subscription } from "models/Subscription"
import { Callback } from "models/Websocket"

const useWebsocket = <Data>(
  subscriptionOrFn: Subscription<Data> | (() => Subscription<Data>),
  callback: Callback<Data>,
) => {
  React.useEffect(() => {
    const subscription = typeof subscriptionOrFn === "function" ? subscriptionOrFn() : subscriptionOrFn
    subscription.connect(callback)
    return () => subscription.disconnect(callback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useWebsocket
