import { useKeyboard, UseKeyboardLevel, useOnOutsideClick } from "@hubrise/react-components"
import { DateTime } from "luxon"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { useOrderContext } from "contexts/OrderContext"
import { UpdateOrderParams } from "contexts/OrderContext/useServer"
import { Day } from "models/Day"
import { Order } from "models/hubrise/Order"
import { Status } from "models/hubrise/types"

import { StatusButton } from "../Styles"

import {
  ClickableOverlay,
  ConfirmedTime,
  ConfirmedTimeLabel,
  SellerNotes,
  StyledDatePicker,
  StyledEditStatus,
} from "./Styles"
import useConfirmedTime from "./useConfirmedTime"

interface EditStatusProps {
  order: Order
  status: Status
  onClose: () => void
}

const EditStatus = ({ order, status, onClose }: EditStatusProps) => {
  const { t, i18n } = useTranslation()
  const { settings, timezone } = useConfig()
  const { status_with_editable_time, status_with_editable_comment } = settings
  const { updateOrder } = useOrderContext()

  const { day, time, setFromDate, wasUpdated, confirmedTime } = useConfirmedTime(order.expectedTime)
  const [sellerNotes, setSellerNotes] = React.useState<string>("")

  const $modalRef = React.useRef<HTMLDivElement>(null)
  const $clickableOverlayRef = React.useRef<HTMLDivElement>(null)

  useOnOutsideClick([$modalRef], true, onClose, $clickableOverlayRef)
  useKeyboard(UseKeyboardLevel.ModalInModal, { code: "Escape" }, onClose, { description: "closeDropdownInModal" })

  const isTimeEditable = status_with_editable_time.includes(status)
  const isCommentEditable = status_with_editable_comment.includes(status)
  const yesterday = Day.fromDateTime(DateTime.now().minus({ days: 1 }), 0, timezone)

  const updateStatus = (): void => {
    const updateParams: UpdateOrderParams = { orderId: order.id, status }
    if (isTimeEditable && wasUpdated) {
      updateParams.confirmed_time = confirmedTime
    }
    if (isCommentEditable && sellerNotes !== "") {
      updateParams.seller_notes = sellerNotes
    }
    void updateOrder(updateParams)
    onClose()
  }

  return (
    <React.Fragment>
      <ClickableOverlay ref={$clickableOverlayRef} />

      <StyledEditStatus ref={$modalRef}>
        {isTimeEditable && (
          <ConfirmedTime>
            <ConfirmedTimeLabel>{t("popup.edit_order.delivery_date")}:</ConfirmedTimeLabel>
            <StyledDatePicker
              selected={new Date(day.year, day.month - 1, day.day, time.hour, time.min)}
              onChange={(date: Date) => setFromDate(date)}
              excludeDateIntervals={[{ start: new Date(2000, 1, 1), end: yesterday.toDate() }]}
              locale={i18n.language}
              dateFormat={t("search_bar.datetime_format")}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
            />
          </ConfirmedTime>
        )}

        {isCommentEditable && (
          <SellerNotes
            value={sellerNotes}
            onChange={(event) => setSellerNotes(event.target.value)}
            placeholder={t("popup.edit_order.message_placeholder")}
          />
        )}

        <StatusButton $status={status} onClick={updateStatus}>
          {t(`order.status.${status}`)}
        </StatusButton>
      </StyledEditStatus>
    </React.Fragment>
  )
}

export default EditStatus
