import * as React from "react"

import Websocket from "models/Websocket"

const useConnectionLost = (websocket: Websocket): boolean => {
  const [isConnectionLost, setIsConnectionLost] = React.useState(false)

  React.useEffect(() => {
    websocket.registerStateChangeCallback((state) => setIsConnectionLost(state !== "connected"))
  }, [websocket, setIsConnectionLost])

  return isConnectionLost
}

export default useConnectionLost
