export type OpeningHours = Record<DayOfTheWeek, TimeInterval[]> //

export interface TimeInterval {
  from: string
  to: string
}

export const DaysOfTheWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const

export type DayOfTheWeek = typeof DaysOfTheWeek[number]

export const emptyOpeningHours = (): OpeningHours => {
  return {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  }
}

function compareTimes(time1: string, time2: string, cutoffTime: string): number {
  const isTime1BeforeCutoff = time1 < cutoffTime
  const isTime2BeforeCutoff = time2 < cutoffTime
  if (isTime1BeforeCutoff !== isTime2BeforeCutoff) {
    return time2.localeCompare(time1)
  }
  return time1.localeCompare(time2)
}

export type HourIntervalErrorType = "overlapping" | "end_before_start"
export type GeneralErrorType = "one_slot_required"

export interface IntervalError {
  intervalIndex: number
  errorType: HourIntervalErrorType
}

export type OpeningHoursErrors = {
  days: Record<DayOfTheWeek, IntervalError[]>
  general: GeneralErrorType | null
}

export const emptyOpeningHoursErrors = (): OpeningHoursErrors => {
  return {
    days: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    general: null,
  }
}

/**
 * @param openingHours
 * @param cutoffTime - time after which the next day starts, e.g. "06:00"
 */
export const validateOpeningHours = (openingHours: OpeningHours, cutoffTime: string): OpeningHoursErrors => {
  const errors: OpeningHoursErrors = { ...emptyOpeningHoursErrors() }

  const isOverlapping = ({ from: from1, to: to1 }: TimeInterval, timeIntervals: Array<TimeInterval>): boolean =>
    timeIntervals.filter(({ from: from2, to: to2 }) => {
      return (
        (compareTimes(from1, from2, cutoffTime) >= 0 && compareTimes(from1, to2, cutoffTime) < 0) ||
        (compareTimes(to1, from2, cutoffTime) > 0 && compareTimes(to1, to2, cutoffTime) <= 0)
      )
    }).length >= 2

  DaysOfTheWeek.forEach((day) => {
    const timeIntervals = openingHours[day]

    for (let i = 0; i < timeIntervals.length; i++) {
      const interval = timeIntervals[i]
      if (compareTimes(interval.from, interval.to, cutoffTime) >= 0) {
        errors.days[day].push({
          intervalIndex: i,
          errorType: "end_before_start",
        })
      }
      if (isOverlapping(interval, timeIntervals)) {
        errors.days[day].push({
          intervalIndex: i,
          errorType: "overlapping",
        })
      }
    }
  })

  const hasAtLeastOneSlot = DaysOfTheWeek.some((day) => openingHours[day].length > 0)
  if (!hasAtLeastOneSlot) {
    errors.general = "one_slot_required"
  }

  return errors
}

export const identicalOpeningHours = (openingHours1: OpeningHours, openingHours2: OpeningHours): boolean => {
  return DaysOfTheWeek.every((day) => {
    const intervals1 = openingHours1[day]
    const intervals2 = openingHours2[day]
    return (
      intervals1.length === intervals2.length &&
      intervals1.every((interval1, index) => {
        const interval2 = intervals2[index]
        return interval1.from === interval2.from && interval1.to === interval2.to
      })
    )
  })
}
