import { Status, Wrapper } from "@googlemaps/react-wrapper"
import { ModalStyle as Modal } from "@hubrise/react-components"
import * as React from "react"

import Loading from "components/Loading"
import { useConfig } from "contexts/ConfigContext"
import { Order } from "models/hubrise/Order"

import Map from "./Map"
import Marker from "./Marker"
import { Address, Body, Header } from "./Styles"

import LatLngLiteral = google.maps.LatLngLiteral

const MapModalContent = ({ center, order, close }: { center: LatLngLiteral; order: Order; close: () => void }) => {
  const { gapiMapToken } = useConfig()

  return (
    <Wrapper
      apiKey={gapiMapToken}
      render={(status) => {
        switch (status) {
          case Status.LOADING:
            return <Loading />
          case Status.FAILURE:
            return (
              <div>
                Could not load Google Maps. <a onClick={close}>Close</a>
              </div>
            )
          case Status.SUCCESS:
            return (
              <Modal.Content>
                <Header>
                  <Modal.Title>
                    {order.customer.fullName} / {order.id}
                    <Address>{order.customer.fullAddress}</Address>
                  </Modal.Title>
                  <Modal.Close onClick={close} />
                </Header>

                <Body>
                  <Map center={center}>
                    <Marker position={center} />
                  </Map>
                </Body>
              </Modal.Content>
            )
        }
      }}
    />
  )
}

export default MapModalContent
