import * as React from "react"

import { Discount as DiscountModel } from "models/hubrise/order/Discount"

import { ItemName, Price, Spacer } from "./Styles"

const Discount = ({ discount }: { discount: DiscountModel }): JSX.Element => {
  const common = { $isDeleted: discount.deleted }
  return (
    <React.Fragment>
      <ItemName {...common}>{discount.name}</ItemName>
      <Price {...common} dangerouslySetInnerHTML={{ __html: `-&nbsp;${discount.priceOff.toHumanString()}` }} />
      <Spacer />
    </React.Fragment>
  )
}

export default Discount
