import { breakpoints, colors, zIndexValues } from "@hubrise/react-components"
import styled from "styled-components"

import DatePicker from "components/DatePicker"

export const ClickableOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexValues.dropdownInModal};
  background: rgba(100, 100, 100, 0.5);
`

export const StyledEditStatus = styled.div`
  position: fixed;
  left: 2px;
  right: 2px;
  top: 25%;
  z-index: ${zIndexValues.dropdownInModal};
  background-color: ${colors.white};

  display: flex;
  flex-direction: column;
  
  @media (min-width: ${breakpoints.mobile}) {
    position: absolute;
    left: initial;
    right: 0;
    top: 85%;
    width: 30rem;
  })
`

export const ConfirmedTime = styled.div`
  border-bottom: 1px solid ${colors.borderLightest};
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0 1rem;
`

export const ConfirmedTimeLabel = styled.div`
  white-space: nowrap;
`

export const StyledDatePicker = styled(DatePicker)`
  flex-grow: 1;
`

export const SellerNotes = styled.textarea`
  border: none;
  padding: 1rem;
  height: 7rem;
  min-width: 20rem;
  resize: none;
  width: 100%;
`
