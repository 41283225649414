import * as React from "react"
import { useTranslation } from "react-i18next"

import { Order } from "models/hubrise/Order"

import { Line, LoyaltySection } from "./Styles"

const Loyalty = ({ order }: { order: Order }): JSX.Element | null => {
  const { t } = useTranslation()

  return order.pointsDelta !== 0 ? (
    <LoyaltySection>
      <Line>
        {t("popup.show_order.points_earned")}:{t("popup.show_order.loyalty_points", { count: order.pointsDelta })}
      </Line>
      <Line>
        {t("popup.show_order.loyalty_new_balance")}:
        {t("popup.show_order.loyalty_points", { count: order.pointsBalance })}
      </Line>
    </LoyaltySection>
  ) : null
}

export default Loyalty
