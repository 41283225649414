import { Icon, iconSizes } from "@hubrise/react-components"
import React from "react"

import MobileNavigation from "components/InventoryList/Navigation/MobileNavigation"
import CatalogSelector from "components/InventoryList/Toolbar/CatalogSelector"
import SearchInput from "components/InventoryList/Toolbar/SearchInput"
import ShowUnavailableButton from "components/InventoryList/Toolbar/ShowUnavailableButton"
import { IconButton, ToolbarPart, StyledToolbar, ControlGroup } from "components/InventoryList/Toolbar/Styles"
import { GroupOrItemWithType, Section } from "components/InventoryList/types"
import { useInventoryContext } from "contexts/InventoryContext"
import { CatalogSummary } from "models/hubrise/CatalogSummary"

import DesktopAdjustInventoryButton from "./AdjustInventoryButton/DesktopAdjustInventoryButton"

interface ToolbarProps {
  catalogSummaries: Array<CatalogSummary>
  selectedCatalogId: string
  selectCatalog: (catalogId: string) => void
  items: Array<GroupOrItemWithType>
  section: Section
  unavailableItemsCount: number
  openModal: () => void
}

const Toolbar = ({
  catalogSummaries,
  selectedCatalogId,
  selectCatalog,
  items,
  section,
  unavailableItemsCount,
  openModal,
}: ToolbarProps): JSX.Element => {
  const { selection, setSelection } = useInventoryContext()

  const allItemIds = React.useMemo(
    () => items.filter((item) => item.type === "product" || item.type === "option").map((item) => item.data.id),
    [items],
  )

  const allSelected = React.useMemo(
    () => allItemIds.length > 0 && allItemIds.every((id) => selection.ids.includes(id)),
    [allItemIds, selection.ids],
  )

  const handleToggleAllItems = () => setSelection({ section, ids: allSelected ? [] : allItemIds })

  return (
    <StyledToolbar>
      <ToolbarPart>
        <CatalogSelector
          catalogSummaries={catalogSummaries}
          selectedCatalogId={selectedCatalogId}
          selectCatalog={selectCatalog}
        />

        <MobileNavigation catalogId={selectedCatalogId} section={section} />
      </ToolbarPart>

      <ToolbarPart>
        <ControlGroup>
          <IconButton variant="emptyOverGray" onClick={handleToggleAllItems}>
            <Icon size={iconSizes._20} code={allSelected ? "check_box" : "check_box_outline_blank"} />
          </IconButton>

          <SearchInput />
        </ControlGroup>

        <ControlGroup>
          <ShowUnavailableButton unavailableItemsCount={unavailableItemsCount} />
        </ControlGroup>

        {selection.section === section && selection.ids.length > 0 && (
          <DesktopAdjustInventoryButton openModal={openModal} section={section} count={selection.ids.length} />
        )}
      </ToolbarPart>
    </StyledToolbar>
  )
}

export default Toolbar
