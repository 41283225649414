import { debounce } from "lodash"
import * as React from "react"
import ReactDatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"

import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Day } from "models/Day"

import {
  Container,
  DateField,
  DateIcon,
  FilterField,
  Entry,
  Label,
  ClearIcon,
  StyledInput,
  StyledDatePicker,
} from "./Styles"
import useSearchBarParams from "./useSearchBarParams"

const SearchBar = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { colon } = useTranslationFormatters()

  const $dateFromRef = React.useRef<ReactDatePicker>(null)
  const $dateToRef = React.useRef<ReactDatePicker>(null)

  const { from, to, search, setFrom, setTo, setSearch } = useSearchBarParams()

  const [formSearch, setFormSearch] = React.useState(search)

  const resetSearch = () => {
    setFormSearch("")
    setSearch("")
  }

  const debouncedSearch = React.useMemo(() => {
    const debounced = debounce((value: string) => setSearch(value), 500)
    return (value: string) => {
      setFormSearch(value)
      debounced(value)
    }
  }, [setSearch])

  const addDays = (date: Date, nbDays: number): Date => {
    const day = new Date(date)
    day.setDate(day.getDate() + nbDays)
    return day
  }

  return (
    <Container>
      <FilterField>
        <Label>{t("search_bar.filter_name") + colon}</Label>
        <Entry>
          <StyledInput
            value={formSearch}
            onChange={(value) => debouncedSearch(value)}
            placeholder={t("search_bar.filter_input_placeholder")}
            effectOnFocus={false}
          />

          {formSearch !== "" && <ClearIcon onClick={() => resetSearch()} />}
        </Entry>
      </FilterField>

      <DateField>
        <Label>{t("search_bar.date_from") + colon}</Label>
        <Entry>
          <StyledDatePicker
            ref={$dateFromRef}
            selected={from.toDate()}
            onChange={(date: Date) => setFrom(Day.fromDate(date))}
            excludeDateIntervals={[{ start: addDays(to.toDate(), 1), end: new Date(2099, 1, 1) }]}
            locale={i18n.language}
            dateFormat={t("search_bar.date_format")}
            effectOnFocus={false}
          />
          <DateIcon onClick={() => $dateFromRef.current?.setOpen(true)} />
        </Entry>
      </DateField>

      <DateField>
        <Label>{t("search_bar.date_to") + colon}</Label>
        <Entry>
          <StyledDatePicker
            ref={$dateToRef}
            selected={to.toDate()}
            onChange={(date: Date) => setTo(Day.fromDate(date))}
            excludeDateIntervals={[{ start: new Date(2000, 1, 1), end: addDays(from.toDate(), -1) }]}
            locale={i18n.language}
            dateFormat={t("search_bar.date_format")}
            effectOnFocus={false}
          />
          <DateIcon onClick={() => $dateToRef.current?.setOpen(true)} />
        </Entry>
      </DateField>
    </Container>
  )
}

export default SearchBar
