import { pubSub } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import { Ringtone } from "models/Settings"
import { playRingtone } from "utils/ringtone"

import { Container, Message } from "./Styles"
import useAudio from "./useAudio"

export type RingtoneMessage = {
  action: "play"
  ringtone: Ringtone
}

export const ringtonePubSub = pubSub<RingtoneMessage>()

const Notification = (): JSX.Element | null => {
  const { t } = useTranslation()
  const { play, periodicCheck } = useAudio()
  const { settings } = useConfig()

  const [popupStatus, setPopupStatus] = React.useState<"invisible" | "done" | "visible">("invisible")

  // Play when pubSub receives a message.
  React.useEffect(() => {
    const tryToPlay = ({ action, ringtone }: RingtoneMessage): void => {
      if (action !== "play") throw new Error(`Unsupported action ${action}`)
      void play(ringtone)
        .then(() => setPopupStatus("invisible"))
        .catch(() => setPopupStatus("visible"))
    }
    ringtonePubSub.subscribe(tryToPlay)
    return () => ringtonePubSub.unsubscribe(tryToPlay)
  }, [play])

  // Periodically check if sound is enabled.
  React.useEffect(() => {
    const tryPeriodicCheck = (): void => {
      void periodicCheck()
        .then(() => setPopupStatus("invisible"))
        .catch(() => setPopupStatus("visible"))
    }
    const id = setInterval(tryPeriodicCheck, 3000)
    return () => clearInterval(id)
  }, [periodicCheck])

  return popupStatus === "invisible" ? null : (
    <Container
      onClick={() => {
        playRingtone(settings.notification_ringtone)
        setPopupStatus("done")
      }}
    >
      <Message
        dangerouslySetInnerHTML={{
          __html: popupStatus === "done" ? t("component.sound_checker.done") : t("component.sound_checker.text"),
        }}
      />
    </Container>
  )
}

export default Notification
