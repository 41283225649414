import { fontSizes } from "@hubrise/react-components"
import styled, { createGlobalStyle, css } from "styled-components"

const paperWidth = "80mm"
const horizontalMargin = "7mm"
const verticalGap = "0.65rem"

export const borderPrinter = "#bbbbbb"

export const GlobalStyle = createGlobalStyle`
  @page { 
    margin: 5mm ${horizontalMargin};
  }
`

export const Receipt = styled.div`
  width: calc(${paperWidth} - 2 * ${horizontalMargin});
  font-family: Arial, sans-serif;
  font-size: ${fontSizes._12};
  color: #000;
`

export const Section = styled.div`
  padding: ${verticalGap} 0;
  border-bottom: 1px solid ${borderPrinter};

  &:last-child {
    border-bottom: none;
  }
`
export const TitleSection = styled(Section)`
  text-transform: uppercase;
  text-align: center;
`

export const SummarySection = styled(Section)`
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
`

export const Line = styled.div<{ highlight?: boolean }>`
  margin-bottom: 0.15rem;
  ${({ highlight }) =>
    highlight === true &&
    css`
      font-weight: bold;
      text-transform: uppercase;
    `}
`

export const OriginAndCollectionCode = styled.div`
  font-size: ${fontSizes._18};
`

export const CustomerSection = styled(Section)`
  text-align: center;
`

export const Address = styled.div`
  margin-top: ${verticalGap};
`

export const CustomerNotesSection = styled(Section)`
  text-align: center;
`

export const LoyaltySection = styled(Section)``

export const DetailsSection = styled(Section)`
  text-align: center;
`

export const LocationSection = styled(Section)`
  text-align: center;
`

export const ThankYou = styled.div`
  margin-top: 0.75rem;
`
