import Pusher from "pusher-js"

import { Config } from "./Config"

type StateChangeCallback = (state: State) => void

export type Callback<Data> = ((data: Data) => Promise<any>) | ((data: Data) => any)

export type Preparer<Data> = (data: any) => Promise<Data>

const states = ["error", "initialized", "connected", "unavailable", "failed", "disconnected"] as const
type State = typeof states[number]

class Websocket {
  public state: State = "connected"

  constructor(config: Config["websocket"]) {
    const { key, cluster } = config
    this.pusher = new Pusher(key, { cluster })

    states.forEach((state) => {
      this.pusher.connection.bind(state, () => {
        if (this.stateChangeCallback && state !== this.state) this.stateChangeCallback(state)
        this.state = state
      })
    })
  }

  get connectionLost() {
    return this.state !== "connected"
  }

  subscribe(channel: string, event: string, callback: (data: any) => Promise<void>) {
    this.pusher.subscribe(channel).bind(event, callback)
  }

  registerStateChangeCallback(callback: StateChangeCallback) {
    this.stateChangeCallback = callback
  }

  private stateChangeCallback: StateChangeCallback | undefined
  private pusher: Pusher
}

export default Websocket
