import styled, { css } from "styled-components"

export const labelValueGap = "0.35rem"

export const dotBefore = css`
  &::before {
    content: "·";
    display: inline-block;
    margin-right: ${labelValueGap};
  }
`

export const NoImage = styled.div`
  display: block;
  background-color: #c2cbd2;
  position: relative;
  overflow: hidden;

  span {
    background-color: #f1f3f5;
    position: absolute;
    width: 6px;
    height: 6px;
    top: 20%;
    left: 35%;
    border-radius: 100%;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: #f1f3f5;
    width: 70%;
    padding-top: 100%;
    transform: rotate(45deg);
    border-radius: 2px;
  }

  &::before {
    right: 5%;
    top: 50%;
  }

  &::after {
    top: 70%;
    left: -10px;
  }
`

export type ColorScheme = "normal" | "dimmed"

export const orderColors = {
  late: "#b52121",
  normal: {
    statusBackground: {
      new: "#6cb14f",
      received: "#3ca4c6",
      accepted: "#4f79b1",
      in_preparation: "#584fb1",
      awaiting_shipment: "#944fb1",
      awaiting_collection: "#b14fa2",
      completed: "#888888",
      in_delivery: "#d4b443",
      delivery_failed: "#eb7323",
      cancelled: "#d95147",
      rejected: "#b2564f",
      hidden: "#fbfbfb",
    },
  },
  dimmed: {
    statusBackground: {
      new: "#d6e9ce",
      received: "#c9e6ef",
      accepted: "#cedae9",
      in_preparation: "#d1cee9",
      awaiting_shipment: "#e1cee9",
      awaiting_collection: "#e9cee5",
      completed: "#dedede",
      in_delivery: "#f3eacb",
      delivery_failed: "#f9d8c2",
      cancelled: "#f4cfcc",
      rejected: "#ead0ce",
      hidden: "#fbfbfb",
    },
  },
}
