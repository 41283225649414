import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { DesktopItem, StyledDesktop } from "components/InventoryList/Navigation/Styles"
import { Section } from "components/InventoryList/types"
import useAppNavigation from "hooks/useAppNavigation"

interface DesktopNavigationProps {
  catalogId: string
  section: Section
}

const DesktopNavigation = ({ catalogId, section }: DesktopNavigationProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { inventoryPath } = useAppNavigation()

  return (
    <StyledDesktop>
      <DesktopItem $isActive={section === "products"} onClick={() => navigate(inventoryPath(catalogId, "products"))}>
        {t("inventory.navigation.products")}
      </DesktopItem>

      <DesktopItem $isActive={section === "options"} onClick={() => navigate(inventoryPath(catalogId, "options"))}>
        {t("inventory.navigation.options")}
      </DesktopItem>
    </StyledDesktop>
  )
}

export default DesktopNavigation
