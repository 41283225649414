import { Modal, modalWidth, UseKeyboardLevel } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import MapModalContent from "components/MapModalContent"
import { useConfig } from "contexts/ConfigContext"
import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Order } from "models/hubrise/Order"

import { Code, CodeLabel, CodeValue, Container, Phone, StyledIcon, StyledLink } from "./Styles"

const EmailPhoneGps = ({ order }: { order: Order }): JSX.Element | null => {
  const { t } = useTranslation()
  const { location } = useConfig()
  const { colon } = useTranslationFormatters()

  const { customer } = order
  const { coordinates } = customer

  const phoneHuman = customer.phoneHuman(location.country)
  const phoneE164 = customer.phoneE164(location.country)
  const phoneToDial = phoneE164 && [phoneE164, customer.phoneAccessCode].filter(Boolean).join("#")

  const showEmail = Boolean(customer.email)
  const showPhone = Boolean(phoneHuman)
  const showCoordinates = Boolean(coordinates) && order.serviceType === "delivery"

  if (!(showEmail || showPhone || showCoordinates)) return null

  return (
    <Container>
      {showEmail && (
        <StyledLink href={`mailto:${customer.email}`} target="_blank">
          <StyledIcon code="mail" />
          {customer.email}
        </StyledLink>
      )}

      {showPhone && (
        <Phone>
          <StyledLink href={`tel:${phoneToDial}`}>
            <StyledIcon code="call" />
            {phoneHuman}
          </StyledLink>
          {customer.phoneAccessCode !== null && (
            <Code>
              <CodeLabel>{t("popup.show_order.phone_access_code") + colon}</CodeLabel>
              <CodeValue>{customer.phoneAccessCode}</CodeValue>
            </Code>
          )}
        </Phone>
      )}

      {showCoordinates && (
        <Modal
          modalWidth={modalWidth.xlarge}
          keyboardLevel={UseKeyboardLevel.ModalInModal}
          renderContent={({ close }) => <MapModalContent center={coordinates!} order={order} close={close} />}
          renderLink={({ open }) => (
            <StyledLink onClick={open}>
              <StyledIcon code="pin_drop" />
              {t("popup.show_order.view_on_map")}
            </StyledLink>
          )}
        />
      )}
    </Container>
  )
}

export default EmailPhoneGps
