import * as React from "react"

import { useClientIcon } from "contexts/ClientIconProvider"
import { Order } from "models/hubrise/Order"

/**
 * Get the icon of the order's channel, in the form of a data URL.
 * @param order
 */

const useOrderIconUrl = (order: Order): string | null => {
  const { getIcon } = useClientIcon()

  const [iconUrl, setIconUrl] = React.useState<string | null>(null)

  React.useEffect(() => {
    const icon = order.type.icon
    if (icon.type === "imageURL") {
      setIconUrl(icon.imageUrl)
    } else {
      void getIcon(icon.clientName).then(setIconUrl)
    }
  }, [order.createdBy, getIcon, order.type.icon])

  return iconUrl
}

export default useOrderIconUrl
