import { Button, Modal, ModalStyle, modalWidth } from "@hubrise/react-components"
import _ from "lodash"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { adjustTitle } from "components/InventoryList/shared"
import { useInventoryContext } from "contexts/InventoryContext"
import { useCatalogInventoryMutation } from "hooks/mutations"
import { exportCatalogInventory, InventoryEntry } from "models/hubrise/CatalogInventory"

import { ItemWithType, OptionWithType, ProductWithType, Section } from "../types"

import Availability, { AvailabilityState } from "./Availability"
import SkuSelector from "./SkuSelector"

interface UpdateModalProps {
  catalogId: string
  section: Section
  editedItems: Array<ItemWithType>
  close: () => void
}

function isProductItems(editedItems: Array<ItemWithType>): editedItems is Array<ProductWithType> {
  return editedItems.length > 0 && editedItems[0].type === "product"
}

function isOptionItems(editedItems: Array<ItemWithType>): editedItems is Array<OptionWithType> {
  return !isProductItems(editedItems)
}

const UpdateModal = ({ catalogId, section, editedItems, close }: UpdateModalProps): JSX.Element => {
  const { t } = useTranslation()
  const { resetSelection } = useInventoryContext()

  const skuNames = React.useMemo(() => {
    if (!isProductItems(editedItems)) return []
    const skus = editedItems.flatMap(({ data }) => data.skus.filter((sku) => sku.ref !== null))
    return _.uniq(skus.map((sku) => sku.name)).filter(Boolean)
  }, [editedItems])

  const [availability, setAvailability] = React.useState<AvailabilityState>({
    available: true,
    stock: null,
    expiresAt: null,
  })
  const [selectedSkuNames, setSelectedSkuNames] = React.useState<Array<string>>(skuNames)

  const { mutate, isPending } = useCatalogInventoryMutation(catalogId)

  const editedRefs = (): Array<string> => {
    let refs: Array<string | null> = []
    if (isProductItems(editedItems)) {
      refs = editedItems
        .flatMap((item) => item.data.skus)
        .filter((sku) => selectedSkuNames.includes(sku.name) || sku.name == null)
        .map(({ ref }) => ref)
    } else if (isOptionItems(editedItems)) {
      refs = editedItems.map((item) => item.data.ref)
    }
    refs = refs.filter(Boolean)
    refs = _.uniq(refs)
    return refs as Array<string>
  }

  const handleSave = () => {
    const stock = availability.available ? availability.stock : 0
    const expiresAt = availability.expiresAt?.toJSDate() ?? null
    const inventoryEntries: Array<InventoryEntry> = editedRefs().map((ref) => ({
      type: isProductItems(editedItems) ? "sku" : "option",
      ref,
      stock,
      expiresAt,
    }))

    mutate(exportCatalogInventory(inventoryEntries))
    resetSelection()
    close()
  }

  return (
    <Modal
      onClose={close}
      renderContent={({ close }) => (
        <ModalStyle.Content>
          <ModalStyle.Header>
            <ModalStyle.Title>
              {adjustTitle(section, editedItems.length, _.capitalize(editedItems[0].data.name), t)}
            </ModalStyle.Title>
            <ModalStyle.Close onClick={close} />
          </ModalStyle.Header>

          <ModalStyle.Body>
            <Availability availability={availability} setAvailability={setAvailability} />

            {skuNames.length > 0 && (
              <SkuSelector
                skuNames={skuNames}
                selectedSkuNames={selectedSkuNames}
                setSelectedSkuNames={setSelectedSkuNames}
              />
            )}
          </ModalStyle.Body>

          <ModalStyle.Footer>
            <Button variant="primary" onClick={handleSave} disabled={isPending}>
              {t("general.confirm")}
            </Button>

            <Button variant="empty" onClick={close}>
              {t("general.cancel")}
            </Button>
          </ModalStyle.Footer>
        </ModalStyle.Content>
      )}
      modalWidth={modalWidth.xlarge}
    />
  )
}

export default UpdateModal
