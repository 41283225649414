import * as React from "react"
import { useTranslation } from "react-i18next"

import { useConfig } from "contexts/ConfigContext"
import useOrderIconUrl from "hooks/useOrderIconUrl"
import usePrint from "hooks/usePrint"
import { Order } from "models/hubrise/Order"
import { Status } from "models/hubrise/types"

import StatusTransition from "./StatusTransition"
import {
  ButtonGroup,
  CollectionCode,
  StyledActions,
  Group,
  PrintButton,
  StyledSpinner,
  Title,
  Value,
  Origin,
  OriginLabels,
  OriginLogo,
  OriginLabel,
} from "./Styles"

interface ActionsProps {
  order: Order
  className?: string
}

const Actions = ({ order, className }: ActionsProps): JSX.Element => {
  const { t } = useTranslation()
  const { settings } = useConfig()
  const { printIframe, print, iFrameStatus } = usePrint(order)

  const [editingStatus, setEditingStatus] = React.useState<Status | null>(null)
  const statusTransitions = settings.status_transitions[order.status] || []

  const iconUrl = useOrderIconUrl(order)

  return (
    <StyledActions className={className}>
      <Origin>
        {iconUrl !== null && <OriginLogo src={iconUrl} alt={order.type.name} />}
        <OriginLabels>
          <OriginLabel>{order.type.name}</OriginLabel>
          {order.connectionName !== null && <OriginLabel>{order.connectionName}</OriginLabel>}
          {order.collectionCode !== null && <CollectionCode>{order.collectionCode}</CollectionCode>}
        </OriginLabels>
      </Origin>

      <Group>
        <Title>{t("popup.show_order.current_status")}</Title>
        <Value>{t(`order.status.${order.status}`)}</Value>
      </Group>

      {statusTransitions.length > 0 && (
        <ButtonGroup>
          <Title>{t("popup.show_order.change_status")}</Title>

          {statusTransitions.map((status: Status, index) => (
            <StatusTransition
              order={order}
              status={status}
              isEditing={editingStatus === status}
              setIsEditing={(isEditing) => setEditingStatus(isEditing ? status : null)}
              key={index}
            />
          ))}
        </ButtonGroup>
      )}

      <ButtonGroup>
        <Title>{t("popup.show_order.actions.title")}</Title>
        <PrintButton onClick={print}>
          {t("popup.show_order.actions.print")}
          <StyledSpinner isVisible={iFrameStatus === "loading"} />
        </PrintButton>
      </ButtonGroup>

      {printIframe}
    </StyledActions>
  )
}

export default Actions
