import { Layout as OrigLayout, modalWidth } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

import SettingsModalContent from "components/SettingsModalContent"
import { useConfig } from "contexts/ConfigContext"
import useTabName from "hooks/useTabName"

import MobileRightHandSide from "./MobileRightHandSide"
import { ToolbarAsideDesktop, DefaultLayout, ToolbarMain, DefaultMobileTitle, ToolbarLayout } from "./Styles"
import usePageHeaderLinks from "./hooks/usePageHeaderLinks"

interface LayoutProps {
  children: React.ReactNode
  layout?:
    | {
        type: "default"
      }
    | {
        type: "toolbar"
        toolbar: JSX.Element
        desktopNavigation: JSX.Element
      }
}

const Layout = ({ children, layout = { type: "default" } }: LayoutProps): JSX.Element => {
  const { t } = useTranslation()
  const { images, location } = useConfig()
  const tabName = useTabName()
  const { logoLink, links } = usePageHeaderLinks()

  return (
    <OrigLayout
      pageHeader={{
        accountOrLocation: { name: `${location.account.name} ${location.name}`, id: location.id },
        logo: images.logo,
        logoLink,
        links,
        settingsModalContent: SettingsModalContent,
        settingsModalWidth: modalWidth.xlarge,
        mobileRightHandSide: <MobileRightHandSide />,
        bottomShadow: true,
      }}
      toolbar={layout.type === "toolbar" ? layout.toolbar : undefined}
      lightestBackground={layout.type === "default"}
    >
      {layout.type === "default" ? (
        <DefaultLayout>
          <DefaultMobileTitle>{t(`header.${tabName}_tab_name`)}</DefaultMobileTitle>
          {children}
        </DefaultLayout>
      ) : (
        <ToolbarLayout>
          <ToolbarAsideDesktop>{layout.desktopNavigation}</ToolbarAsideDesktop>
          <ToolbarMain>{children}</ToolbarMain>
        </ToolbarLayout>
      )}

      <Outlet />
    </OrigLayout>
  )
}

export const withLayout = <Props,>(
  component: React.FunctionComponent<Props>,
  componentName = component.displayName,
  layoutProps?: Omit<LayoutProps, "children">,
): React.FunctionComponent<Props> => {
  const Component: React.FunctionComponent<Props> = (props, context) => (
    <Layout {...layoutProps}>{component(props, context)}</Layout>
  )
  Component.displayName = `${componentName}WithLayout`
  return Component
}

export default Layout
