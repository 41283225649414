import * as React from "react"

import { Charge as ChargeModel } from "models/hubrise/order/Charge"

import { TdName, TdPrice } from "./Styles"

const Charge = ({ charge }: { charge: ChargeModel }): JSX.Element => (
  <React.Fragment>
    <TdName>{charge.name}</TdName>
    <TdPrice dangerouslySetInnerHTML={{ __html: charge.price.toHumanString() }} />
  </React.Fragment>
)

export default Charge
