import * as React from "react"

import { Item as ItemModel } from "models/hubrise/order/Item"

import CustomerNotes from "./CustomerNotes"
import OptionLists from "./OptionLists"
import Top from "./Top"

const Item = ({ item }: { item: ItemModel }): JSX.Element => (
  <React.Fragment>
    <Top item={item} />
    <OptionLists item={item} />
    <CustomerNotes item={item} />
  </React.Fragment>
)

export default Item
