import * as React from "react"
import { useNavigate } from "react-router-dom"

import { withLayout } from "components/Layout"
import Loading from "components/Loading"
import { useConfig } from "contexts/ConfigContext"
import { useCatalogSummariesQuery } from "hooks/queries"
import useAppNavigation from "hooks/useAppNavigation"
import useTitle from "hooks/useTitle"
import { defaultCatalogId } from "utils/paths"

const InventoryLoader = withLayout((): JSX.Element => {
  const navigate = useNavigate()
  const { inventoryPath } = useAppNavigation()

  const { appInstanceId } = useConfig()

  useTitle(0)

  const { data: catalogSummaries } = useCatalogSummariesQuery(appInstanceId)

  React.useEffect(() => {
    if (catalogSummaries) {
      const catalogId = defaultCatalogId(catalogSummaries)
      navigate(inventoryPath(catalogId, "products"))
    }
  }, [catalogSummaries, navigate, inventoryPath])

  return <Loading />
}, "InventoryLoader")

export default InventoryLoader
