import type { PageHeaderProps } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useOrderContext } from "contexts/OrderContext"
import useAppNavigation from "hooks/useAppNavigation"
import useTabName from "hooks/useTabName"

import { Counter } from "./Styles"

type Links = PageHeaderProps["links"]

const usePageHeaderLinks = (): { logoLink: string; links: Links } => {
  const { t } = useTranslation()
  const tabName = useTabName()
  const { nbNewTodayOrders, nbNewFutureOrders } = useOrderContext()
  const { withAppInstanceId } = useAppNavigation()

  return {
    logoLink: withAppInstanceId("/orders"),
    links: [
      {
        to: withAppInstanceId("/orders"),
        isActive: tabName === "today",
        testId: "today-tab",
        content: (
          <>
            {t("header.today_tab_name")}
            {nbNewTodayOrders > 0 && (
              <Counter $tabName="today" data-testid="counter">
                {nbNewTodayOrders}
              </Counter>
            )}
          </>
        ),
      },
      {
        to: withAppInstanceId("/orders/future"),
        isActive: tabName === "future",
        testId: "future-tab",
        content: (
          <>
            {t("header.future_tab_name")}
            {nbNewFutureOrders > 0 && <Counter $tabName="future">{nbNewFutureOrders}</Counter>}
          </>
        ),
      },
      {
        to: withAppInstanceId("/orders/history"),
        isActive: tabName === "history",
        testId: "history-tab",
        content: t("header.history_tab_name"),
      },
      {
        to: withAppInstanceId("/opening-hours"),
        isActive: tabName === "opening-hours",
        testId: "opening-hours-tab",
        content: t("header.opening-hours_tab_name"),
      },
      {
        to: withAppInstanceId("/inventory"),
        isActive: tabName === "inventory",
        testId: "inventory-tab",
        content: t("header.inventory_tab_name"),
      },
    ],
  }
}

export default usePageHeaderLinks
