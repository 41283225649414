import { DateTime } from "luxon"

export class Day {
  constructor(public year: number, public month: number, public day: number) {}

  /**
   * Returns a Date.
   */
  toDate(): Date {
    return new Date(this.year, this.month - 1, this.day)
  }

  /**
   * Constructs a Day from a Date.
   */
  static fromDate(date: Date): Day {
    return new Day(date.getFullYear(), date.getMonth() + 1, date.getDate())
  }

  /**
   *  Returns a string with the format YYYY-MM-DD. Months and days are padded with zeros.
   */
  toString(): string {
    return [this.year.toString(), this.month.toString().padStart(2, "0"), this.day.toString().padStart(2, "0")].join(
      "-",
    )
  }

  /**
   * Returns a string with the format DD/MM/YYYY.
   */
  toHumanString(): string {
    return [this.day.toString(), this.month.toString(), this.year.toString()].join("/")
  }

  /**
   * Constructs a Day from a string with the format YYYY-MM-DD.
   * @param dateString
   */
  static fromString(dateString: string): Day {
    const [year, month, day] = dateString.split("-")
    return new Day(Number(year), Number(month), Number(day))
  }

  /**
   * Convert a DateTime to a Day, given a timezone and cutoff time.
   * @param dateTime
   * @param cutoff Cutoff time
   * @param timezone Optional timezone. If not provided, uses the DateTime's timezone.
   */
  static fromDateTime(dateTime: DateTime, cutoff: number, timezone?: string): Day
  static fromDateTime(dateTime: DateTime | null, cutoff: number, timezone?: string): Day | null
  static fromDateTime(dateTime: DateTime | null, cutoff: number, timezone?: string) {
    if (dateTime === null) return null
    const dateTime_tz = timezone !== undefined ? dateTime.setZone(timezone) : dateTime
    const date = dateTime_tz.minus({ seconds: cutoff })
    return new Day(date.year, date.month, date.day)
  }

  /**
   * Returns the current day, in the location timezone and given a cutoff time.
   * @param cutoff
   * @param timezone
   */
  static today(cutoff: number, timezone: string): Day {
    return Day.fromDateTime(DateTime.now(), cutoff, timezone)
  }

  /**
   * Override the default equality operator.
   * @param other
   */
  equals(other: Day): boolean {
    return this.toString() === other.toString()
  }
}
