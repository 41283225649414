import _ from "lodash"
import * as React from "react"
import { useTranslation } from "react-i18next"
import AutoSizer from "react-virtualized-auto-sizer"
import { VariableSizeList } from "react-window"

import { useInventoryContext } from "contexts/InventoryContext"
import { Catalog, Product } from "models/hubrise/Catalog"
import { InventoryEntry } from "models/hubrise/CatalogInventory"

import { Group, GroupOrItemWithType, Item, ItemWithType, OptionWithType, ProductWithType, Section } from "../types"

import GroupRow from "./GroupRow"
import ItemRow from "./ItemRow"
import { ListWrapper, RowWrapper, NoMatch } from "./Styles"
import { categoryHeight, optionHeight, optionListHeight, productHeight } from "./heights"

interface ListViewProps<G extends Group, I extends Item> {
  section: Section & I extends Product ? "products" : "options"
  items: Array<GroupOrItemWithType>
  catalog: Catalog
  setEditedItems: React.Dispatch<React.SetStateAction<Array<ItemWithType>>>
  getDescendants: (group: G) => Array<I>
  getInventoryEntry: (ref: string | null) => InventoryEntry | undefined
  isGroupSelected: (group: G) => boolean
  isItemEditable: (item: I) => boolean
  isLastGroupItem: (index: number) => boolean
}

const ListView = <G extends Group, I extends Item>({
  section,
  items,
  catalog,
  setEditedItems,
  getDescendants,
  getInventoryEntry,
  isGroupSelected,
  isItemEditable,
  isLastGroupItem,
}: ListViewProps<G, I>): JSX.Element => {
  const { t } = useTranslation()

  const { selection, setSelection, showUnavailableItems, searchValue } = useInventoryContext()

  const getItemSize = React.useCallback(
    (index: number) => {
      const item = items[index]

      switch (item.type) {
        case "category":
          return categoryHeight("small")
        case "product":
          return productHeight(item.data, isLastGroupItem(index) ? "large" : "small")
        case "option_list":
          return optionListHeight("small")
        case "option":
          return optionHeight(isLastGroupItem(index) ? "large" : "small")
        default:
          return 0
      }
    },
    [isLastGroupItem, items],
  )

  const createGroupRow = (group: G, section: Section, height: number) => (
    <GroupRow
      group={group}
      height={height}
      isSelected={isGroupSelected(group)}
      onClick={(group) =>
        setEditedItems(
          getDescendants(group).map(
            (item) =>
              ({
                type: section === "products" ? "product" : "option",
                data: item,
              } as ItemWithType),
          ),
        )
      }
      onCheck={(group) =>
        setSelection((previous) => {
          const descendants = getDescendants(group)
          const ids =
            previous.section !== section
              ? descendants.map((item) => item.id)
              : isGroupSelected(group)
              ? previous.ids.filter((id) => !descendants.some((item) => item.id === id))
              : _.uniq([...previous.ids, ...descendants.map((item) => item.id)])
          return { section, ids }
        })
      }
    />
  )

  const createItemRow = (item: I, section: Section, height: number, isLastItem: boolean) => (
    <ItemRow
      catalogId={catalog.id}
      getInventoryEntry={getInventoryEntry}
      item={item}
      height={height}
      isSelected={selection.section === section && selection.ids.includes(item.id)}
      isLastItem={isLastItem}
      isEditable={isItemEditable(item)}
      onClick={(item) =>
        setEditedItems([{ type: section === "products" ? "product" : "option", data: item }] as
          | Array<ProductWithType>
          | Array<OptionWithType>)
      }
      onCheck={(item) =>
        setSelection((previous) => ({
          section,
          ids:
            previous.section !== section
              ? [item.id]
              : previous.ids.includes(item.id)
              ? previous.ids.filter((id) => id !== item.id)
              : [...previous.ids, item.id],
        }))
      }
    />
  )

  const rowRender = ({ index, style }: { index: number; style?: React.CSSProperties }) => {
    const item = items[index]
    const isLastItem = isLastGroupItem(index)

    const rowTypeRender = () => {
      switch (item.type) {
        case "category":
          return createGroupRow(item.data as G, "products", categoryHeight())
        case "product":
          return createItemRow(item.data as I, "products", productHeight(item.data), isLastItem)
        case "option_list":
          return createGroupRow(item.data as G, "options", optionListHeight())
        case "option":
          return createItemRow(item.data as I, "options", optionHeight(), isLastItem)
      }
    }

    return (
      <RowWrapper style={style} $isLastItem={isLastItem}>
        {rowTypeRender()}
      </RowWrapper>
    )
  }

  return (
    <>
      {items.length > 0 ? (
        <ListWrapper>
          <AutoSizer disableWidth>
            {({ height }: { height: number }) => {
              return (
                <VariableSizeList
                  key={[searchValue, catalog.id, showUnavailableItems, items.length].join()}
                  itemCount={items.length}
                  itemSize={getItemSize}
                  width="100%"
                  height={Number(height) ?? "100%"}
                  // The list width does not depend on the content width of its absolutely positioned rows, so
                  // we need to set a reasonable minimum width for narrow screens.
                  style={{ minWidth: "30rem" }}
                >
                  {rowRender}
                </VariableSizeList>
              )
            }}
          </AutoSizer>
        </ListWrapper>
      ) : (
        <NoMatch>
          {searchValue != "" || showUnavailableItems
            ? t(`inventory.no_match.${section}`)
            : t(`inventory.empty_catalog.${section}`)}
        </NoMatch>
      )}
    </>
  )
}

export default ListView
