import * as React from "react"

import { StyledLink } from "./Styles"

export interface ExternalLinkProps {
  to: string
  className?: string
  children?: React.ReactNode
}

const ExternalLink = ({ to, className, children }: ExternalLinkProps): JSX.Element => {
  return (
    <StyledLink href={to} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </StyledLink>
  )
}

export default ExternalLink
