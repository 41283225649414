import { Icon } from "models/hubrise/order/Type"

import DefaultType from "models/hubrise/order/types/DefaultType"

class DeliverooType extends DefaultType {
  get name() {
    return "Deliveroo"
  }

  get icon() {
    return { type: "clientName", clientName: "Deliveroo Bridge" } as Icon
  }
}

export default DeliverooType
