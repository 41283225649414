import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import { Day } from "models/Day"
import { Subscription } from "models/Subscription"
import { Order } from "models/hubrise/Order"

import { OrderStore } from "./types"
import useOrderUpdated from "./useOrderUpdated"
import useServer from "./useServer"

const useTodayAndFutureOrders = (subscription: Subscription<Order>) => {
  const { cutoff, timezone } = useConfig()
  const { getTodayAndFutureOrders } = useServer()

  const getToday = React.useCallback(() => Day.today(cutoff, timezone), [cutoff, timezone])

  const [todayAndFutureOrders, setTodayAndFutureOrders] = React.useState<OrderStore>({
    orders: undefined,
    isLoading: true,
  })

  useOrderUpdated(subscription, setTodayAndFutureOrders)

  React.useEffect(() => {
    const fetchOrders = async () => {
      const orders = await getTodayAndFutureOrders()
      setTodayAndFutureOrders({ isLoading: false, orders })
    }
    void fetchOrders()
  }, [getTodayAndFutureOrders])

  const [day, setDay] = React.useState<Day>(getToday)

  React.useEffect(() => {
    const updateDayOnDayChange = () => {
      setDay((day) => (getToday().toString() === day.toString() ? day : getToday()))
    }
    const id = setInterval(updateDayOnDayChange, 1000)
    return () => clearInterval(id)
  }, [getToday])

  const todayOrders = React.useMemo(
    () => Order.today(todayAndFutureOrders.orders || [], cutoff, day),
    [cutoff, day, todayAndFutureOrders.orders],
  )

  const nbNewTodayOrders = React.useMemo(
    () => todayOrders.filter((order) => order.status === "new").length,
    [todayOrders],
  )

  const futureOrders = React.useMemo(
    () => Order.future(todayAndFutureOrders.orders || [], cutoff, day),
    [cutoff, day, todayAndFutureOrders.orders],
  )

  const nbNewFutureOrders = React.useMemo(
    () => futureOrders.filter((order) => order.status === "new").length,
    [futureOrders],
  )

  return React.useMemo(
    () => ({
      todayAndFutureOrders,
      todayOrders,
      nbNewTodayOrders,
      futureOrders,
      nbNewFutureOrders,
      day,
    }),
    [futureOrders, nbNewFutureOrders, nbNewTodayOrders, todayAndFutureOrders, todayOrders, day],
  )
}

export default useTodayAndFutureOrders
