import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import useWebsocket from "hooks/useWebsocket"
import { Day } from "models/Day"
import { OrderNotificationStore } from "models/OrderNotificationStore"
import { Intervals } from "models/Settings"
import { Subscription } from "models/Subscription"
import { Order } from "models/hubrise/Order"

const orderNotificationStore = new OrderNotificationStore()

const useOrderNotifications = (
  subscription: Subscription<Order>,
): { orderNotificationStore: OrderNotificationStore; notificationStoreHash: number } => {
  const [notificationStoreHash, setNotificationStoreHash] = React.useState(0)
  const updateHash = () => setNotificationStoreHash((h) => h + 1)

  const { settings, cutoff, timezone } = useConfig()
  const { notification_interval, notification_repeats, notification_ringtone } = settings
  const interval = Intervals.find(({ label }) => label === notification_interval)!.value

  orderNotificationStore.updateSettings(interval, notification_repeats, notification_ringtone, updateHash)

  const callback = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (order: Order) => {
      const today = Day.today(cutoff, timezone)
      if (order.status === "new" && order.isDueTodayOrFuture(cutoff, today)) {
        orderNotificationStore.addOrder(order.id)
      }
    },
    [cutoff, timezone],
  )
  useWebsocket(subscription, callback)

  React.useEffect(() => orderNotificationStore.startBackgroundJob(), [])

  // We return the hash to refresh the consumers of the context when orderNotificationStore changes.
  return { orderNotificationStore: orderNotificationStore, notificationStoreHash: notificationStoreHash }
}

export default useOrderNotifications
