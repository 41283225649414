import * as React from "react"

import { Item } from "models/hubrise/order/Item"

import { TdCustomerNotes } from "../Styles"

const CustomerNotes = ({ item }: { item: Item }): JSX.Element | null =>
  item.customerNotes !== null ? <TdCustomerNotes>{item.customerNotes}</TdCustomerNotes> : null

export default CustomerNotes
