import { Icon } from "models/hubrise/order/Type"

import DefaultType from "models/hubrise/order/types/DefaultType"

class ShopifyType extends DefaultType {
  get name() {
    return "Shopify"
  }

  get icon() {
    return { type: "clientName", clientName: "Shopify Bridge" } as Icon
  }
}

export default ShopifyType
