import * as React from "react"
import { useTranslation } from "react-i18next"

import { Dot } from "components/InventoryList/ListView/Styles"
import { readableExpiresAt } from "components/InventoryList/shared"
import { useConfig } from "contexts/ConfigContext"
import { InventoryEntry } from "models/hubrise/CatalogInventory"

import { ExpiresAt, IndicatorIcon, Text } from "./Styles"

interface IndicatorProps {
  itemRef: string | null
  inventoryEntry: InventoryEntry | undefined
}

const Indicator = ({ itemRef, inventoryEntry }: IndicatorProps): JSX.Element => {
  const { t } = useTranslation()
  const { cutoff, timezone } = useConfig()

  const indicator = ((): JSX.Element => {
    if (itemRef === null) {
      return (
        <>
          <IndicatorIcon code="warning" /> <Text>{t("inventory.status.warning")}</Text>
        </>
      )
    }

    if (inventoryEntry === undefined || inventoryEntry.stock === null) {
      return <Text>{t("inventory.status.available")}</Text>
    } else if (inventoryEntry.stock === 0) {
      if (inventoryEntry.expiresAt === null) {
        return <Text $strong>{t("inventory.status.unavailable")}</Text>
      } else {
        return (
          <>
            <Text $strong>{t("inventory.status.unavailable")}</Text>
            <Dot />
            <IndicatorIcon code="schedule" />
            <ExpiresAt>{readableExpiresAt(inventoryEntry.expiresAt, cutoff, timezone, t)}</ExpiresAt>
          </>
        )
      }
    } else {
      return <Text>{t("inventory.status.in_stock", { stock: inventoryEntry.stock })}</Text>
    }
  })()

  return (
    <>
      <Dot />
      {indicator}
    </>
  )
}

export default Indicator
