import * as React from "react"

import { Error, Link, Message, Title } from "./Styles"

const MissingAppInstanceId = (): JSX.Element => {
  return (
    <Error>
      <Title>The URL is invalid.</Title>
      <Message>
        Please connect again from the HubRise{" "}
        <Link href="https://manager.hubrise.com/connections">CONNECTIONS page</Link>.
      </Message>
    </Error>
  )
}

export default MissingAppInstanceId
