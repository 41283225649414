import { colors, fontSizes } from "@hubrise/react-components"
import styled from "styled-components"

export const Row = styled.div`
  margin-top: 2.25rem;

  &:first-child {
    margin-top: 0;
  }
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Title = styled.div`
  color: ${colors.textDarkest};
  font-size: ${fontSizes._14};
  margin-bottom: 1rem;
`

export const Subtitle = styled.div`
  color: ${colors.textMedium};
  font-size: ${fontSizes._14};
  margin-top: 0.35rem;
`
