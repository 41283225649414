import "react-datepicker/dist/react-datepicker.css"
import enGB from "date-fns/locale/en-GB"
import frFR from "date-fns/locale/fr"
import * as React from "react"
import type { ReactDatePickerProps } from "react-datepicker"
import ReactDatePicker, { registerLocale } from "react-datepicker"

import { Wrapper } from "./Styles"

registerLocale("en-GB", enGB)
registerLocale("fr-FR", frFR)

type DatePickerProps = ReactDatePickerProps & {
  className?: string
  effectOnFocus?: boolean
}

const DatePicker = React.forwardRef(
  ({ className, effectOnFocus = true, ...props }: DatePickerProps, $ref: React.ForwardedRef<ReactDatePicker>) => {
    return (
      <Wrapper className={className} $effectOnFocus={effectOnFocus}>
        <ReactDatePicker {...props} ref={$ref} />
      </Wrapper>
    )
  },
)

DatePicker.displayName = "DatePicker"

export default DatePicker
