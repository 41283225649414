import * as React from "react"

import { Order } from "models/hubrise/Order"

import EmailPhoneGps from "./EmailPhoneGps"
import OtherInfo from "./OtherInfo"

interface InfoProps {
  order: Order
  className?: string
}

const Info = ({ order, className }: InfoProps): JSX.Element => {
  return (
    <div className={className}>
      <EmailPhoneGps order={order} />
      <OtherInfo order={order} />
    </div>
  )
}

export default Info
