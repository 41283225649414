import { Money } from "@hubrise/react-components"

export class Option {
  constructor(
    public optionListName: string,
    public name: string,
    public ref: string | null,
    public price: Money,
    public quantity: number,
    public removed: boolean,
  ) {}
}

export type GroupedOptions = { [optionListName: string]: Array<Option> }
