import { colors, mixin, sizes } from "@hubrise/react-components"
import styled from "styled-components"

import { Status } from "models/hubrise/types"
import { orderColors } from "styles/common"

export const Container = styled.div`
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Label = styled.span`
  color: ${colors.textMedium};
  padding: 0.15rem 0.3rem 0.15rem 0;
  ${mixin.clickable};
`

export const Statuses = styled.div`
  margin-top: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
`

export const StatusButton = styled.div<{ $status: Status | "gray" }>`
  font-weight: lighter;
  padding: 0.3rem;
  border-radius: ${sizes.borderRadius};
  ${mixin.clickable};
  color: ${colors.white};
  background-color: ${({ $status }) => ($status === "gray" ? "#bbb" : orderColors.normal.statusBackground[$status])};
`
