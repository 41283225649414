import { colors } from "@hubrise/react-components"
import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  // This wrapper can be customised by passing a className prop
`

export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

export const Checkbox = styled.div<{ $checked: boolean; $disabled: boolean }>`
  width: 1rem;
  height: 1rem;

  border-radius: 2px;
  border: 2px solid ${colors.primary};
  cursor: pointer;
  pointer-events: all;

  ${({ $disabled, $checked }) =>
    $disabled
      ? css`
          pointer-events: none;
          border: 2px solid ${colors.borderLight};
          background-color: ${colors.backgroundLight};
        `
      : css`
          background-color: ${$checked ? colors.primary : "transparent"};
        `}

  transition: color 300ms ease-in;

  ${CheckboxIcon} {
    visibility: ${(props) => (props.$checked ? "visible" : "hidden")};
  }
`

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
