import * as React from "react"

import { Order } from "models/hubrise/Order"

import Charge from "./Charge"
import Deal from "./Deal"
import Discount from "./Discount"
import Item from "./Item"
import { ItemsSection } from "./Styles"
import Total from "./Total"

const Items = ({ order }: { order: Order }): JSX.Element => {
  return (
    <ItemsSection>
      {order.groupedItemsByDeal.map(({ deal, items }, index) => {
        const deleted = items.every((item) => item.deleted)
        return deleted ? null : <Deal key={index} deal={deal} items={items} />
      })}

      {order.itemsWithoutDeals.map((item, index) => {
        return item.deleted ? null : <Item key={index} item={item} />
      })}

      {order.charges.map((charge, index) => {
        return charge.deleted ? null : <Charge key={index} charge={charge} />
      })}

      {order.discounts.map((discount, index) => {
        return discount.deleted ? null : <Discount key={index} discount={discount} />
      })}

      <Total order={order} />
    </ItemsSection>
  )
}

export default Items
