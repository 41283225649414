import { colors, iconSizes, Icon, Button, burgerMenuBreakpoint } from "@hubrise/react-components"
import styled from "styled-components"

export const TextButton = styled(Button)<{ $isSelected: boolean }>`
  color: ${colors.textDark};
  padding: 0 0.75rem;

  background-color: ${({ $isSelected }) => ($isSelected ? `${colors.backgroundWhite}` : ``)};

  &,
  &:hover {
    outline: ${({ $isSelected }) => ($isSelected ? `1px solid ${colors.borderLight}` : `none`)};
  }

  &:disabled {
    cursor: default;
  }

  @media (min-width: ${burgerMenuBreakpoint}) {
    margin-right: 0;
    margin-left: 0.875rem;
  }
`

export const CloseIcon = styled(Icon).attrs({ size: iconSizes._14, code: "close" })`
  margin-left: 0.625rem;
  color: ${colors.textDark};
`
