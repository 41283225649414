import Type, { Icon } from "models/hubrise/order/Type"

class DefaultType extends Type {
  get name() {
    return this.order.createdBy
  }

  get icon() {
    return { type: "clientName", clientName: this.order.createdBy } as Icon
  }
}

export default DefaultType
