import { Ringtone } from "models/Settings"
import { OrderId } from "models/hubrise/Order"
import { playRingtone } from "utils/ringtone"

export class OrderNotificationStore {
  private notificationMap = new Map<OrderId, OrderNotification>()
  private lastPlayedAt = Date.now()

  private settings?: {
    interval: number
    repeats: number
    ringtone: Ringtone
    onUpdate: () => void
  }

  public hasAny(): boolean {
    return this.notificationMap.size > 0
  }

  public hasOrder(orderId: OrderId): boolean {
    return this.notificationMap.has(orderId)
  }

  public updateSettings(interval: number, repeats: number, ringtone: Ringtone, onUpdate: () => void): void {
    this.settings = { interval, repeats, ringtone, onUpdate }
  }

  /**
   * Adds a notification for the given orderId.
   * Only runs if settings have been updated at least once.
   * @param orderId
   */
  public addOrder(orderId: OrderId): void {
    if (!this.settings) return
    const { repeats, ringtone, onUpdate } = this.settings

    playRingtone(ringtone)

    // lastPlayedAt is used to calculate the next notification. We only update it if no other notification is running.
    if (!this.hasAny()) this.lastPlayedAt = Date.now()

    if (repeats > 0) {
      const notification = new OrderNotification(repeats)
      this.notificationMap.set(orderId, notification)
      onUpdate()
    }
  }

  /**
   * Remove the notification for the given orderId.
   * @param orderId
   */
  public removeOrder(orderId: OrderId): void {
    if (!this.notificationMap.has(orderId)) return

    this.notificationMap.delete(orderId)
    this.settings?.onUpdate()
  }

  /**
   * Every interval, if any notification is running, we play the ringtone and decrement the repeats.
   * Only runs if settings have been updated at least once.
   */
  public startBackgroundJob(): void {
    const doJob = (): void => {
      if (!this.settings) return
      const { interval, ringtone, onUpdate } = this.settings

      if (Date.now() >= this.lastPlayedAt + interval && this.hasAny()) {
        playRingtone(ringtone)
        this.lastPlayedAt = Date.now()

        for (const [orderId, notification] of this.notificationMap) {
          notification.play()
          if (!notification.hasRepeatsLeft) this.notificationMap.delete(orderId)
        }
        onUpdate()
      }
    }

    setInterval(doJob, 1000)
  }
}

class OrderNotification {
  private _repeats: number

  constructor(repeats: number) {
    this._repeats = repeats
  }

  play() {
    if (this._repeats > 0) this._repeats--
  }

  get hasRepeatsLeft() {
    return this._repeats > 0
  }
}
