import * as React from "react"
import { useParams } from "react-router"

import { useOrderContext } from "contexts/OrderContext"
import { Order } from "models/hubrise/Order"

import useTabName from "./useTabName"

/**
 * Get the order from the URL.
 */
const useOrder = (): Order | undefined => {
  const { getOrder } = useOrderContext()

  const { orderId } = useParams<{ orderId: string }>()
  const [order, setOrder] = React.useState<Order | undefined>(undefined)

  const tabName = useTabName()
  const { todayAndFutureOrders, historyOrders } = useOrderContext()

  React.useEffect(() => {
    if (orderId === undefined) return

    const orderStore = tabName === "history" ? historyOrders.orders : todayAndFutureOrders.orders
    const order = orderStore?.find((o) => o.id === orderId)

    if (order) {
      setOrder(order)
    } else {
      void getOrder(orderId).then((order) => {
        setOrder(order)
      })
    }
  }, [orderId, tabName, historyOrders.orders, todayAndFutureOrders.orders, getOrder])

  return order
}

export default useOrder
