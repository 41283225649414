import * as React from "react"
import { useTranslation } from "react-i18next"

import { CloseIcon, TextButton } from "components/InventoryList/Toolbar/ShowUnavailableButton/Styles"
import { useInventoryContext } from "contexts/InventoryContext"

const ShowUnavailableButton = ({ unavailableItemsCount }: { unavailableItemsCount: number }): JSX.Element => {
  const { t } = useTranslation()
  const { showUnavailableItems, setShowUnavailableItems } = useInventoryContext()
  const disabled = unavailableItemsCount === 0 && !showUnavailableItems

  return (
    <TextButton
      $isSelected={showUnavailableItems}
      disabled={disabled}
      variant="emptyOverGray"
      onClick={() => setShowUnavailableItems((state) => !state)}
    >
      {disabled
        ? t(`inventory.unavailable_filter.none`)
        : t(`inventory.unavailable_filter.show`, { count: unavailableItemsCount })}
      {showUnavailableItems ? <CloseIcon /> : null}
    </TextButton>
  )
}

export default ShowUnavailableButton
