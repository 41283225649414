import * as React from "react"
import ReactDOM from "react-dom/client"

import App from "App"
import { ServerConfig } from "models/Config"
import { initSentry } from "utils/sentry"

document.addEventListener("DOMContentLoaded", () => {
  const appDataset = document.getElementById("app-data")!.dataset

  const serverConfig = JSON.parse(appDataset.serverConfig!) as ServerConfig
  initSentry(serverConfig)

  const root = ReactDOM.createRoot(document.getElementById("app")!)
  root.render(<App serverConfig={serverConfig} />)
})
