import * as Sentry from "@sentry/react"

import { ServerConfig } from "models/Config"

export const initSentry = (serverConfig: ServerConfig) => {
  const { sentry_dsn, release } = serverConfig

  if (sentry_dsn !== null) {
    Sentry.init({
      dsn: sentry_dsn,
      release: release ?? undefined,
    })
  }
}
