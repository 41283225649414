import { Money } from "@hubrise/react-components"
import { DateTime } from "luxon"

import { Day } from "models/Day"
import { toDateStringOl, toTimeStringOl } from "utils/dateHelpers"

import { Charge } from "./order/Charge"
import { Customer } from "./order/Customer"
import { Deal } from "./order/Deal"
import { Discount } from "./order/Discount"
import { Item } from "./order/Item"
import { LoyaltyOperation } from "./order/LoyaltyOperation"
import { Payment } from "./order/Payment"
import Type from "./order/Type"
import DefaultType from "./order/types/DefaultType"
import DeliverooType from "./order/types/DeliverooType"
import DeliveryHeroType from "./order/types/DeliveryHeroType"
import GlovoType from "./order/types/GlovoType"
import JustEatType from "./order/types/JustEatType"
import OtherBridgeType from "./order/types/OtherBridgeType"
import ShopifyType from "./order/types/ShopifyType"
import UberEatsType from "./order/types/UberEatsType"
import { ServiceType, Status } from "./types"

export type OrderId = string & { readonly brand: unique symbol }
export const OrderId = (id: string): OrderId => id as OrderId

export type GroupedItemsByDeal = Array<{ deal: Deal; items: Array<Item> }>
export type OrderGroup = { day: Day; orders: Array<Order> }

export type TimeStatus =
  | { status: "imminent"; left: number }
  | { status: "later_today"; time: string | null }
  | { status: "future"; time: string | null }
  | { status: "past"; time: string | null }

export const imminentInMinutes = 60

type CustomFields = {
  restaurant?: {
    table_name?: string
  }
}

export class Order {
  private readonly _groupedItemsByDeal: GroupedItemsByDeal
  private _type: Type | undefined

  constructor(
    public id: OrderId,
    public status: Status,
    public serviceType: ServiceType | null,
    public serviceTypeRef: string | null,
    public createdAt: DateTime,
    public createdBy: string,
    public channel: string,
    public connectionName: string | null,
    public expectedTime: DateTime | null,
    public confirmedTime: DateTime | null,
    public customer: Customer,
    public customerNotes: string | null,
    public sellerNotes: string | null,
    public couponCodes: Array<string>,
    public collectionCode: string | null,
    public total: Money,
    public items: Array<Item>,
    public loyaltyOperations: Array<LoyaltyOperation>,
    public charges: Array<Charge>,
    public payments: Array<Payment>,
    public discounts: Array<Discount>,
    public deals: {
      [dealKey: string]: Deal
    },
    public customFields: CustomFields,
  ) {
    this._groupedItemsByDeal = []
    for (const [dealKey, deal] of Object.entries(deals)) {
      const items = this.items.filter((item) => item.dealLine?.dealKey === dealKey)
      this._groupedItemsByDeal.push({ deal, items })
    }
  }

  get type(): Type {
    this._type ||= this.determineType()
    return this._type
  }

  private determineType(): Type {
    const { createdBy, channel } = this

    if (createdBy === "Deliveroo Bridge" || (createdBy === "Developer Tools" && channel === "Deliveroo"))
      return new DeliverooType(this)

    if (createdBy === "Delivery Hero Bridge") return new DeliveryHeroType(this)

    if (createdBy === "Glovo Bridge") return new GlovoType(this)

    if (createdBy === "Just Eat Flyt Bridge" || createdBy === "Just Eat Takeaway Bridge") return new JustEatType(this)

    if (createdBy === "Shopify Bridge" || (createdBy === "Developer Tools" && channel === "Shopify"))
      return new ShopifyType(this)

    if (createdBy === "Uber Eats Bridge" || (createdBy === "Developer Tools" && channel === "Uber Eats"))
      return new UberEatsType(this)

    if (createdBy.endsWith(" Bridge")) return new OtherBridgeType(this)

    return new DefaultType(this)
  }

  get confirmedOrExpectedTime(): DateTime | null {
    return this.confirmedTime || this.expectedTime
  }

  get dueDate(): DateTime {
    return this.confirmedOrExpectedTime || this.createdAt
  }

  get itemsWithoutDeals(): Array<Item> {
    return this.items.filter((item) => !item.dealLine)
  }

  get groupedItemsByDeal(): GroupedItemsByDeal {
    return this._groupedItemsByDeal
  }

  dueDay(cutoff: number): Day {
    return Day.fromDateTime(this.dueDate, cutoff)
  }

  get pointsEarned(): number {
    return this.loyaltyOperations.reduce((acc, { delta }) => acc + Math.max(delta, 0), 0)
  }

  get pointsUsed(): number {
    return this.loyaltyOperations.reduce((acc, { delta }) => acc + Math.max(-delta, 0), 0)
  }

  get pointsDelta(): number {
    return this.pointsEarned - this.pointsUsed
  }

  get pointsBalance(): number {
    return this.loyaltyOperations.length > 0 ? this.loyaltyOperations[this.loyaltyOperations.length - 1].newBalance : 0
  }

  private get _nonDeletedPayments(): Array<Payment> {
    return this.payments.filter((payment) => !payment.deleted)
  }

  get paymentSummaryHTML(): string | null {
    const payments = this._nonDeletedPayments
    if (payments.length === 0) return null

    if (payments.length === 1 && payments[0].amount.isEqualTo(this.total)) {
      return payments[0].name
    } else {
      return payments
        .map((payment) => payment.amount.toHumanString() + (payment.name !== null ? ` (${payment.name})` : ""))
        .join(" + ")
    }
  }

  get paymentDue(): Money {
    const zero = Money.zero(this.total)
    return this.total.minus(this._nonDeletedPayments.reduce((acc, payment) => acc.plus(payment.amount), zero))
  }

  /**
   * Group orders by date.
   * @param orders: an array of Order.
   * @param cutoff: the cutoff time in seconds.
   * @return an array of objects with the following properties: date, orders.
   */
  static groupByDate(orders: Array<Order>, cutoff: number): Array<OrderGroup> {
    const groups: Array<OrderGroup> = []

    // Create the list of all dates.
    const daySet = new Set<string>()
    for (const order of orders) {
      daySet.add(order.dueDay(cutoff).toString())
    }

    // Group the orders by date.
    for (const dayString of daySet) {
      groups.push({
        day: Day.fromString(dayString),
        orders: orders.filter((order) => order.dueDay(cutoff).toString() === dayString),
      })
    }

    // Sort orders in each group
    for (const group of groups) {
      group.orders
        .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
        .sort((a, b) => a.dueDate.toMillis() - b.dueDate.toMillis())
    }

    // Sort groups by date, and return the result.
    return groups.sort((a, b) => a.day.toString().localeCompare(b.day.toString()))
  }

  /**
   * Returns true if the order is due today.
   */
  isDueToday(cutoff: number, today: Day): boolean {
    return this.dueDay(cutoff).toString() === today.toString()
  }

  /**
   * Returns true if the order is a future order. An order cannot be simultaneously due today and future.
   */
  isFuture(cutoff: number, today: Day): boolean {
    return this.dueDay(cutoff).toString() > today.toString()
  }

  isDueTodayOrFuture(cutoff: number, today: Day): boolean {
    return this.isDueToday(cutoff, today) || this.isFuture(cutoff, today)
  }

  timeStatus(cutoff: number, today: Day): TimeStatus {
    const confirmedOrExpectedTime = this.confirmedOrExpectedTime
    const timeLeft = confirmedOrExpectedTime ? confirmedOrExpectedTime.diffNow("minutes").minutes : null
    if (timeLeft !== null && timeLeft >= 0 && timeLeft <= imminentInMinutes) {
      return { status: "imminent", left: Math.round(timeLeft) }
    } else if (this.isDueToday(cutoff, today) && (timeLeft === null || timeLeft > imminentInMinutes)) {
      return { status: "later_today", time: confirmedOrExpectedTime ? toTimeStringOl(confirmedOrExpectedTime) : null }
    } else if (this.isFuture(cutoff, today)) {
      return { status: "future", time: confirmedOrExpectedTime ? toDateStringOl(confirmedOrExpectedTime) : null }
    } else {
      return { status: "past", time: confirmedOrExpectedTime ? toTimeStringOl(confirmedOrExpectedTime) : null }
    }
  }

  private static filterAndSort = (orders: Array<Order>, filterFn: (order: Order) => boolean): Array<Order> => {
    return Object.values(orders)
      .filter(filterFn)
      .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
      .sort((a, b) => a.dueDate.toMillis() - b.dueDate.toMillis())
  }

  static today = (orders: Array<Order>, cutoff: number, today: Day): Array<Order> =>
    Order.filterAndSort(orders, (order) => order.isDueToday(cutoff, today))

  static future = (orders: Array<Order>, cutoff: number, today: Day): Array<Order> =>
    Order.filterAndSort(orders, (order) => order.isFuture(cutoff, today))

  static withStatus = (orders: Array<Order>, status: Status): Array<Order> =>
    orders.filter((order) => !status || order.status === status)

  get tableName(): string | null {
    return this.customFields?.restaurant?.table_name ?? null
  }
}
