export type CatalogInventory = Array<InventoryEntry>

export interface InventoryEntry {
  type: "sku" | "option"
  ref: string
  stock: number | null
  expiresAt: Date | null
}

export type ApiCatalogInventory = Array<ApiInventoryEntry>

export interface ApiInventoryEntry {
  sku_ref?: string
  option_ref?: string
  stock: string | null
  expires_at: string | null
}

export const importCatalogInventory = (data: ApiCatalogInventory): CatalogInventory => {
  return data.map((entry) => ({
    type: entry.sku_ref !== undefined ? "sku" : "option",
    ref: (entry.sku_ref ?? entry.option_ref)!,
    stock: entry.stock === null ? null : Number(entry.stock),
    expiresAt: entry.expires_at !== null ? new Date(entry.expires_at) : null,
  }))
}

export const exportCatalogInventory = (catalogInventory: CatalogInventory): ApiCatalogInventory => {
  return catalogInventory.map((entry) => ({
    [`${entry.type}_ref`]: entry.ref,
    stock: entry.stock === null ? null : String(entry.stock),
    expires_at: entry.expiresAt !== null ? entry.expiresAt.toISOString() : null,
  }))
}
