import { Order } from "models/hubrise/Order"

import Websocket, { Callback, Preparer } from "./Websocket"

export class Subscription<Data> {
  private callbacks: Array<Callback<Data>>

  private constructor(websocket: Websocket, channel: string, event: string, preparer?: Preparer<Data>) {
    this.callbacks = []

    websocket.subscribe(channel, event, async (data) => {
      const result: Data = preparer ? await preparer(data) : (data as Data)
      await Promise.all(this.callbacks.map((callback) => void callback(result)))
    })
  }

  static createForCatalog(websocket: Websocket, appInstanceId: string): Subscription<void> {
    return new Subscription(websocket, appInstanceId, "catalog")
  }

  static createForInventory(websocket: Websocket, appInstanceId: string): Subscription<void> {
    return new Subscription(websocket, appInstanceId, "inventory")
  }

  static createForLocation(websocket: Websocket, appInstanceId: string): Subscription<void> {
    return new Subscription(websocket, appInstanceId, "location")
  }

  static createForOrder(
    websocket: Websocket,
    appInstanceId: string,
    getOrder: (orderId: string) => Promise<Order>,
  ): Subscription<Order> {
    return new Subscription(websocket, appInstanceId, "order", async (data: { hr_id: string }) => getOrder(data.hr_id))
  }

  connect(callback: Callback<Data>) {
    const index = this.callbacks.indexOf(callback)
    if (index === -1) {
      this.callbacks.push(callback)
      return callback
    }
  }

  disconnect(callback: Callback<Data>) {
    const index = this.callbacks.indexOf(callback)
    if (index >= 0) {
      this.callbacks.splice(index, 1)
    }
  }
}
