import * as React from "react"

const Marker = (options: google.maps.MarkerOptions): null => {
  const [marker, setMarker] = React.useState<google.maps.Marker>()

  React.useEffect(() => {
    if (!marker) setMarker(new google.maps.Marker())

    // Remove marker from map on unmount
    return () => {
      if (marker) marker.setMap(null)
    }
  }, [marker])

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  // The component returns null as google.maps.Map manages the DOM manipulation.
  return null
}

export default Marker
