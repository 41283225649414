import { breakpoints, ModalStyle as Modal } from "@hubrise/react-components"
import styled from "styled-components"

import Actions from "./Actions"
import Info from "./Info"
import Items from "./Items"

export const Grid = styled(Modal.Body)`
  padding: 0;
  display: grid;
  grid-template-columns: minmax(100%, 1fr);
  grid-template-areas: "actions" "info" "items";

  @media (min-width: ${breakpoints.mobile}) {
    grid-template-columns: minmax(67%, 2fr) minmax(33%, 1fr);
    grid-template-areas:
      "info actions"
      "items actions";
  }
`

export const PositionedActions = styled(Actions)`
  grid-area: actions;
`

export const PositionedItems = styled(Items)`
  grid-area: items;
`

export const PositionedInfo = styled(Info)`
  grid-area: info;
`
