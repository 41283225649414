import { Icon } from "models/hubrise/order/Type"

import DefaultType from "./DefaultType"
import logoDeliveryHero from "./logos/delivery-hero.png"
import logoFoodora from "./logos/foodora.png"
import logoFoodPanda from "./logos/foodpanda.png"
import logoTalabat from "./logos/talabat.png"

class DeliveryHeroType extends DefaultType {
  get name() {
    return this.order.channel
  }

  get icon(): Icon {
    switch (this.name) {
      case "foodora":
        return { type: "imageURL", imageUrl: logoFoodora }
      case "foodpanda":
        return { type: "imageURL", imageUrl: logoFoodPanda }
      case "talabat":
        return { type: "imageURL", imageUrl: logoTalabat }
      default:
        return { type: "imageURL", imageUrl: logoDeliveryHero }
    }
  }
}

export default DeliveryHeroType
