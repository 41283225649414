import { breakpoints, colors } from "@hubrise/react-components"
import styled, { css } from "styled-components"

const columnPadding = "1.25rem"

export const Columns = styled.div`
  padding: ${columnPadding} 0;
  border-bottom: 1px solid ${colors.borderLight};
  border-top: 1px solid ${colors.borderLight};

  @media (min-width: ${breakpoints.mobile}) {
    display: flex;
    padding: 0;
  }
`

export const Column = styled.div<{ nb: number }>`
  @media (min-width: ${breakpoints.mobile}) {
    width: 50%;
    padding: ${columnPadding}; 
  
    ${({ nb }) =>
      nb === 0
        ? css`
            border-right: 1px solid ${colors.borderLight};
            padding-left: 0;
          `
        : css`
            padding-right: 0;
          `}
`
