import _ from "lodash"
import * as React from "react"

import { isOptionEditable } from "components/InventoryList/shared"
import { GroupOrItemWithType } from "components/InventoryList/types"
import { Option, OptionList } from "models/hubrise/Catalog"

interface OptionListHooks {
  isOptionListSelected: (optionList: OptionList) => boolean
  isLastOfOptionList: (index: number) => boolean
  optionListDescendants: (optionList: OptionList) => Array<Option>
}

const useOptionListHooks = (items: Array<GroupOrItemWithType>, selectedOptionIds: Array<string>): OptionListHooks => {
  const optionsByOptionListId = React.useMemo(() => {
    const options = items
      .filter((item) => item.type === "option" && isOptionEditable(item.data))
      .map((item) => item.data as Option)
    return _.groupBy(options, "option_list_id")
  }, [items])

  const optionListDescendants = React.useCallback(
    (optionList: OptionList): Array<Option> => optionsByOptionListId[optionList.id] ?? [],
    [optionsByOptionListId],
  )

  const isOptionListSelected = React.useCallback(
    (optionList: OptionList) =>
      optionListDescendants(optionList).every((product) => selectedOptionIds.includes(product.id)),
    [optionListDescendants, selectedOptionIds],
  )

  const isLastOfOptionList = React.useCallback(
    (index: number) => index === items.length - 1 || items[index + 1]?.type === "option_list",
    [items],
  )

  return { isLastOfOptionList, optionListDescendants, isOptionListSelected }
}

export default useOptionListHooks
