import React from "react"

import { CheckboxIcon, Checkbox, HiddenCheckbox, Wrapper } from "components/FancyCheckbox/Styles"

interface FancyCheckboxProps {
  checked: boolean
  name?: string
  disabled?: boolean
  onChange: () => void
  className?: string
}

const FancyCheckbox = ({ checked, name, disabled, onChange, className }: FancyCheckboxProps): JSX.Element => {
  return (
    <Wrapper
      onClick={(e) => {
        if (disabled !== true) {
          e.stopPropagation()
          onChange()
        }
      }}
      className={className}
    >
      <Checkbox $checked={checked} $disabled={disabled != null && disabled}>
        <HiddenCheckbox type="checkbox" name={name} />

        <CheckboxIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <polyline points="4 12 8 16 20 4" strokeWidth="3" />
        </CheckboxIcon>
      </Checkbox>
    </Wrapper>
  )
}

export default FancyCheckbox
