import * as React from "react"

import { Order } from "models/hubrise/Order"

import { CustomerNotesSection, Line } from "./Styles"

const CustomerNotes = ({ order }: { order: Order }): JSX.Element | null => {
  return order.customerNotes !== null ? (
    <CustomerNotesSection>
      <Line>{order.customerNotes}</Line>
    </CustomerNotesSection>
  ) : null
}

export default CustomerNotes
