export class LocalStorage {
  static get lastCatalogId(): string | null {
    return localStorage.getItem("lastCatalogId")
  }

  static set lastCatalogId(newId: string | null) {
    if (newId === null) {
      localStorage.removeItem("lastCatalogId")
    } else {
      localStorage.setItem("lastCatalogId", newId)
    }
  }
}
