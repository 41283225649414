import { Select } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useUserMutation } from "hooks/mutations"
import { Locale } from "models/hubrise/User"
import { languageDropdown } from "utils/i18n"

import { Row, Title } from "../Styles"

const General = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { mutate } = useUserMutation()

  const onLanguageChange = (value: Array<string>): void => {
    const locale = value[0] as Locale
    void mutate({ locale })
  }

  return (
    <React.Fragment>
      <Row>
        <Title>{t(`popup.settings.tabs.general.language`)}</Title>
        <Select options={languageDropdown} value={[i18n.language]} onChange={onLanguageChange} searchable={false} />
      </Row>
    </React.Fragment>
  )
}

export default General
