import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import useOrderNotifications from "contexts/OrderContext/useOrderNotifications"
import { UpdateOrderParams } from "contexts/OrderContext/useServer"
import { Day } from "models/Day"
import { OrderNotificationStore } from "models/OrderNotificationStore"
import { Subscription } from "models/Subscription"
import { Order } from "models/hubrise/Order"

import { HistoryFilter, OrderStore } from "./types"
import useConnectionLost from "./useConnectionLost"
import useHistoryOrders from "./useHistoryOrders"
import useServer from "./useServer"
import useTodayAndFutureOrders from "./useTodayAndFutureOrders"

export interface OrderContextInterface {
  getOrder: (orderId: string) => Promise<Order>
  updateOrder: (params: UpdateOrderParams) => Promise<Order>
  todayAndFutureOrders: OrderStore
  todayOrders: Array<Order>
  nbNewTodayOrders: number
  futureOrders: Array<Order>
  nbNewFutureOrders: number
  day: Day
  historyOrders: OrderStore
  historyFilter: HistoryFilter
  nbNewHistoryOrders: number
  orderNotificationStore: OrderNotificationStore
  isConnectionLost: boolean
}

const OrderContext = React.createContext<OrderContextInterface & { initialised: boolean }>({
  ...({} as OrderContextInterface),
  initialised: false,
})

export const OrderContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { appInstanceId } = useConfig()
  const { getOrder, updateOrder } = useServer()

  const { pusherWebsocket } = useConfig()
  const subscription = React.useMemo(
    () => Subscription.createForOrder(pusherWebsocket, appInstanceId, getOrder),
    [appInstanceId, getOrder, pusherWebsocket],
  )

  const todayAndFutureOrders = useTodayAndFutureOrders(subscription)
  const historyOrders = useHistoryOrders(subscription)
  const orderNotifications = useOrderNotifications(subscription)
  const isConnectionLost = useConnectionLost(pusherWebsocket)

  const value = React.useMemo<OrderContextInterface>(() => {
    return {
      getOrder,
      updateOrder,
      ...todayAndFutureOrders,
      ...historyOrders,
      ...orderNotifications,
      isConnectionLost,
    }
  }, [getOrder, updateOrder, todayAndFutureOrders, historyOrders, orderNotifications, isConnectionLost])

  return <OrderContext.Provider value={{ ...value, initialised: true }}>{children}</OrderContext.Provider>
}

export const useOrderContext = () => {
  const context = React.useContext(OrderContext)
  if (!context.initialised) {
    throw new Error("OrderContext not initialised")
  }
  return context
}
