import { colors, fontSizes, mixin } from "@hubrise/react-components"
import styled from "styled-components"

import { bodyHeight, headerHeight } from "../Styles"

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  ${headerHeight};

  ${mixin.centerElement};
  color: ${colors.textMedium};
  background-color: ${colors.backgroundLightest};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  font-weight: lighter;
  font-size: ${fontSizes._18};
`

export const Body = styled.div`
  ${bodyHeight};

  ${mixin.centerElement};
  text-transform: uppercase;
`
