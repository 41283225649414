import * as React from "react"
import { useTranslation } from "react-i18next"

import { Sku, SelectAll, SkuList, StyledSelector, StyledCheckbox } from "./Styles"

interface SkuSelectorProps {
  skuNames: Array<string>
  selectedSkuNames: Array<string>
  setSelectedSkuNames: React.Dispatch<React.SetStateAction<Array<string>>>
}

const SkuSelector = ({ skuNames, selectedSkuNames, setSelectedSkuNames }: SkuSelectorProps): JSX.Element => {
  const { t } = useTranslation()

  const allSelected = skuNames.length === selectedSkuNames.length

  const toggleAll = () => setSelectedSkuNames(allSelected ? [] : skuNames)

  const toggleOne = (skuName: string) =>
    setSelectedSkuNames((prevState) =>
      prevState.includes(skuName) ? prevState.filter((name) => name !== skuName) : [...prevState, skuName],
    )

  return (
    <StyledSelector>
      <SkuList>
        <SelectAll onClick={toggleAll}>
          <StyledCheckbox checked={allSelected} onChange={toggleAll} />
          {t("inventory.modal.sku_select_all")}
        </SelectAll>

        {skuNames.map((skuName) => (
          <Sku key={skuName} onClick={() => toggleOne(skuName)}>
            <StyledCheckbox
              checked={selectedSkuNames.includes(skuName)}
              name={skuName}
              onChange={() => toggleOne(skuName)}
            />
            {skuName}
          </Sku>
        ))}
      </SkuList>
    </StyledSelector>
  )
}

export default SkuSelector
