import { colors, fontSizes } from "@hubrise/react-components"
import styled from "styled-components"

export const Error = styled.div`
  margin: 5rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`

export const Title = styled.h1`
  font-size: ${fontSizes._18};
`

export const Message = styled.div``

export const Link = styled.a`
  color: ${colors.primary};
  text-decoration: underline;
`
