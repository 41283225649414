import { Modal, modalWidth } from "@hubrise/react-components"
import * as React from "react"
import { useLocation as useRouterLocation, useNavigate } from "react-router-dom"

import Loading from "components/Loading"
import OrderModalContent from "components/OrderModalContent"
import useAppNavigation, { LocationState } from "hooks/useAppNavigation"
import useOrder from "hooks/useOrder"
import useTabName from "hooks/useTabName"

const Order = (): JSX.Element => {
  const navigate = useNavigate()
  const { state } = useRouterLocation()

  const order = useOrder()
  const tabName = useTabName()
  const { tabPath } = useAppNavigation()

  const onClose = () => {
    const redirectUrl = (state as LocationState)?.redirectUrl
    if (redirectUrl === undefined) {
      // redirectUrl is undefined when the user opens the popup from an external link.
      const path = tabPath(tabName)
      navigate(new URL(path, window.location.origin))
    } else {
      navigate(new URL(redirectUrl))
    }
  }

  if (!order) return <Loading />

  return (
    <Modal
      modalWidth={modalWidth.xlarge}
      onClose={onClose}
      renderContent={({ close }) => <OrderModalContent close={close} order={order} />}
    />
  )
}

export default Order
