import * as React from "react"
import { Navigate, useRoutes } from "react-router-dom"

import useAppNavigation from "hooks/useAppNavigation"
import Future from "pages/Future"
import History from "pages/History"
import Inventory from "pages/Inventory"
import InventoryLoader from "pages/InventoryLoader"
import OpeningHours from "pages/OpeningHours"
import Order from "pages/Order"
import Receipt from "pages/Receipt"
import Today from "pages/Today"

const Routes = (): JSX.Element | null => {
  const { tabPath } = useAppNavigation()

  const order = { path: ":orderId", element: <Order /> }

  return useRoutes([
    { path: "/orders", element: <Today />, children: [order] },
    { path: "/orders/future", element: <Future />, children: [order] },
    { path: "/orders/history", element: <History />, children: [order] },
    { path: "/orders/:orderId/receipt", element: <Receipt /> },
    { path: "/opening-hours", element: <OpeningHours /> },
    { path: "/inventory", element: <InventoryLoader /> },
    { path: "/inventory/:catalogId/products", element: <Inventory section="products" /> },
    { path: "/inventory/:catalogId/options", element: <Inventory section="options" /> },
    { path: "*", element: <Navigate to={tabPath("today")} replace /> },
  ])
}

export default Routes
