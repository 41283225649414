import { Icon } from "models/hubrise/order/Type"

import DefaultType from "models/hubrise/order/types/DefaultType"

class UberEatsType extends DefaultType {
  get name() {
    return "Uber Eats"
  }

  get icon() {
    return { type: "clientName", clientName: "Uber Eats Bridge" } as Icon
  }
}

export default UberEatsType
