import * as React from "react"
import { useTranslation } from "react-i18next"

import { HourIntervalErrorType, TimeInterval } from "models/hubrise/location/opening_hours"

import {
  Errors,
  ErrorMessage,
  StyledTimeIntervalSelector,
  SelectorsAndButton,
  IconButton,
  StyledIcon,
  StyledInput,
  Selectors,
} from "./Styles"

interface TimeIntervalProps {
  from: string
  to: string
  onChange: (timeInterval: TimeInterval) => void
  onRemove: () => void
  errors: Array<HourIntervalErrorType>
}

const TimeIntervalSelector: React.FC<TimeIntervalProps> = ({ from, to, onChange, onRemove, errors }) => {
  const { t } = useTranslation()

  return (
    <StyledTimeIntervalSelector>
      <SelectorsAndButton hasErrors={errors.length > 0}>
        <Selectors>
          <StyledInput type="time" value={from} required onChange={(e) => onChange({ from: e.target.value, to: to })} />
          <div>-</div>
          <StyledInput type="time" value={to} required onChange={(e) => onChange({ from: from, to: e.target.value })} />
        </Selectors>

        <IconButton onClick={onRemove}>
          <StyledIcon code="delete" />
        </IconButton>
      </SelectorsAndButton>

      {errors.length > 0 && (
        <Errors>
          {errors.map((errorType, index) => {
            return <ErrorMessage key={index}>{t(`opening_hours.errors.${errorType}`)}</ErrorMessage>
          })}
        </Errors>
      )}
    </StyledTimeIntervalSelector>
  )
}

export default TimeIntervalSelector
