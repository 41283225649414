import { Status } from "./hubrise/types"

export const initialSettings: Settings = {
  status_hidden: [],
  status_transitions: {},
  status_with_editable_time: [],
  status_with_editable_comment: [],

  notification_repeats: 0,
  notification_interval: "15s",
  notification_ringtone: "default",
  notification_show_order: false,

  inventory_stock_counter: false,
}

interface Settings {
  status_hidden: Array<Status>
  status_transitions: Partial<{ [key in Status]: Array<Status> }>
  status_with_editable_time: Array<Status>
  status_with_editable_comment: Array<Status>

  notification_repeats: number
  notification_interval: string
  notification_ringtone: Ringtone
  notification_show_order: boolean

  inventory_stock_counter: boolean
}

export default Settings

export const Ringtones = [
  "default",
  "silent",
  "aluminium_can_open",
  "appointed",
  "arpeggio",
  "echoed_ding",
  "filling_your_inbox",
  "light",
  "ping_tone",
  "plucky",
  "quite_impressed",
  "robinhood76",
  "slow_spring_board",
  "success_sound_effect",
  "tiny_bell",
  "unconvinced",
] as const

export type Ringtone = typeof Ringtones[number]

export const Repeats = [0, 1, 2, 3, 5, 10, 20, 50] as const

export const Intervals = [
  { label: "5s", value: 5 * 1000 },
  { label: "15s", value: 15 * 1000 },
  { label: "30s", value: 30 * 1000 },
  { label: "1m", value: 1 * 60 * 1000 },
  { label: "2m", value: 2 * 60 * 1000 },
  { label: "5m", value: 5 * 60 * 1000 },
] as const
