import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import { usePrefetchCatalogAndInventory } from "hooks/queries"

type Selection = { section: "products"; ids: Array<string> } | { section: "options"; ids: Array<string> }
const defaultSelection: Selection = { section: "products", ids: [] }

interface InventoryContextInterface {
  selection: Selection
  setSelection: React.Dispatch<React.SetStateAction<Selection>>
  resetSelection: () => void
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  showUnavailableItems: boolean
  setShowUnavailableItems: React.Dispatch<React.SetStateAction<boolean>>
}

const InventoryContext = React.createContext<InventoryContextInterface & { initialized: boolean }>({
  ...({} as InventoryContextInterface),
  initialized: false,
})

export const InventoryContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
  const { appInstanceId } = useConfig()
  usePrefetchCatalogAndInventory(appInstanceId) // To speed up the initial display of the inventory

  const [selection, setSelection] = React.useState<Selection>(defaultSelection)
  const [searchValue, setSearchValue] = React.useState("")
  const [showUnavailableItems, setShowUnavailableItems] = React.useState(false)

  return (
    <InventoryContext.Provider
      value={{
        selection,
        setSelection,
        resetSelection: () => setSelection(defaultSelection),
        searchValue,
        setSearchValue,
        showUnavailableItems,
        setShowUnavailableItems,
        initialized: true,
      }}
    >
      {children}
    </InventoryContext.Provider>
  )
}

export const useInventoryContext = (): InventoryContextInterface => {
  const context = React.useContext(InventoryContext)
  if (!context.initialized) {
    throw new Error("useConfig must be used within an InventoryContextProvider")
  }
  return {
    ...context,
  }
}
