import { boxShadows, colors, mixin, sizes, zIndexValues } from "@hubrise/react-components"
import styled from "styled-components"

import { Status } from "models/hubrise/types"
import { orderColors } from "styles/common"

export const ClickableOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexValues.dropdownInModal};
  background: rgba(100, 100, 100, 0.5);
`

export const StyledPopup = styled.div`
  position: fixed;
  visibility: hidden;
  padding: 1rem;
  background-color: ${colors.white};
  border-radius: ${sizes.borderRadius};
  box-shadow: ${boxShadows.large};
  z-index: ${zIndexValues.dropdownInModal};
  width: 20rem;
`

export const Title = styled.div`
  margin-bottom: 1rem;
  color: ${colors.textMedium};
`

export const Subtitle = styled.div`
  color: ${colors.textDarkest};
`

export const StatusButton = styled.div<{ $status: Status }>`
  margin-bottom: 0.2rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  border-radius: ${sizes.borderRadius};

  color: ${colors.white};
  background-color: ${({ $status }) => orderColors.normal.statusBackground[$status]};

  ${mixin.clickable};
`

export const StyledIcon = styled.div`
  border-left: 1px solid ${colors.white};
  padding: 0 0.5rem;
`

export const Clear = styled.div`
  margin-top: 1rem;
  padding: 0.5rem 0;
  margin-bottom: -0.5rem;
  ${mixin.clickable};
`
