import { isSupportedCountry, parsePhoneNumberFromString } from "libphonenumber-js"
import type { CountryCode, PhoneNumber } from "libphonenumber-js"
import type { E164Number } from "libphonenumber-js/types"
import { DateTime } from "luxon"

import { Gender } from "../types"

import LatLngLiteral = google.maps.LatLngLiteral

export class Customer {
  constructor(
    public email: string | null,
    public firstName: string | null,
    public lastName: string | null,
    public gender: Gender | null,
    public birthDate: DateTime | null,
    public companyName: string | null,
    public phone: string | null,
    public phoneAccessCode: string | null,
    public address1: string | null,
    public address2: string | null,
    public postalCode: string | null,
    public city: string | null,
    public state: string | null,
    public country: string | null,
    public latitude: number | null,
    public longitude: number | null,
    public deliveryNotes: string | null,
    public smsMarketing: boolean,
    public emailMarketing: boolean,
  ) {}

  /**
   * Returns the phone number converted to a PhoneNumber object, or null if the phone number is not valid.
   * @param locationCountry
   */
  private parsePhone(locationCountry: string): PhoneNumber | null {
    if (!isSupportedCountry(locationCountry)) {
      throw new Error(`Country ${locationCountry} is not supported`)
    }
    return (this.phone !== null && parsePhoneNumberFromString(this.phone, locationCountry as CountryCode)) || null
  }

  /**
   * Returns the phone number formatted for humans.
   * @example phone = "+33612345678", locationCountry = "FR" => "06 12 34 56 78"
   * @param locationCountry
   */
  phoneHuman(locationCountry: string): string | null {
    const phoneNumber = this.parsePhone(locationCountry)
    if (phoneNumber) {
      return phoneNumber.country === locationCountry ? phoneNumber.formatNational() : phoneNumber.formatInternational()
    } else {
      // Default to the original phone number if it cannot be parsed
      return this.phone
    }
  }

  /**
   * Returns the phone number in E164 format, or null if the phone number cannot be parsed.
   * @example "+33612345678"
   * @param locationCountry
   */
  phoneE164(locationCountry: string): E164Number | null {
    const phoneNumber = this.parsePhone(locationCountry)
    return phoneNumber ? phoneNumber.number : null
  }

  get address1And2(): string | null {
    return [this.address1, this.address2].filter(Boolean).join(" ")
  }

  get postalCodeAndCity(): string | null {
    return [this.postalCode, this.city].filter(Boolean).join(" ")
  }

  get fullAddress(): string | null {
    return [this.address1And2, this.postalCodeAndCity].filter(Boolean).join(", ")
  }

  get fullName(): string | null {
    return [this.firstName, this.lastName].filter(Boolean).join(" ")
  }

  get coordinates(): LatLngLiteral | null {
    return this.latitude !== null && this.longitude !== null ? { lat: this.latitude, lng: this.longitude } : null
  }
}
