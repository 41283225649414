import { colors, sizes, fontSizes, Button, Field, breakpoints } from "@hubrise/react-components"
import styled, { css } from "styled-components"

export const StyledAvailability = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 1rem;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`

export const OptionsLabel = styled.div`
  margin-top: 2rem;
  margin-bottom: 0.375rem;
  font-size: ${fontSizes._12};
  color: ${colors.textLight};

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const UnavailableOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;

  ${OptionsLabel} {
    flex-basis: 100%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    column-gap: 1rem;
    row-gap: 0;
  }
`

export const AvailableOptions = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    flex-basis: auto;
  }
`

export const StyledNumberInput = styled(Field.NumberInput)`
  margin: 0;
  max-width: 10rem;

  & div {
    border: none;
    box-shadow: none;
  }

  border: 2px solid ${colors.borderMedium};
  overflow: hidden;
  border-radius: ${sizes.borderRadius};

  &:focus-within {
    border-color: ${colors.borderInputFocus};
  }

  & input {
    padding-left: 4px;
  }
`

export const RadioButton = styled(Button)<{ $isSelected?: boolean }>`
  background-color: transparent;
  color: ${colors.textDarkest};
  border: 2px solid ${colors.borderMedium};

  &:hover {
    background-color: ${colors.backgroundLight} !important;
  }

  ${({ $isSelected }) =>
    $isSelected != null && $isSelected
      ? css`
          background-color: ${colors.backgroundDark};
          color: ${colors.white};
          border: 2px solid ${colors.backgroundDark};

          &:hover {
            background: ${colors.backgroundDarkest} !important;
            border-color: ${colors.backgroundDarkest} !important ;
          }
        `
      : null}
`
