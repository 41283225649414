import * as React from "react"

import { useOrderContext } from "contexts/OrderContext"

import { RightHandSide } from "./Styles"

const MobileRightHandSide = () => {
  const { nbNewTodayOrders, nbNewFutureOrders } = useOrderContext()
  const nbNewOrders = nbNewTodayOrders + nbNewFutureOrders

  return (
    <RightHandSide $isVisible={nbNewOrders > 0} data-testid="mobile-counter">
      {nbNewOrders}
    </RightHandSide>
  )
}

export default MobileRightHandSide
