import { Money } from "@hubrise/react-components"

export class Payment {
  constructor(
    public name: string | null,
    public ref: string | null,
    public amount: Money,
    public info: Record<string, unknown> | null,
    public deleted: boolean,
  ) {}
}
