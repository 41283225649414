import { CatalogSelector as OrigCatalogSelector } from "@hubrise/react-components"
import type { CatalogForSelector } from "@hubrise/react-components"
import * as React from "react"

import { StyledCatalogSelector } from "components/InventoryList/Toolbar/CatalogSelector/Styles"
import { useInventoryContext } from "contexts/InventoryContext"
import { CatalogSummary } from "models/hubrise/CatalogSummary"

interface CatalogSelectorProps {
  catalogSummaries: CatalogSummary[]
  selectedCatalogId: string
  selectCatalog: (catalogId: string) => void
}

const CatalogSelector = ({
  catalogSummaries,
  selectCatalog,
  selectedCatalogId,
}: CatalogSelectorProps): JSX.Element | null => {
  const { resetSelection, setShowUnavailableItems } = useInventoryContext()

  if (selectedCatalogId === null) return null

  const catalogs: Array<CatalogForSelector> = catalogSummaries.map(({ id, name }) => ({
    id,
    name,
    onClick: () => {
      resetSelection()
      setShowUnavailableItems(false)
      selectCatalog(id)
    },
  }))

  return (
    <StyledCatalogSelector>
      <OrigCatalogSelector
        catalogs={catalogs}
        selectedCatalogId={selectedCatalogId}
        variant="emptyOverGray"
        reducedHeight={true}
      />
    </StyledCatalogSelector>
  )
}

export default CatalogSelector
