import { boxShadows, Button, colors, fontSizes, Icon, mixin } from "@hubrise/react-components"
import styled from "styled-components"

export const StyledTimeIntervalSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: min-content;
`

export const SelectorsAndButton = styled.div<{ hasErrors: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0 0.25rem;
  background-color: ${colors.backgroundWhite};
  ${({ hasErrors }) => mixin.fieldBorder(hasErrors ? colors.danger : "transparent")};

  box-shadow: ${boxShadows.small};
  :focus-within {
    ${mixin.fieldBorder(colors.borderInputFocus)};
  }
`

export const Selectors = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const StyledInput = styled.input`
  color: ${colors.textDarkest};
  position: relative;
  border: none;
  background-color: transparent;
  border-bottom: 2px transparent solid;

  :focus {
    outline: none;
    border-bottom-color: ${colors.borderInputFocus};
  }

  // Hide the clock icon
  &::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
  }
`

export const IconButton = styled(Button).attrs({ variant: "empty" })`
  padding: 0.2rem 0.5rem;
  border-left: 1px solid ${colors.borderLight};
  color: ${colors.textDark};
  ${mixin.clickable};
`

export const StyledIcon = styled(Icon).attrs({ size: 20 })`
  cursor: pointer;
  color: ${colors.textLight};
`

export const Errors = styled.div`
  flex: 0 1 auto;
`

export const ErrorMessage = styled.div`
  color: ${colors.danger};
  font-size: ${fontSizes._12};
  line-height: 1.5;
`
