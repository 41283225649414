import { boxShadows, breakpoints, colors, mixin, sizes, zIndexValues } from "@hubrise/react-components"
import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexValues.toast};
  padding: 1rem 1.5rem;

  border-radius: ${sizes.borderRadius};
  box-shadow: ${boxShadows.small};
  background-color: ${colors.danger};
  color: ${colors.white};
  font-weight: 500;

  ${mixin.clickable};

  @media (min-width: ${breakpoints.mobile}) {
    position: absolute;
    top: 0.75rem;
    left: initial;
    right: 1.25rem;
    max-width: 22.5rem;
    padding: 2rem 1.5rem;
  }
`
export const Message = styled.div`
  text-align: center;
  line-height: 1.5;
`
