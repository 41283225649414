import * as React from "react"

/**
 * Works in conjunction with usePrint. Must be called from the Iframe.
 */
const usePrintIframe = (): { markAsLoaded: () => void } => {
  const markAsLoaded = React.useCallback(() => {
    // Send message to parent window.
    parent.postMessage({ action: iframeLoadedAction })
  }, [])

  return { markAsLoaded }
}

export default usePrintIframe

export const iframeLoadedAction = "iframe-loaded"
