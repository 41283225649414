import * as React from "react"

import useTranslationFormatters from "hooks/useTranslationFormatters"
import { Item } from "models/hubrise/order/Item"

import { ItemName, Price, Quantity } from "../Styles"

const Top = ({ item, isDeal }: { item: Item; isDeal: boolean }): JSX.Element => {
  const { formatItemPrice } = useTranslationFormatters()
  const common = { $isDeleted: item.deleted }

  return (
    <React.Fragment>
      <ItemName {...common} $isDeal={isDeal}>
        {item.productFullName}
      </ItemName>
      <Quantity {...common}>{item.quantity}x</Quantity>
      <Price {...common} dangerouslySetInnerHTML={{ __html: formatItemPrice(item) }} />
    </React.Fragment>
  )
}

export default Top
