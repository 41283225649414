import * as React from "react"

import useWebsocket from "hooks/useWebsocket"
import { Subscription } from "models/Subscription"
import { Order } from "models/hubrise/Order"

import { OrderStore } from "./types"

/**
 * Take a Subscription<Order> and bind it to the provided setter function.
 */
const useOrderUpdated = (
  subscription: Subscription<Order>,
  stateSetter: (fn: (prevState: OrderStore) => OrderStore) => void,
): void => {
  const orderUpdateCallback = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (order: Order) => {
      stateSetter((prevState) =>
        prevState.isLoading
          ? prevState
          : {
              ...prevState,
              orders: updateOrder(prevState.orders, order),
            },
      )
    },
    [stateSetter],
  )
  useWebsocket(subscription, orderUpdateCallback)
}

const updateOrder = (orders: Array<Order>, order: Order): Array<Order> => {
  if (orders.find((o) => o.id === order.id)) {
    return orders.map((o) => (o.id === order.id ? order : o))
  } else {
    return [...orders, order]
  }
}

export default useOrderUpdated
