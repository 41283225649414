import * as React from "react"
import { useTranslation } from "react-i18next"

import { Order as OrderModel } from "models/hubrise/Order"

import { Spacer, TotalLabel, TotalPrice } from "./Styles"

const Total = ({ order }: { order: OrderModel }): JSX.Element => {
  const { t } = useTranslation()

  const paymentDue = order.paymentDue

  return (
    <React.Fragment>
      <TotalLabel>{t("popup.show_order.total_amount")}</TotalLabel>
      <TotalPrice dangerouslySetInnerHTML={{ __html: order.total.toHumanString() }} />

      {paymentDue.isZero ? null : (
        <React.Fragment>
          <Spacer />
          <TotalLabel>{t("popup.show_order.payment_due")}</TotalLabel>
          <TotalPrice dangerouslySetInnerHTML={{ __html: paymentDue.toHumanString() }} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Total
