import { fontSizes } from "@hubrise/react-components"
import styled from "styled-components"

import { dotBefore } from "styles/common"

import { borderPrinter, Section } from "../Styles"

export const ItemsSection = styled(Section)`
  display: grid;
  grid-template-columns: [quantity] 15% [name] 65% [price] 20% [end];
  gap: 0.35rem 0;
  font-size: ${fontSizes._12};
`

export const TdDealName = styled.div`
  grid-column: quantity / end;
  font-weight: bold;
`

export const TdDealDelimiter = styled.div`
  grid-column: quantity / end;
  border-top: 1px dashed ${borderPrinter};
`

export const TdQuantity = styled.div`
  grid-column: quantity;
  padding-left: 0.75rem;
`

export const TdName = styled.div`
  grid-column: name;
  font-weight: bold;
`

export const TdPrice = styled.div`
  grid-column: price;
  text-align: right;
`

const TdTotal = styled.div`
  margin-top: 0.5rem;
  font-weight: bold;
`

export const TdTotalLabel = styled(TdTotal)`
  grid-column: name;
  text-transform: uppercase;
`

export const TdTotalPrice = styled(TdTotal)`
  grid-column: price;
  text-align: right;
`

export const TdOptionLists = styled.div`
  grid-column: name / end;
  padding: 0 0 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
`

export const TdCustomerNotes = styled.div`
  grid-column: name / end;
`

export const OptionList = styled.div`
  ${dotBefore};
`
