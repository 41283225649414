import { colors } from "@hubrise/react-components"
import styled from "styled-components"

import { blockPadding } from "components/OrderModalContent/consts"
import { labelValueGap } from "styles/common"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  padding: ${blockPadding};
  border-bottom: 1px solid ${colors.borderLight};
`

export const Info = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Label = styled.label`
  white-space: nowrap;
  margin-right: ${labelValueGap};
  color: ${colors.textMedium};
`

export const Value = styled.span`
  font-weight: bold;
  color: ${colors.textDark};
`

export const ValueHighlight = styled(Value)`
  text-transform: uppercase;
`
