import * as React from "react"

import { useConfig } from "contexts/ConfigContext"
import { Ringtone } from "models/Settings"

interface UseAudioReturn {
  /**
   * Play a ringtone. If a ringtone is currently playing, it will be stopped immediately and either
   * restarted if the ringtone is identical, or replaced with the new ringtone.
   * @param ringtone The ringtone to play
   * @return A promise that resolves when the ringtone has finished playing, or rejects if the audio element fails to play.
   */
  play: (ringtone: Ringtone) => Promise<void>

  /**
   * Play a silent sound. If it succeeds, resolve the promise otherwise reject it.
   */
  periodicCheck: () => Promise<void>
}

const useAudio = (): UseAudioReturn => {
  const { ringtones } = useConfig()

  const audioMap = React.useRef<Map<string, HTMLAudioElement>>(new Map())
  const playingAudio = React.useRef<HTMLAudioElement | null>(null)

  const getAudio = React.useCallback(
    (ringtone: Ringtone): HTMLAudioElement => {
      const soundPath = ringtones[ringtone]
      const audio = audioMap.current.get(ringtone) || new Audio(soundPath)
      audioMap.current.set(ringtone, audio)
      return audio
    },
    [ringtones],
  )

  const play = React.useCallback(
    (ringtone: Ringtone): Promise<void> => {
      const audio = getAudio(ringtone)

      return new Promise((resolve, reject) => {
        if (playingAudio.current) {
          playingAudio.current.pause()
        }

        playingAudio.current = audio
        audio.currentTime = 0

        audio.oncanplaythrough = () => {
          void audio.play().catch(reject)
        }

        audio.onpause = () => resolve()

        audio.onended = () => {
          playingAudio.current = null
          resolve()
        }
      })
    },
    [getAudio],
  )

  const periodicCheck = React.useCallback((): Promise<void> => {
    const audio = getAudio("silent")

    return new Promise((resolve, reject) => {
      audio.currentTime = 0
      audio.oncanplaythrough = () => {
        void audio.play().catch(reject)
      }
      audio.onended = () => resolve()
    })
  }, [getAudio])

  return { play, periodicCheck }
}

export default useAudio
