import { colors } from "@hubrise/react-components"
import styled, { css } from "styled-components"

export const Wrapper = styled.div<{ $effectOnFocus: boolean }>`
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.5rem;
    padding: 0.25rem;
  }

  .react-datepicker__time-list-item {
    height: 20px !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    background-color: ${colors.primary} !important;
  }

  .react-datepicker__day--disabled.react-datepicker__day--keyboard-selected {
    background-color: ${colors.backgroundLight} !important;
  }

  input {
    width: 100%;
    height: 2rem;

    border: none;
    box-shadow: none;
    ${(props) =>
      !props.$effectOnFocus &&
      css`
        :focus,
        :focus-visible {
          outline: none;
        }
      `}
  }
`
