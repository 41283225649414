import { colors, fontSizes } from "@hubrise/react-components"
import styled, { css } from "styled-components"

export const ListWrapper = styled.div`
  height: 100%;
`

export const RowWrapper = styled.div<{ $isLastItem: boolean }>`
  margin-bottom: 1px;
  border-bottom: ${({ $isLastItem }) => (!$isLastItem ? `1px solid ${colors.borderLightest}` : "none")};
`

const checkboxLeftMargin = "1.25rem"

export const checkboxPositionCss = css`
  display: flex;
  align-items: center;
  padding-left: ${checkboxLeftMargin};
`

export const NoMatch = styled.div`
  margin: 0.75rem 0 0 ${checkboxLeftMargin};
  display: flex;
  font-size: ${fontSizes._18};
`

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  position: relative;
  bottom: 0.2rem;
  border-radius: 100%;
  background-color: ${colors.textDark};
`
