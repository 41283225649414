import * as React from "react"

import { Deal as DealModel } from "models/hubrise/order/Deal"
import { Item as ItemModel } from "models/hubrise/order/Item"

import Item from "./Item"
import { DealName, Spacer } from "./Styles"

const Deal = ({ deal, items }: { deal: DealModel; items: Array<ItemModel> }): JSX.Element => {
  return (
    <React.Fragment>
      <DealName>{deal.name}</DealName>

      {items.map((item, index) => (
        <Item key={index} item={item} isDeal={true} />
      ))}

      <Spacer />
    </React.Fragment>
  )
}

export default Deal
